import React from 'react';
import { connect } from 'react-redux';
import config from '../../../../config';
import classnames from 'classnames';
import store from 'store';

import styles from './Success.module.scss';
import Button from "../../../../components/ButtonV3/Button";
import Popup from "reactjs-popup";

class ReferralSuccess extends React.Component {
	state = {
		isPWA: store.get(config.user.isPWA)
	}

	render() {
		const { isPWA } = this.state;

		return (
			<div className={classnames(styles.pageContainer, isPWA ? styles.isPWA : '')}>
				<Popup modal closeOnDocumentClick={false} open={true} onClose={this.toggleModal} lockScroll={false} className={styles.successPopup}>
					{(close) => (
						<div className={styles.successPopup}>
							<svg xmlns="http://www.w3.org/2000/svg" width="44.892" height="44.899" viewBox="0 0 44.892 44.899">
								<g id="icon" transform="translate(-15.745 -15.745)">
									<path id="Path_10963" data-name="Path 10963" d="M65.709,36.351a21.453,21.453,0,1,0,10.583,18.5" transform="translate(-16.655 -16.655)" fill="none" stroke="#272a33" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
									<path id="Path_10965" data-name="Path 10965" d="M48.62,55.2,57.1,64.068l21.5-22.085" transform="translate(-20.152 -19.213)" fill="none" stroke="#272a33" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
									<path id="Path_10964" data-name="Path 10964" d="M65.308,41.221A17.6,17.6,0,1,0,73.991,56.4" transform="translate(-18.204 -18.204)" fill="none" stroke="#00d1d2" stroke-linecap="round" stroke-width="2"/>
								</g>
							</svg>
							<h2>Felicitări!</h2>
							<h2>Ai trimis cu succes invitația.</h2>
							<p>Împărtășește mai departe experiența IQOS printr-un mesaj de încurajare.</p>
							<p><b>Vrei să atașezi un mesaj de încurajare invitației adresate unui prieten adult fumător? </b></p>
							<div className={styles.buttons}>
								<Button href={config.path.index}>Nu îmi doresc</Button>
								<Button type='darkWithBlue' href={config.path.sendCard}>Da, vreau</Button>
							</div>
						</div>
					)}
				</Popup>
			</div>
		)
	}
}

export default connect()(ReferralSuccess)
