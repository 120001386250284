import React from "react";
import classnames from "classnames";

// Assets
import styles from "./style.module.scss";
import ErrorIcon from "../../assets/images/icons/sad-face.svg";

class ErrorScenario extends React.Component{
	render() {
		const {icon, textAlign} = this.props;

		return (
			<div className={classnames(
				styles.errorScenario,
				textAlign === "left" ? styles.leftAlign : ''
			)}>
				{icon && <img src={ErrorIcon} alt="error-icon"/>}
				{this.props.children}
			</div>
		);
	}
}

export default ErrorScenario;
