import React from 'react';
import { Fade } from 'react-reveal';

import styles from './Benefits.module.scss';
import Button from '../../../components/Button';

import HouseIcon from './images/House.png';
import HeartIcon from './images/Heart.png';
import DiamondsIcon from './images/Diamonds.png';

import Layout from "../../../components/Layout";
import classnames from "classnames";
import config from '../../../config';
import FdaSection from '../Components/fdaSection/fdaSection';

class Benefits extends React.Component {

    render() {
        const reasons = [
            {
                icon: HouseIcon,
                title: 'Fără foc, fără ardere',
                description: 'Spre deosebire de țigările obișnuite, IQOS încălzește tutunul, nu îl arde, iar astfel vei avea parte de o experiență mai plăcută.'
            },
            {
                icon: HeartIcon,
                title: 'Fără fum',
                description: 'Cu IQOS, tutunul nu arde, deci nu se produce fum. Încălzirea tutunului produce un aerosol care se disipează mai repede, iar încăperile casei tale vor fi mai curate.',
                disclaimer: 'IQOS nu este lipsit de riscuri. IQOS este destinat exclusiv fumătorilor adulți care altfel ar continua să fumeze.'
            },
            {
                icon: DiamondsIcon,
                title: 'O alternativă mai bună la țigări',
                description: `Experimentează gustul autentic de tutun adevărat, împreună cu avantajele care fac IQOS să fie o alegere mai bună.IQOS nu este lipsit de riscuri. 
                                IQOS este destinat exclusiv fumătorilor adulți care altfel ar continua să fumeze.`
            }
        ];

        return (
            <Layout>
                <div className={classnames('page_heading', 'isReferral', 'hasSubheading')}>
                    <div className='textContainer'>
                        <h1>  Tu ai făcut primul pas. </h1>
                        <h2> Ajută-ți prietenii* să facă la fel.</h2>
                    </div>
                    <div className='light-brown'/>
                    <div className='darker-brown'/>
                </div>

                <div className={styles.container}>
                    <Fade bottom cascade>
                        <div className={styles.textContainer}>
                            <h3>Invită-ți prietenii adulți fumători să descopere IQOS!</h3>
                            <p className={styles.paragraph}>Împarte experiențele fresh cu cei cu care te bucuri mereu de noi începuturi.</p>
                            <p className={styles.paragraph}>
                                Invită-ți prietenii să testeze gratuit IQOS timp de <b>14 zile</b>. Dacă decid să achiziționeze un dispozitiv în urma testării, tu vei primi puncte în IQOS Club și împreună veți descoperi ce înseamnă un stil de viață fără fum și fără scrum.
                            </p>
                            <Button type="primary" toPage={config.path.referral}>
                                Trimite invitație
                            </Button>
                        </div>

                        <div className={styles.reasonsContainer}>
                            <h3>Experiența IQOS are următoarele beneficii:</h3>
                            <div className={styles.reasons}>
                                {reasons.map(({ icon, title, description, disclaimer }, id) => (
                                    <div className={styles.reason} key={id}>
                                        <div className={styles.iconContainer}>
                                            <img src={icon} alt="icon-benefits" />
                                        </div>
                                        <h3> {title}</h3>
                                        <p>{description}</p>
                                        <small>{disclaimer}</small>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Button type="primary" toPage={config.path.referral}>
                            Trimite invitație{' '}
                        </Button>
                    </Fade>
                    <FdaSection />
                </div>
            </Layout>
        );
    }
}

export default Benefits;
