import React from 'react';
import store from 'store';
import Slider from 'react-slick';
import classnames from 'classnames';
import ReeValidate from 'ree-validate';

import config from '../../../../config';
import styles from './SendCard.module.scss';
import styleButton from '../../../../components/Button/Button.module.scss';
import { CopyIcon } from './SendCardIcons';

// Saga
import { connect } from 'react-redux';
import ReferralAction from '../../../../stores/Referral/Actions';

import { FacebookMessengerShareButton, WhatsappShareButton, WhatsappIcon, FacebookMessengerIcon } from 'react-share';

// Components
import Card from './Card';
import Layout from '../../../../components/Layout';
import Loader from '../../../../components/Loader';
import Button from "../../../../components/Button/Button";

class SendCard extends React.Component {
    constructor(props) {
        super(props);
        let dictionary = {
            en: {
                messages: {
                    required: () => 'Acest câmp este obligatoriu.',
                    max: (field, value) => `Te rugăm să introduci maxim ${value} de caractere.`
                }
            }
        };

        this.validator = new ReeValidate({
            name: 'required|max:32'
        });

        this.validator.localize(dictionary);
        this.state = {
            isPWA: store.get(config.user.isPWA),
            isLoggedIn: store.get(config.user.token),
            name: '',
            slide: 0,
            userName: store.get(config.user.data),
            errors: this.validator.errors,
            isMobile: window.innerWidth < 920
        };

        this.handleCurrentSlide = this.handleCurrentSlide.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (store.get(config.user.token)) {
            this.props.getCards();
        }
        window.addEventListener('resize', this.isMobileWidth);
    }

    isMobileWidth = () => {
        this.setState({ isMobile: window.innerWidth < 920 });
    };

    handleCurrentSlide = (currentSlide) => {
        const { cardsData } = this.props;
        this.setState({ slide: currentSlide });
    };

    handleInputChange = (field, value) => {
        const { errors } = this.validator;
        errors.remove(field);

        this.setState({ name: value });
        this.validator.validate(field, value).then(() => this.setState({ errors }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const { name, slide } = this.state;
        const { errors } = this.validator;
        const { submit, cardsData } = this.props;

        errors.remove('name');

        const isValid = await this.validator.validate('name', name);

        if (isValid) {
            const activeSlide = cardsData[slide].id;
            submit({image_id: activeSlide, person_name: name});
        } else {
            this.setState({ errors });
        }
    };

    handleCopyText = (e) => {
        e.preventDefault();
        let copyText = document.getElementById('link-text');
        copyText.select();
        copyText.setSelectionRange(0, 1000);

        document.execCommand('copy');
    };

    render() {
        const { cardsData, success, cardData, loading } = this.props;
        const { isPWA, userName, isMobile } = this.state;

        const settings = {
            variableWidth: true,
            focusOnSelect: true,
            infinite: false,
            dots: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1040,
                    settings: {
                        centerMode: true,
                        variableWidth: false
                    }
                },
                {
                    breakpoint: 920,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        variableWidth: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        infinite: true,
                        variableWidth: false,
                        dots: false
                    }
                }
            ]
        };

        return (
            <Layout>
                {success && (
                    <div className={styles.pageContainer}>
                        <div className={styles.successContainer}>
                            <h3>Trimite mesajul!</h3>
                            <p>Așa arată mesajul ales de tine. E timpul să-l trimiți.</p>
                            <div className={classnames(styles.form)}>
                                <input
                                    // className={isPWA ? styles.isPWA : ''}
                                    type="text"
                                    value={cardData && cardData.link}
                                    id="link-text"
                                />
                                <button className={isPWA ? styles.isPWA : ''} onClick={this.handleCopyText}>
                                    {/*{!isPWA && <CopyIcon />} */}
                                    Copiază{' '}
                                </button>
                            </div>

                            <div className={styles.socialContainer}>
                                <FacebookMessengerShareButton
                                    openShareDialogOnClick={true}
                                    url={cardData && cardData.link}
                                >
                                    <FacebookMessengerIcon size={32} round={true} />
                                </FacebookMessengerShareButton>

                                <WhatsappShareButton url={cardData && cardData.link}>
                                    <WhatsappIcon size={32} round={true} />
                                </WhatsappShareButton>
                            </div>

                            {cardData && (
                                <Card
                                    friendName={cardData.person_name}
                                    userName={userName.first_name}
                                    message={cardData.image.content}
                                    greetings={cardData.greetings}
                                    photo={cardData.image.image}
                                    slogan={ cardData.image.slogan}
                                />
                            )}
                        </div>
                        <div className={styles.turqoiseSection} />
                    </div>
                )}

                {!success && (
                    <div className={styles.pageContainer}>
                        <div className={classnames(styles.contentContainer, isPWA ? styles.isPWA : '')}>
                            <div className={classnames(styles.textContainer, isPWA ? styles.isPWA : '')}>
                                <h3>
                                    Ne bucurăm că vrei să împărtășești experiențele IQOS cu cineva drag.
                                </h3>
                                <p>
                                    Alege unul dintre mesajele de încurajare, completează numele prietenului adult fumător și invită-l în lumea IQOS.
                                </p>

                                <form className={styles.form} onSubmit={this.handleSubmit} id="my-form">
                                    <label className={classnames({ 'has-error': this.state.errors.has('name') })}>
                                        <p>Numele prietenului</p>
                                        <input
                                            // className={isPWA ? styles.isPWA : ''}
                                            type="text"
                                            placeholder="ex: Alexandru"
                                            value={this.state.name}
                                            id="name"
                                            onChange={(e) =>
                                                this.handleInputChange('name', e.target ? e.target.value : e)
                                            }
                                        />
                                        {this.state.errors.has('name') && (
                                            <span className="error">{this.state.errors.first('name')}</span>
                                        )}
                                    </label>
                                    {(!isPWA && !isMobile) && <Button type="submit" onClick = {() => this.handleSubmit()}>Alege</Button>}

                                </form>
                            </div>

                            {loading ? (
                                <Loader />
                            ) : (
                                <Slider
                                    className={classnames(styles.sliderContainer, 'mgmSlider', isPWA ? 'isPWA' : '')}
                                    {...settings}
                                    afterChange={this.handleCurrentSlide}
                                >
                                    {cardsData &&
                                        cardsData.map((card) => (
                                            <Card
                                                friendName={this.state.name}
                                                key={card.id}
                                                photo={card.image}
                                                message={card.content}
                                                greetings={card.greetings}
                                                userName={userName.first_name}
                                            />
                                        ))}
                                </Slider>
                            )}

                            {isPWA && (
                                <button
                                    className={classnames(styleButton.primaryButton, styleButton.isPWA)}
                                    type="submit"
                                    form="my-form"
                                    noLink
                                >
                                    {isPWA ? `Alege` : `Salvează mesajul`}
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.referral.loading,
    cardsData: state.referral.cardsData,
    cardData: state.referral.cardData,
    success: state.referral.success
});

const mapDispatchToProps = {
    getCards: () => ReferralAction.referralCards(),
    submit: (params) => ReferralAction.referralSubmitCards(params)
};

export default connect(mapStateToProps, mapDispatchToProps)(SendCard);
