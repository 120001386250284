import React, { createRef, useEffect, useRef, useState } from 'react';
import styles from './EducatedAwareness.module.scss';
import { connect } from 'react-redux';
import breadTransparent from './images/bread-transparent.png';
import deviceTransparent from './images/device-transparent.png';

const EducatedAwarenessComponent = () => {

	return (
		<div className={styles.educatedAwarenessComponent}>
			<div className={styles.promoSection}>
				<div className={styles.half}>
					<img src={breadTransparent} alt=""/>
					<h3>Unele lucruri sunt mai bune când nu sunt arse.</h3>
				</div>
				<div className={styles.half}>
					<img src={deviceTransparent} alt=""/>
					<h3>La fel e și cu tutunul.</h3>
				</div>
			</div>
			<div className={styles.detailsAwareness}>
				<h3>Încălzirea versus</h3>
				<h3>arderea tutunului</h3>
				<p>Cel mai nociv lucru legat de fumat este arderea tutunului, nu tutunul în sine. Spre deosebire de țigări, IQOS încălzește tutunul, în loc să îl ardă, și generează, în medie, niveluri cu 95% mai reduse ale unor substanțe nocive în comparație cu țigările*.</p>
				<p>Procesul de ardere a tutunului produce peste 6.000 de substanțe. Dintre acestea, autoritățile de sănătate publică au constatat că în jur de 100 sunt legate de bolile asociate fumatului.</p>
				<p>Încălzirea tutunului reduce numărul și nivelul de substanțe nocive care sunt produse, în comparație cu fumatul unei țigări.</p>
				<div className={styles.infoDisclaimer}>
					<p><b>*Informații importante:</b> Aceasta nu echivalează neapărat cu o reducere a riscului cu 95%. IQOS nu este lipsit de riscuri. Eliberează nicotină, care provoacă dependență.</p>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	loading: state.user.loading,
	telcorData: state.user.telcorData
});

export default connect(mapStateToProps, null)(EducatedAwarenessComponent);
