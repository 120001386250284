import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../../config";
import styles from "./IQOSPoints.module.scss";

// Components
import Layout from "../../../components/Layout";
import Button from "../../../components/Button/Button";
import MapPreview from "../../OfflineRedemption/MapPreview";

// Images
import PwaImg01 from './images/image01.png';
import PwaImg02 from './images/image02.png';
import PwaIcon01 from './images/icon01.svg';
import PwaIcon02 from './images/icon02.svg';
import PwaIcon03 from './images/icon03.svg';

import HowToImg1 from "../../../assets/images/ui/howto4.png";
import HowToImg2 from "../../../assets/images/ui/howto5.png";
import HowToImg3 from "../../../assets/images/ui/howto6.png";
import HowToImg4 from "../../../assets/images/ui/howto7.png";
import HowToImg5 from "../../../assets/images/ui/howto8.png";

import PwaImg4 from "../../../assets/images/ui/how-to/img4.png";

import GooglePlay from "../../../assets/images/ui/google-play.png";
import AppStore from "../../../assets/images/ui/app-store.png";
import QollectionLogo from "../../../assets/images/ui/qollection-logo.png";


class IQOSPoints extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA)
    };

    render() {
        const {isPWA} = this.state;

        const StepOne = () => {
            return (
                <div className={classnames(styles.stepOne, isPWA ? styles.isPWA : '')}>
                    <div className={styles.container}>
                        <h3>Vei putea cumpăra produsele tale IQOS preferate prin achitarea contravalorii lor:</h3>

                        <ul className={styles.threeColumns}>
                            <li className={isPWA ? styles.pwaList : ''}>
                                <img src={isPWA ? PwaIcon01 : HowToImg1} title="Cartuse HEETS"
                                     alt="imagine cartuse HEETS"/>
                                <p>Cu bani</p>
                            </li>
                            <li className={isPWA ? styles.pwaList : ''}>
                                <img src={isPWA ? PwaIcon02 : HowToImg2} title="Dispozitive IQOS si accesorii"
                                     alt="imagine dispozitive IQOS si accesorii"/>
                                <p>Cu puncte</p>
                            </li>
                            <li className={isPWA ? styles.pwaList : ''}>
                                <img src={isPWA ? PwaIcon03 : HowToImg3} title="Servicii si experiente"
                                     alt="imagine servicii si experiente"/>
                                <p>Și cu bani și cu puncte</p>
                            </li>
                        </ul>

                        <Button noLink onClick={() => window.location = config.path.shopIQOS} type="primary"
                                color={isPWA ? 'gold' : ''}>Intră în shop</Button>
                        {isPWA &&
                        <p>În continuare, punctele tale vor putea fi folosite și în catalogul IQOS Club, având la
                            dispoziție produse create de designeri români, din secțiunea Qollection, alături de vouchere
                            și experiențe cadou de la partenerii IQOS.</p>}
                    </div>
                </div>
            )
        };

        const StepThree = () => {
            return (
                <div className={styles.stepTwo}>
                    <div className={styles.container}>
                        {isPWA ? '' :
                            <p>În catalogul IQOS Club poți revendica produse create de designeri români, din secțiunea
                                Qollection, alături de vouchere și experiențe cadou de la partenerii IQOS.</p>
                        }

                        <ul className={classnames(styles.threeColumns, isPWA ? styles.isPWA : '')}>
                            <li>
                                <h4>
                                    <img src={QollectionLogo} title="Qollection by Romanian Designers"
                                         alt="qollection-logo"/>
                                    by Romanian Designers
                                </h4>
                                <img src={isPWA ? PwaImg01 : HowToImg4} alt="qollection"/>
                            </li>

                            <li>
                                <h4>Experiențe și <br/>vouchere cadou</h4>
                                <img src={isPWA ? PwaImg02 : HowToImg5} alt="experiente-vouchere-cadou"/>
                            </li>
                        </ul>

                        <Button toPage={config.path.catalog} type='primary' color={isPWA && 'gold'}>Vezi
                            catalogul</Button>
                        <br/>
                        <p>Indiferent unde alegi să îți folosești punctele din IQOS Club, vrem ca tu să ai cea mai bună
                            experiență!</p>
                    </div>
                </div>
            )
        };

        return (
            <Layout>
                <div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
                    <h1>De acum, îți poți folosi <br/> punctele și în IQOS.ro</h1>
                </div>
                <div className={classnames(styles.howToUse, isPWA ? styles.isPWA : '')}>
                <Button type="goBack" toPage={config.path.index} > <span> Înapoi</span> </Button>
                    <div className={styles.container}>
                        <p>Pentru că lucrăm continuu la îmbunătățirea interacțiunii tale cu platformele IQOS, avem vești
                            bune pentru tine! Începând cu 20 februarie, <b>punctele acumulate în IQOS Club pot fi folosite și
                            în IQOS Shop-ul de pe iqos.ro.</b></p>
                    </div>

                    <StepOne/>
                    <StepThree/>
                </div>
            </Layout>
        )
    }
}

export default IQOSPoints;
