import React from 'react';
import {connect} from 'react-redux';
import config from '../../../../config';
import classnames from 'classnames';
import store from 'store';

import styles from './Success.module.scss';
import successImg from '../../images/success-image.png'
import Button from '../../../../components/Button/Button';

class ReferralSuccess extends React.Component {
	state={
        isPWA: store.get(config.user.isPWA)
	}
	render() {
		const { isPWA } = this.state;

		return(
			<>
			<div className={classnames(styles.pageContainer, isPWA ? styles.isPWA : '')}>
				<div className={classnames(styles.imageContainer, isPWA ? styles.isPWA : '')}>
					<div className={classnames(styles.cube, isPWA ? styles.isPWA : '')}>
						{isPWA && <h3> Felicitări !</h3>}
					</div>
					<img src={successImg} className={classnames(styles.image, isPWA ? styles.isPWA : '')} />
				</div>

				<div className={classnames(styles.textContainer, isPWA ? styles.isPWA : '')}>
					<div className={classnames(styles.contentContainer, isPWA ? styles.isPWA : '')}>
						<h3 className={classnames(styles.title, isPWA ? styles.isPWA : '')}>{!isPWA && `Felicitări!`} <br/> Invitația ta a fost trimisă cu succes!</h3>
						<p> Știm cât de importanți sunt prietenii pentru tine și, de aceea, am creat o serie de mesaje prin care să îi poți încuraja să vă bucurați de și mai multe momente împreună, fără fum și fără scrum.</p>
						<p><b>Vrei să-i trimiți un mesaj de încurajare prietenului tău?</b></p>

						<div className={styles.buttonContainer}>
							<Button type="goBack" toPage={config.path.index}> Nu vreau </Button>
							<Button type="primary" color='gold' toPage={config.path.sendCard}> Da, vreau </Button>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}

export default connect()(ReferralSuccess)
