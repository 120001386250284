import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './Autocomplete.module.scss';

export const InputWithAutocomplete = ({
                                          title,
                                          isFirstInput,
                                          id,
                                          value,
                                          handleChange,
                                          errors,
                                          arr,
                                          objKey,
                                          isCheckout,
                                          handleKeyPress,
                                          placeholder
                                      }) => {
    const options = arr.map((item) => item[objKey]);

    const obj = arr.find((item) => item.id === value) || {};
    const [suggestions, setSuggestions] = useState(options);
    const [inputValue, setInputValue] = useState(obj[objKey] || '');
    const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);

    const inputRef = useRef();

    useEffect(() => {
        const filteredOptions = options.filter((suggestion) => {
            const searchValue = inputValue.toLowerCase();
            return suggestion.toLowerCase().indexOf(searchValue) > -1;
        });

        setSuggestions(filteredOptions);
    }, [inputValue, arr]);

    useEffect(() => {
        const handleClick = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setAreSuggestionsVisible(false);
            }
        };
        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    });

    useEffect(() => {
        const handlekeyDown = (e) => {
            if (e.keyCode === 8 && !isFirstInput) {
                handleChange(id, e.target.value);
                setInputValue('');
            }
        };
        document.addEventListener('keydown', handlekeyDown);

        return () => document.removeEventListener('keydown', handlekeyDown);
    }, [inputValue]);

    const handleOption = (e, suggestion) => {
        const optionId = arr.find((a) => a[objKey] === suggestion).id;

        e.preventDefault();
        setInputValue(suggestion);
        handleChange(id, optionId);
        setAreSuggestionsVisible(false);
    };

    return (
        <label
            className={classnames('select', { 'has-error': errors.has(id) })}
            style={isCheckout ? { width: 'calc(33.3% - 20px)', position: 'relative' } : { position: 'relative' }}
        >
            <p>{title}</p>

            <input
                placeholder={placeholder ? placeholder : title}
                ref={inputRef}
                autoComplete="none"
                name={id}
                id={id}
                value={inputValue}
                onClick={() => setAreSuggestionsVisible(true)}
                onChange={(e) => {setInputValue(e.target.value); handleKeyPress && handleKeyPress(id,e.target.value)}}
            />
            {areSuggestionsVisible && !!suggestions.length && (
                <div
                    className={styles.optionContainer}
                    style={errors.has(id) ? { marginTop: '0' } : {}}
                    onBlur={() => setAreSuggestionsVisible(false)}
                >
                    {suggestions.map((suggestion, i) => {
                        return (
                            <div className={styles.option} key={i} onClick={(e) => handleOption(e, suggestion)}>
                                {suggestion}
                            </div>
                        );
                    })}
                </div>
            )}
            {errors.has(id) && <span className="error">{errors.first(id)}</span>}
        </label>
    );
};
