import React from 'react';
import { connect } from 'react-redux';
import store from 'store';

// Assets
import styles from './Popup.module.scss';
import classnames from 'classnames';
import ReferralAction from '../../../../stores/Referral/Actions';
import config from '../../../../config';
import { CloseIcon } from './PopupIcons';
import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
class Popup extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA)
    };

    closePopup = () => {
        this.props.toggleModal();
    };

    render() {
        return (
            <div className={classnames(styles.formPopup, this.props.showModal ? styles.showPopup : '')}>
                <div className={styles.centerAlign}>
                    <div className={styles.container}>
                        <div className={styles.closeButton} onClick={this.closePopup}>
                            <CloseIcon fill="white" />
                        </div>

                        <h3>Nu uita!</h3>

                        <p>
                            Persoana pe care o recomanzi trebuie să aștepte să fie contactată de un reprezentant IQOS,
                            pentru a achiziționa un dispozitiv. Dacă persoana pe care o recomanzi achiziționează pe cont
                            propriu un dispozitiv IQOS în alt mod, nu vei primi punctele pentru recomandarea făcută cu
                            succes.
                        </p>
                        <p>
                            Vezi regulamentul <LinkContainer to={config.path.regulation} className={styles.simpleLink}>
                            <Nav.Link eventKey="">aici</Nav.Link></LinkContainer>.
                        </p>
                        {/*<p>*/}
                        {/*    Dacă persoana pe care o recomanzi achiziționează pe cont propriu un dispozitiv IQOS din alt*/}
                        {/*    loc sau în alt mod, nu vei primi punctele pe recomandarea făcută cu succes*. De asemenea, nu*/}
                        {/*    vei primi puncte nici din recomandările făcute cu succes*, ulterior, de către persoana*/}
                        {/*    recomandată de tine. Pentru fiecare recomandare cu succes, vei primi în contul tău IQOS*/}
                        {/*    puncte, conform regulamentului.*/}
                        {/*</p>*/}
                        <p>
                            *O recomandare efectuată cu succes înseamnă că persoana recomandată a achiziționat
                            dispozitivul IQOS, în condițiile din regulament.
                        </p>

                        <button
                            type="button"
                            className={this.state.isPWA ? styles.buttonPWA : ''}
                            onClick={this.props.submitForm}
                        >
                            Trimite invitație
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    showModal: state.referral.showModal
});

const mapDispatchToProps = {
    toggleModal: () => ReferralAction.toggleModal()
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
