import React from 'react';
import store from 'store';
import classnames from 'classnames';
import config from '../../../../config';
import styles from './Card.module.scss';
import img from './images/no-smoke.png';

class Card extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA)
    };

    render() {
        const {friendName, userName, message, photo, greetings} = this.props;
        const {isPWA} = this.state;

        return (
            <div className={classnames(styles.imageContainer, isPWA ? styles.isPWA : '')}>
                <img src={photo} className={isPWA ? styles.isPWA : ''}/>
                <div className={classnames(styles.details, isPWA ? styles.isPWA : '')}>
                    <h3 dangerouslySetInnerHTML={{__html: friendName}}/>
                    <p dangerouslySetInnerHTML={{__html: message}}/>

                    <img src={img} alt=""/>

                    <p style={{fontSize: '16px'}}>
                        {greetings} <b>{userName}</b>
                    </p>

                    <small>IQOS nu este lipsit de riscuri. IQOS este destinat exclusiv fumătorilor adulți care
                        altfel ar continua să fumeze.</small>
                </div>
            </div>
        )
    }
}

export default Card;