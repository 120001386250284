import React from 'react';
import store from 'store';
import classnames from "classnames";
import {connect} from 'react-redux';

// Assets
import config from "../../config";
import cookies from "../../utils/cookies";
import styles from './Footer.module.scss';
import disclaimerStyles from "./parts/disclaimer.module.scss";

// Components
import Popup from "reactjs-popup";
import {Nav} from "react-bootstrap";
import Collapsible from 'react-collapsible';
import {LinkContainer} from "react-router-bootstrap";
import ManageCookies from "./parts/ManageCookies";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

class Footer extends React.Component {
	state = {
		isLoggedIn: store.get(config.user.token),
		isPWA: store.get(config.user.isPWA),
		showManageCookiesPopup: false,
		showDisclaimer: false,
		width: 0
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions());

		if(!(cookies.read('CookiesNotice'))) {
			this.setState({
				showDisclaimer: window.location.hostname === 'iqos.ro' && window.location.pathname.indexOf('çlub') > - 1
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions());
		clearAllBodyScrollLocks();
	}

	updateWindowDimensions() {
		this.setState({width: window.innerWidth});
	}

	toggleModal = (event) => {
		event.preventDefault();

		this.state.showManageCookiesPopup ? enableBodyScroll('body') : disableBodyScroll('body');
		this.setState({
			showManageCookiesPopup: !this.state.showManageCookiesPopup
		});
	};

	handleAcceptTerms = (event) => {
		event.preventDefault();
		this.setState({ showDisclaimer: false });
	};

	render() {
		const {isPWA, isLoggedIn, showDisclaimer} = this.state;

        return(
            <div className={isPWA ? styles.footerPWA : styles.footer}>
                <div className={isPWA ? styles.containerPWA : ''}>
                    <div className={styles.details}>
                        <a href="http://www.iqos.ro/" className={styles.logo} target="_blank" rel="noopener noreferrer">IQOS</a>
                        <p>&copy; {new Date().getFullYear()} Philip Morris Products SA. <br /> Toate drepturile rezervate.</p>
                    </div>

                    {this.state.width > 1024 ? (
                        <ul className={styles.columns}>
                            <li>
                                <ul>
                                    <li>Linkuri utile</li>
                                    <li><a href="http://www.iqos.ro/" target="_blank" rel="noopener noreferrer">Mergi pe IQOS.ro</a></li>
	                                <li>
		                                <a href={config.path.map}>
			                                Găsește IQOS
		                                </a>
	                                </li>
	                                <li>
		                                <a href={config.path.listWinners}>
			                                Listă câștigători

		                                </a>
	                                </li>

	                                <li>
		                                <a href= 'https://www.iqos.ro/noutati' target="_blank" rel="noopener noreferrer">
			                                Noutăți
		                                </a>
	                                </li>
                                    <li>
	                                    <a href={config.path.shopIQOS} target="_blank" rel="noopener noreferrer">
	                                    IQOS Shop
                                         </a>
                                    </li>
	                                <li>
		                                <a href="https://www.iqos-on.ro/" target="_blank" rel="noopener noreferrer">
			                                IQOS ON
		                                </a>
	                                </li>
	                                <li><a href="https://www.pmi.com" target="_blank" rel="noopener noreferrer">Accesează PMI.com</a></li>

                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li>Asistență</li>

                                    <li>
	                                    <a href={config.path.contact}>
		                                    Contactează-ne
	                                    </a>
                                    </li>

									<li>
										<LinkContainer to={config.path.regulation}>
											<Nav.Link eventKey="">
												Regulament
											</Nav.Link>
										</LinkContainer>
									</li>

									{isLoggedIn &&
										<li>
											<li>
												<a href={config.path.FAQs}>
													Întrebări frecvente

												</a>
											</li>
											{/*<LinkContainer to={config.path.FAQs}>*/}
											{/*	<Nav.Link eventKey="">*/}
											{/*		Întrebări frecvente*/}
											{/*	</Nav.Link>*/}
											{/*</LinkContainer>*/}
										</li>
									}
	                                <li>
		                                <a href="https://www.iqos.ro/garantie-iqos">Înlocuire IQOS</a>
	                                </li>
	                                <li>
		                                <a href="https://www.iqos.ro/asistenta/iqos/3-duo">Tutoriale</a>
	                                </li>
                                </ul>
                            </li>

                            <li className={styles.legal}>
                                <ul>
                                    <li>Legal</li>

                                    <li><a href="http://www.anpc.gov.ro" target="_blank" rel="noopener noreferrer">ANPC</a></li>
	                                <li>
		                                <a href={config.path.termsConditions}>
			                                Termeni și Condiții
		                                </a>
	                                </li>
	                                <li>
		                                <a href={config.path.termsConditionsUGC}>Termeni și Condiții "TU si IQOS"</a>
	                                </li>
	                                <li>
		                                <a href={config.path.userDataNotification}>
			                                Notificare de protecție a datelor
		                                </a>
	                                </li>

	                                <li>
		                                <a href={config.path.cookiesPolicy}>
			                                Politică de cookies
		                                </a>
	                                </li>
                                    {/*<li><a href="#" className="cookie_hint_settings" onClick={(e) => this.toggleModal(e)}>Gestionează cookies</a></li>*/}
                                </ul>
                            </li>

                            <li className={styles.alwaysActive}>
                                <ul className={styles.social_links}>
                                    <li>Urmărește-ne</li>
                                    <li>
                                        <a href="https://www.facebook.com/iqos.ro" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/iqos_support_ro" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    ) : (
                        <div className={styles.footerDetails}>
                            <Collapsible
                                trigger="Linkuri utile"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
                                <a href="http://www.iqos.ro/" target="_blank" rel="noopener noreferrer">Mergi pe IQOS.ro</a>
	                            <li>
		                            <a href={config.path.map}>
			                            Găsește IQOS
		                            </a>
	                            </li>

	                            <li>
		                            <a href={config.path.shopIQOS} target="_blank" rel="noopener noreferrer">
			                            IQOS Shop
		                            </a>
	                            </li>
	                            <li>
		                            <a href="https://www.iqos-on.ro/" target="_blank" rel="noopener noreferrer">
			                            IQOS ON
		                            </a>
	                            </li>
	                            <li><a href="https://www.pmi.com" target="_blank" rel="noopener noreferrer">Accesează PMI.com</a></li>

	                            <li>
		                            <a href={config.path.listWinners}>
			                            Listă câștigători
		                            </a>
	                            </li>
	                            <li>
		                            <a href= 'https://www.iqos.ro/noutati' target="_blank" rel="noopener noreferrer">
			                            Noutăți
		                            </a>
	                            </li>
                            </Collapsible>

                            <Collapsible
                                trigger="Asistență"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
	                            <a href={config.path.contact}>
		                            Contactează-ne
	                            </a>

								<LinkContainer to={config.path.regulation}>
									<Nav.Link eventKey="">
										Regulament
									</Nav.Link>
								</LinkContainer>

								{isLoggedIn &&
									<li>
										<a href={config.path.FAQs}>
											Întrebări frecvente

										</a>
									</li>
									// <LinkContainer to={config.path.FAQs}>
									// 	<Nav.Link eventKey="">
									// 		Întrebări frecvente
									// 	</Nav.Link>
									// </LinkContainer>
								}
								<li>
									<a href="https://www.iqos.ro/garantie-iqos">Înlocuire IQOS</a>
								</li>
	                            <li>
		                            <a href="https://www.iqos.ro/asistenta/iqos/3-duo">Tutoriale</a>
	                            </li>
                            </Collapsible>

                            <Collapsible
                                trigger="Legal"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
                                <a href="http://www.anpc.gov.ro" target="_blank" rel="noopener noreferrer">ANPC</a>

                                <li>
	                                <a href={config.path.termsConditions}>
		                                Termeni și Condiții
	                                </a>
                                </li>

	                            <li>
		                            <a href={config.path.termsConditionsUGC}>Termeni și Condiții "TU si IQOS"</a>
	                            </li>
								<li>
									<a href={config.path.userDataNotification}>
										Notificare de protecție a datelor
									</a>
								</li>

	                            <li>
		                            <a href={config.path.cookiesPolicy}>
			                            Politică de cookies
		                            </a>
	                            </li>

                                {/*<a href="#" className="cookie_hint_settings" onClick={(e) => this.toggleModal(e)}>Gestionează cookies</a>*/}
                            </Collapsible>

                            <Collapsible
                                trigger="Urmărește-ne"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
                                <a href="https://www.facebook.com/iqos.ro" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-facebook" />
                                </a>
                                <a href="https://twitter.com/iqos_support_ro" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-twitter" />
                                </a>
                            </Collapsible>
                        </div>
                    )}
                </div>

				<Popup modal closeOnDocumentClick
					   open={this.state.showManageCookiesPopup}
					   onClose={this.toggleModal}
				>
					{close => (
						<>
							<img src="https://sandbox.gd.ro/images/esc.png" className={styles.closePopup} onClick={close} alt="close-popup"/>
							<ManageCookies/>
						</>
					)}
				</Popup>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Footer);
