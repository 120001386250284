import React from 'react';
import { Router as BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { history } from '../../stores/createStore';
import config from '../../config';
import store from 'store';
import 'antd/dist/antd.css';

//Scroll
import ScrollToTop from './ScrollToTop'

// Auth
import AgeGate from '../../containers/AgeGate';
import Login from '../../containers/Auth/Login';
import Register from '../../containers/Auth/Register';
import ForgotPassword from '../../containers/Auth/ForgotPassword';
import MapPage from '../../containers/Pages/MapPage';

// Landing pages
import HomeNotLogged from '../../containers/Landing/HomeNotLogged';
// Referral
import ListReferrals from '../../containers/Referral/List';
import ReferralBenefits from '../../containers/Referral/Benefits';


import Referral from '../../containers/Referral';
import ReferralSuccess from '../../containers/Referral/Components/Success';
import SendCard from '../../containers/Referral/Components/SendCard'
import ReceiveCard from '../../containers/Referral/Components/ReceivedCard'

// Referral2021
import ListReferrals2021 from '../../containers/Referral2021/List';
import ReferralBenefits2021 from '../../containers/Referral2021/Benefits';

import Referral2021 from '../../containers/Referral2021';
import ReferralSuccess2021 from '../../containers/Referral2021/Components/Success';
import SendCard2021 from '../../containers/Referral2021/Components/SendCard';
import ReceiveCard2021 from '../../containers/Referral2021/Components/ReceivedCard';
// Activities
import OfflineRedemption from '../../containers/OfflineRedemption';
import UserDataNotification from "../../containers/Pages/UserDataNotification";
import TermsConditions from "../../containers/Pages/TermsConditions";
import CookiesPolicy from "../../containers/Pages/CookiesPolicy";
import ListWinners from "../../containers/Pages/ListWinners/ListWinners";
import Regulation from "../../containers/Pages/Regulation";
import IQOSPoints from "../../containers/Pages/IQOSPoints";
import Contact from "../../containers/Pages/Contact/Contact";
import HowTo from "../../containers/Pages/HowTo";
import FAQs from "../../containers/Pages/FAQs/FAQs";


const token     = store.get(config.user.token);
const isAdult   = store.get(config.user.isAdult);
const is2021 = () => (new Date() >= new Date('1/1/2021 00:00:00')) || store.get('testing');

const Router = () => (
    <BrowserRouter history={history} basename={'/club/invita-un-prieten'}>
        <ScrollToTop />
        <Switch>
            {/* Landing */}
            <Route   exact path={config.path.index} component={isAdult ? (token ? Referral2021 : HomeNotLogged) : AgeGate} />
            <Route   exact path={config.path.home} component={Referral} />

            {/*  Explore */}
                <Route exact path={`${config.path.receivedCard}/:token`}        component={is2021() ? ReceiveCard2021 : ReceiveCard} />

            <ProtectedRoute exact path={config.path.userDataNotification}       render={() => <Redirect to={config.path.index} />}  component={UserDataNotification} />
            <ProtectedRoute exact path={config.path.termsConditions}       		render={() => <Redirect to={config.path.index} />}  component={TermsConditions} />
            <ProtectedRoute exact path={config.path.cookiesPolicy}       		render={() => <Redirect to={config.path.index} />}  component={CookiesPolicy} />
            <ProtectedRoute exact path={config.path.listWinners}                render={() => <Redirect to={config.path.index} />}  component={ListWinners} />
            <ProtectedRoute exact path={config.path.IQOSpoints}                 render={() => <Redirect to={config.path.index} />}  component={IQOSPoints} />
            <ProtectedRoute exact path={config.path.contact}                    render={() => <Redirect to={config.path.index} />}  component={Contact} />
            <ProtectedRoute exact path={config.path.map}                        render={() => <Redirect to={config.path.index} />}  component={MapPage} />
            <ProtectedRoute exact path={config.path.FAQs}                       render={() => <Redirect to={config.path.index} />}  component={FAQs} />

            {/*    */}
            {/*    */}
            {/* Auth */}
            <Route   exact path={config.path.login} component={Login} />
            <Route   exact path={config.path.register} component={Register} />
            <Route   exact path={config.path.forgotPassword} component={ForgotPassword} />
            <Route exact path={config.path.regulation} component={Regulation} />


            {/* Activities */}
            <ProtectedRoute exact path={config.path.referralList}                   render={() => <Redirect to={config.path.referralList} />} component={is2021() ? ListReferrals2021 : ListReferrals} />
            <ProtectedRoute exact path={config.path.benefits}                   render={() => <Redirect to={config.path.benefits} />} component={is2021() ? ReferralBenefits2021 : ReferralBenefits} />

            <ProtectedRoute exact path={config.path.referral}                   render={() => <Redirect to={config.path.index} />} component={is2021() ? Referral2021 : Referral} />
            <ProtectedRoute exact path={config.path.referralSuccess}            render={() => <Redirect to={config.path.index} />} component={is2021() ? ReferralSuccess2021 : ReferralSuccess} />
            <ProtectedRoute exact path={config.path.sendCard}                   render={() => <Redirect to={config.path.sendCard} />} component={is2021() ? SendCard2021 : SendCard} />

            <ProtectedRoute exact path={config.path.offlineRedemption}          render={() => <Redirect to={config.path.index} />} component={OfflineRedemption} />
            <ProtectedRoute exact path={config.path.map} render={() => <Redirect to={config.path.index} />} component={MapPage} />

            <Redirect to={config.path.notFound}  />
        </Switch>
    </BrowserRouter>
);

export default Router;
