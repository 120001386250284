import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import store from 'store';

// Assets
import ReactTooltip from 'react-tooltip';
import styles from './Stats.module.scss';
import Reveal from "react-reveal/Reveal";
import config from "../../../../config";

class ReferralStats extends React.Component {
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const {isPWA} = this.state;

		return (
			<div className={classnames(isPWA ? styles.referralStatsPWA : styles.referralStats)}>
				<ul className={styles.refStats}>
					<Reveal effect="fadeInUp" cascade>
						<li>
							<p>Recomandările tale totale:</p>

							{
								(this.props.treeData) ? (
									<h4><span>{this.props.treeData.content.recommendations}</span> recomandări</h4>
								) : (
									''
								)
							}

							<ReactTooltip place="bottom" className={styles.tooltip} />
							<a href="#" className="read_more" data-tip="Numărul total al recomandărilor pe care le-ai trimis până în prezent, ce include recomandările finalizate cu succes și recomandările în curs de confirmare."><i className="fa fa-info-circle"></i></a>
						</li>

						<li>
							<p>Recomandările tale cu succes:</p>
							{
								(this.props.treeData) ? (
									<h4><span>{this.props.treeData.content.converted}</span> recomandări</h4>
								) : (
									''
								)
							}

							<a href="#" className="read_more" data-tip="Numărul total al recomandărilor finalizate cu succes până în prezent."><i className="fa fa-info-circle"></i></a>
						</li>
					</Reveal>
				</ul>
			</div>
		)
	}
}

export default connect(
)(ReferralStats);

