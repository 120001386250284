import { call, put, takeLatest } from 'redux-saga/effects';
import CountiesActions, { CountiesTypes } from './Actions';
import axios, { setAxiosToken } from '../../utils/axios';
import store from 'store';


export function* getCounties() {
	yield put(CountiesActions.countiesLoading());
	try {
		const response = yield call(() => axios.get('/profile/counties', {}));
		let counties = response.data; //TO DO remove this, because only token is received

		yield store.set('counties', counties);
		yield put(CountiesActions.countiesSuccess(counties));
	} catch (error) {
		yield put(CountiesActions.countiesFail(error));
	}
}

//CITIES DEPENDING ON COUNTY ID
export function* getCities({id}) {
	yield put(CountiesActions.citiesLoading());
	try {
		const response = yield call(() => axios.get('/profile/cities', {
			params: {
				'county_id' : id
			}
		}));
		let cities = response.data;
		yield put(CountiesActions.citiesSuccess(cities[0].cities));
	} catch (error) {
		yield put(CountiesActions.citiesFail(error));
	}
}

export default function* countiesSaga() {
	yield takeLatest(CountiesTypes.COUNTIES, getCounties);
	yield takeLatest(CountiesTypes.CITIES, getCities);
}
