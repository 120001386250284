import React from 'react';
import store from 'store';
import classnames from 'classnames';
import {connect} from 'react-redux';
// Assets
import Popup from 'reactjs-popup';
import {Nav} from 'react-bootstrap';
import config from '../../../../config';
import {LinkContainer} from 'react-router-bootstrap';
// Images
import SilverSimple from '../../../../assets/images/ui/silver-simple.png';
import GoldSimple from '../../../../assets/images/ui/gold-simple.png';
import PlatinumSimple from '../../../../assets/images/ui/platinum-simple.png';

import Silver from '../../../../assets/images/ui/silver.png';
import Gold from '../../../../assets/images/ui/gold.png';
import Platinum from '../../../../assets/images/ui/platinum.png';
// Saga
import UserActions from '../../../../stores/User/Actions';
import LogoutActions from '../../../../stores/Logout/Actions';

class UserDetails extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA)
    };

    componentDidMount() {
        this.props.getUser();
    };

    markAsRead = (id, e) => {
        e.preventDefault();
        this.props.readNotification(id);
    };

    render() {
        const {isPWA} = this.state;
        const {telcorData, userData, loading} = this.props;
        const UserIcon = () => (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <path className="path path-light"
                      d="M13.62,12.92a6.25,6.25,0,1,0-5.24,0A8.5,8.5,0,0,0,2.5,21h1a7.5,7.5,0,0,1,15,0h1A8.5,8.5,0,0,0,13.62,12.92ZM5.75,7.25A5.25,5.25,0,1,1,11,12.5,5.25,5.25,0,0,1,5.75,7.25Z"></path>
            </svg>
        );
        return (
            <>
                <div className="account_details">
                    {telcorData &&
                    <LinkContainer to={config.path.index} className="user_points">
                        <Nav.Link eventKey="">
                            <span>
                                {telcorData.availablePoints ? <>
                                <b>{telcorData.availablePoints}</b> puncte</> : ''}
                            </span>
                        </Nav.Link>
                    </LinkContainer>
                    }

                    <div className={classnames('user_details', userData && !loading ? '' : 'disableClicks')}>
                        <Popup
                            trigger={UserIcon}
                            position="bottom right"
                            on="hover"
                        >
                            <ul className="user_menu">
                                <li className="menuUserDetails">
                                    {telcorData &&
                                    <div className="user_points">
                                        {userData ? <b className="name">{userData.first_name}</b> : ''}
                                        <span type="hidden" data-value=""/>

                                        {telcorData.availablePoints ?
                                            <b className="points">{telcorData.availablePoints} puncte</b> : ''}
                                    </div>
                                    }
                                </li>
                                <li>
                                    <a href="#" onClick={this.props.logout}>
                                        <i className="fa fa-sign-out"/> Ieși din cont
                                    </a>
                                </li>
                            </ul>
                        </Popup>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.user.loading,
    userData: state.user.userData,
    telcorData: state.user.telcorData
});

const mapDispatchToProps = {
    getUser: () => UserActions.currentUser(),
    logout: () => LogoutActions.logout()
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDetails);
