import React from 'react';
import store from 'store';

// import ReCAPTCHA from 'react-google-recaptcha';
import Reaptcha from 'reaptcha';
import classnames from 'classnames';
import ReeValidate from 'ree-validate';

import config from '../../../../config';
import styles from './Form.module.scss';
import {TextInput} from './FormInput';
import {InputWithMask} from '../../../../components/Input';
import {SelectInput} from "../../../../components/Input/Select";
import {InputWithAutocomplete} from "../../../../components/Input";
import Loader from '../../../../components/Loader';

// Redux
import {connect} from 'react-redux';
import CountiesAction from '../../../../stores/Counties/Actions';
import ReferralAction from '../../../../stores/Referral/Actions';


class Form extends React.Component {
    constructor(props) {
        super(props);

        let dictionary = {
            en: {
                messages: {
                    required: () => 'Acest câmp este obligatoriu.',
                    email: () => 'Te rugăm să introduci o adresă de email validă.',
                    numeric: () => 'Număr telefon invalid.',
                    alpha: () => 'Caracterele numerice nu sunt permise.'
                }
            }
        };

        this.validator = new ReeValidate({
            first_name: 'required',
            last_name: 'required',
            phone_number: 'required|numeric',
            county: 'required',
            city: 'required',
            smoker: 'required',
            gRecaptchaResponse: 'required'
        });

        this.validator.localize(dictionary);
        this.captcha = null;

        this.state = {
            formData: {
                first_name: '',
                last_name: '',
                phone_number: '',
                county: '',
                city: '',
                smoker: '',
                gRecaptchaResponse: ''
            },
            selectedCounty: {
                id: null,
                name: null,
                cities: []
            },
            captchaReady: false,
            errors: this.validator.errors,
            isPWA: store.get(config.user.isPWA)
        };

        this.handleChange = this.handleChange.bind(this);
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getCounties();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const formErrors = this.props.formErrors;
        this.updateHeight();

        if (formErrors && prevProps.formErrors !== formErrors) {
            const {errors} = this.validator;

            for (let vKey in formErrors.errors) {
                errors.add({
                    field: vKey,
                    msg: formErrors.errors[vKey][0]
                });
            }
            this.setState({errors});
        }
    }

    //CALLED RESET METHOD TO EMPTY THE CITIES ARRAY WHEN USER CHANGES THE INPUT BY PRESSING OR DELETING LETTERS
    handleKeyPress = () => {
        this.props.citiesReset();
        this.setState((state) => ({
            ...state,
            formData: {
                ...state.formData,
                city: null
            }
        }));
    }

    handleChange = (field, value) => {
        const {errors} = this.validator;
        const { counties } = this.props;
        errors.remove(field);

        value = field === 'smoker' && value === false ? '' : value;

        if (field === 'county') {
            errors.remove('city');
            let countyObj;
            counties.map((item) => {
                if (item.id === value) {
                    countyObj = item;
                }
            });

            if (countyObj) {
                this.props.getCities(countyObj.id)  //CALLED CITIES BASED ON COUNTY SELECTED
            }
            else this.setState((state) => ({ ...state, selectedCounty: { ...state.selectedCounty, cities: [] } }));
        }
        this.setState((state) => ({
            ...state,
            formData: {
                ...state.formData,
                [field]: value
            }
        }));

        this.validator.validate(field, value).then(() => {
            this.setState({errors});
        });

        this.updateHeight();
    };

    validateAndSubmit = (e) => {
        e.preventDefault();

        const {formData} = this.state;
        const {errors} = this.validator;
        errors.clear();

        this.validator.validateAll(formData).then((success) => {
            if (success) {
                this.props.showModal(formData);
            } else {
                this.setState({errors});
            }
        });

        this.updateHeight();
    };

    updateHeight = () => {
        setTimeout(() => {
            //this.props.updateHeight();
        }, 300);
    };

    onLoad = () => {
        this.setState({
            captchaReady: true
        });
    };

    onChange = (value) => {
        const {errors} = this.validator;

        this.setState((state) => ({
            ...state,
            formData: {
                ...state.formData,
                gRecaptchaResponse: value
            }
        }));

        this.validator.validate('gRecaptchaResponse', value).then(() => {
            this.setState({errors});
        });
    };

    trackStartForm = () => {
    };

    render() {
        const {errors, selectedCounty, isPWA, formData} = this.state;
        const {loading, counties} = this.props;
        if (loading) {
            return (
                <form className={styles.form}>
                    <Loader/>
                </form>
            );
        } else {
            return (
                <form
                    className={classnames(styles.form, isPWA ? styles.isPWA : '')}
                    onSubmit={(event) => this.validateAndSubmit(event)}
                >
                    <TextInput
                        id="first_name"
                        title="* Prenume"
                        placeholder="ex: George"
                        errors={errors}
                        value={this.state.formData.first_name}
                        onClick={this.trackStartForm}
                        handleChange={this.handleChange}
                    />

                    <TextInput
                        id="last_name"
                        title="* Nume"
                        placeholder="ex: Popescu"
                        errors={errors}
                        value={this.state.formData.last_name}
                        handleChange={this.handleChange}
                    />

                    <InputWithMask
                        id="phone_number"
                        title="* Număr de telefon"
                        placeholder="ex. 07xx xxx xxx"
                        errors={errors}
                        value={this.state.formData.phone_number}
                        handleChange={this.handleChange}
                    />

                    { counties && (
                        <InputWithAutocomplete
                            isFirstInput
                            id="county"
                            title="* Județ"
                            value={this.state.formData.county}
                            handleChange={this.handleChange}
                            handleKeyPress = {this.handleKeyPress}
                            errors={errors}
                            arr={counties}
                            objKey="county"
                        />
                    )}

                    {(this.props.cities.length > 0 || formData.city) && ( //CHECK IF THERE ARE CITIES OR THERE IS A SELECTED CITY IN SUBMIT FORM
                        <InputWithAutocomplete
                            id="city"
                            title="* Oraș"
                            value={this.state.formData.city}
                            handleChange={this.handleChange}
                            errors={errors}
                            arr={this.props.cities}
                            objKey="city"
                        />
                    )}
                    <div className={styles.recaptchaContainer}>
                        <Reaptcha
                            ref={(e) => (this.captcha = e)}
                            sitekey="6LfRALgUAAAAAH4Dvwupku82fyCNItFCPYP9qLnY"
                            onLoad={this.onLoad}
                            onVerify={this.onChange}
                            // size="invisible"
                        />
                    </div>

                    {errors.has('gRecaptchaResponse') && (
                        <span className={styles.error}>{errors.first('gRecaptchaResponse')}</span>
                    )}

                    <label
                        style={{margin: '20px 0'}}
                        className={classnames('control', 'control--checkbox', {'has-error': errors.has('smoker')})}
                    >
                        * Persoana recomandată este fumătoare, are peste 18 ani și a fost informată că datele sale
                        personale vor fi furnizate de mine în programul IQOS Club, pentru a fi contactată de un
                        reprezentant de vânzări IQOS.

                        <input
                            type="checkbox"
                            id="smoker"
                            name="smoker"
                            value={formData.smoker}
                            onChange={(e) => this.handleChange('smoker', e.target ? e.target.checked : e)}
                        />
                        <div className="control__indicator"/>
                    </label>
                    {errors.has('smoker') && <span className={styles.error}>{errors.first('smoker')}</span>}

                    <p className={styles.termsConditions}>
                        Te rugăm să citești <a href={config.path.termsConditions}>aici</a> condițiile în care punctele sunt acordate pentru invitațiile trimise și finalizate cu succes (achiziție).
                    </p>

                    <button type="submit" className={classnames('showDetailsPopup', isPWA ? styles.submitPWA : '')}>
                        Trimite recomandarea
                    </button>

                    {errors.has('general') && <span className={styles.error}>{errors.first('general')}</span>}

                    <p className={styles.disclaimer}>
                        <b>Nu uita!</b> Persoana pe care o recomanzi trebuie să aștepte să fie contactată de un
                        reprezentant IQOS, pentru a achiziționa un dispozitiv. Dacă persoana pe care o recomanzi
                        achiziționează pe cont propriu un dispozitiv IQOS în alt mod, nu vei primi punctele pentru
                        recomandarea făcută cu succes. Vezi regulamentul <a href={config.path.regulation}>aici</a>.
                    </p>
                </form>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    loading: state.counties.loading || state.referral.loading,
    counties: state.counties.counties,
    cities: state.counties.cities,
    formErrors: state.referral.formErrors
});

const mapDispatchToProps = {
    getCounties: () => CountiesAction.counties(),
    getCities: (id) => CountiesAction.cities(id),
    citiesReset: () => CountiesAction.citiesReset(),
    showModal: (formData) => ReferralAction.referralModal(formData)
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
