import React from "react";
import store from "store";
import {connect} from "react-redux";
import config from "../../config";
import {Image} from "react-bootstrap";
import classnames from "classnames";

// Assets
import styles from "./OfflineRedemption.module.scss";
import Img from "../../assets/images/ui/revendica-offline/img.png";

// Components
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import MapPreview from "./MapPreview";
import ListCodes from "./ListCodes";
import Form from "./Form";

// Saga
import OfflineRedemptionActions from "../../stores/OfflineRedemption/Actions";
import {Link} from "react-scroll/modules";
import Reveal from "react-reveal/Reveal";

class OfflineRedemption extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA)
    };

    componentDidMount() {
        this.props.getRedeemed();
    }

    render() {
        const {redeemed, loading} = this.props;
        const {isPWA} = this.state;

        return (
            <Layout>
                <div className={classnames('page_heading', 'isReferral', 'hasSubheading')}>
                    <div className='rowHeading'>
                        <div className='textContainer'>
                            <div className='detailsHeader'>
                                <h2>Același ritual.</h2>
                                <h2>Aceeași comunitate.</h2>

                                <p>Recomandă IQOS unui prieten adult fumător și bucurați-vă împreună de beneficii.</p>
                            </div>
                            {/*{index === 1 && <Button noLink type='primary' onClick={() => this.handleTabChange(0)}> Descoperă avantajele IQOS </Button>}*/}
                        </div>
                        <div className= 'imageHeader' />
                    </div>
                </div>

                <div className={styles.offlineRedemption}>
                    <div className={styles.container}>

                        <Reveal effect="fadeInUp" cascade>
                            <h3>Cum poți revendica recompensele?</h3>
                            <p>În baza numărului de puncte acumulate, selectează câte pachete HEETS dorești să ridici și generează codul special.</p>
                        </Reveal>
                        {
                            loading ? (
                                <Loader/>
                            ) : (
                                <>
                                    <Form
                                        availablePoints={redeemed.content.availablePoints}
                                        availablePacks={redeemed.content.availablePacks}
                                    />

                                    {
                                        redeemed.content.unclaimedCodes.length > 0 &&
                                        <ListCodes userCodes={redeemed.content}/>
                                    }
                                </>
                            )
                        }

                        <Reveal effect="fadeInUp">
                            <p>
                                <small>
                                    * Poți alege să îți anulezi codurile de revendicare HEETS din magazine, iar punctele
                                    se întorc în contul tău, pentru ca tu să le poți folosi pentru achiziții online.
                                </small>
                            </p>
                            <p>
                                Pentru că ești membru IQOS Club, poți alege să-ți ridici chiar tu recompensele în
                                centrele de premiere din drumul tău. Acestea se găsesc chiar în magazinele IQOS,
                                benzinăriile MOL, ROMPETROL sau în magazinele Inmedio, 1Minute, HUB și Tabac Xpress.
                            </p>
                        </Reveal>

                        <MapPreview/>
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    redeemed: state.offlineRedemption.data,
    loading: state.offlineRedemption.loading
});

const mapDispatchToProps = {
    getRedeemed: () => OfflineRedemptionActions.redeemed()
};

export default connect(
    mapStateToProps, mapDispatchToProps,
)(OfflineRedemption);
