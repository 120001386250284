import React from "react";
import store from "store";
import classnames from "classnames";
import config from "../../../config";
import {Image} from "react-bootstrap";

// Assets
import styles from "./MapPreview.module.scss";

import PWAMapImage from "../../../assets/images/ui/revendica-offline/preview-harta-mare.png";
import MapImage from "../../../assets/images/ui/revendica-offline/preview-harta.png";

// Components
import Button from "../../../components/Button/Button";
import Reveal from "react-reveal/Reveal";

class MapPreview extends React.Component{
	state = {
	};

	render() {
			return(
				<>
					<Reveal effect="fadeInUp">
						<h3>Unde găsești centrele de premiere IQOS?</h3>
					</Reveal>

					<div className={styles.gasesteHarta}>
						<Reveal effect="fadeInUp" cascade>
							<Image src={MapImage}/>

							<div className={styles.details}>
								<Reveal effect="fadeInUp" cascade>
									<p>Caută cel mai apropiat centru <br/> de premiere pe hartă.</p>
									<Button toPage={config.path.map} type="primary">Vezi harta</Button>
								</Reveal>
							</div>
						</Reveal>
					</div>
				</>
			)
	}
}

export default MapPreview;
