import React from 'react'
import styles from './ReceiveCard.module.scss'

export const IQOSTitle = () => <svg id="Group_120" className={styles.customTitle} data-name="Group 120" xmlns="http://www.w3.org/2000/svg" width="461.94" height="43.177" viewBox="0 0 461.94 43.177">
    <path id="Path_932" data-name="Path 932" d="M90.471,21.1V14.286c0-7.734,4.445-9.321,11.117-9.321h11.6c6.665,0,11.108,1.587,11.108,9.321V21.1c0,7.738-4.443,9.324-11.108,9.324h-11.6c-6.672,0-11.117-1.586-11.117-9.324M103.311,0C94,0,84.488,1.659,84.488,13.954v7.47c0,12.3,9.512,13.961,18.823,13.961h8.142c9.317,0,18.835-1.66,18.835-13.961v-7.47C130.289,1.659,120.771,0,111.454,0Z" transform="translate(-14.14 0)" fill="#d09a59"/>
    <path id="Path_933" data-name="Path 933" d="M27.535,28.912c-.04-.032-.315-.266-.409-.354-1.367-1.333-2.231-3.412-2.284-6.626V5.5l24,.013c3.17.114,5.46.714,7.027,1.918.04.033.315.266.409.354,1.367,1.332,2.23,3.412,2.283,6.627V30.823l-24,0c-3.17-.113-5.46-.714-7.026-1.917M21.781.437a2.727,2.727,0,0,0-3.029,2.708V21.877c0,4.629,1.358,7.748,3.543,9.839,3.6,3.535,9.531,4.194,15.383,4.194H59.266l6.528,6.528a2.656,2.656,0,0,0,3.968-.167c1.3-1.3,1.308-2.87.306-3.873l-5.415-5.416V14.47c0-4.63-1.359-7.748-3.543-9.839C57.508,1.1,51.58.437,45.726.437H21.781Z" transform="translate(-3.138 -0.073)" fill="#d09a59"/>
    <path id="Path_934" data-name="Path 934" d="M2.939.041H2.924A2.745,2.745,0,0,0,0,3V32.525a2.745,2.745,0,0,0,2.924,2.957h.031a2.746,2.746,0,0,0,2.924-2.957V3A2.746,2.746,0,0,0,2.955.04H2.939" transform="translate(0 -0.007)" fill="#d09a59"/>
    <path id="Path_935" data-name="Path 935" d="M167.219.386c-14.226,0-17.089,3.306-17.089,8.639V11c0,6.545,3.088,9.092,11,9.092H178.47c6.968,0,7.508,2.015,7.508,4.866v.263c0,4.137-1.077,5.189-10.983,5.189,0,0-12.644-.007-12.936-.017v0c-3.294-.086-5.376-.469-6.341-2.162-.09-.179-.209-.43-.209-.43A2.883,2.883,0,0,0,152.593,26c-1.724,0-2.76.908-2.853,2.371a2.246,2.246,0,0,0,0,.485c.534,3.292,2.842,6.126,12.608,6.436v-.011c1.164.027,13.883.028,13.883.028,6.235,0,15.651,0,15.651-9.9v-.126c0-9.343-6.215-10.082-14.339-10.082H162.708c-5.634,0-6.664-1.414-6.664-4.2V9.746c0-3.713,1.626-4.464,9.669-4.464h9.807c4.754,0,7.609.107,8.8,2.19.091.179.21.43.21.43a2.882,2.882,0,0,0,2.915,1.805c1.724,0,2.759-.908,2.853-2.372a2.139,2.139,0,0,0,0-.484c-.565-3.49-3.121-6.465-14.444-6.465h-8.626Z" transform="translate(-25.059 -0.065)" fill="#d09a59"/>
    <path id="Path_936" data-name="Path 936" d="M260.553.375a23.5,23.5,0,0,1,6.522.765,7.253,7.253,0,0,1,3.793,2.3A8.813,8.813,0,0,1,272.5,6.6a15.7,15.7,0,0,1,.433,3.96q0,5.724-2.429,8.385T260.82,21.6h-21.5V33.317a1.923,1.923,0,0,1-.7,1.53,2.612,2.612,0,0,1-1.764.6,2.4,2.4,0,0,1-1.73-.632,2.148,2.148,0,0,1-.666-1.63V2.638a2.1,2.1,0,0,1,.7-1.63,2.621,2.621,0,0,1,1.83-.632h23.559Zm1.4,4.858H239.323V16.68h22.96q5.855,0,5.857-4.925v-1.53q0-2.928-1.531-3.96A8.438,8.438,0,0,0,261.95,5.233Z" transform="translate(-39.241 -0.063)" fill="#d09a59"/>
    <path id="Path_937" data-name="Path 937" d="M288.575,22.2V13.686q0-6.988,2.828-10.149T301.22.375h20.365q6.988,0,9.816,3.162t2.829,10.149V22.2q0,6.923-2.829,10.082t-9.816,3.161H301.22q-6.989,0-9.817-3.161T288.575,22.2Zm40.728,1V12.687q0-4.259-2.2-5.89a10.1,10.1,0,0,0-6.123-1.63H301.818a10.1,10.1,0,0,0-6.123,1.63q-2.2,1.632-2.2,5.89V23.2q0,4.26,2.2,5.889a10.085,10.085,0,0,0,6.123,1.63h19.166a10.093,10.093,0,0,0,6.123-1.63Q329.3,27.462,329.3,23.2Z" transform="translate(-48.297 -0.063)" fill="#d09a59"/>
    <path id="Path_938" data-name="Path 938" d="M357.4,12.288V23.6q0,7.255,7.987,7.254h15.24a11.92,11.92,0,0,0,5.192-.832q1.6-.832,1.863-3.56V26.2a2.039,2.039,0,0,1,.666-1.6,2.484,2.484,0,0,1,1.73-.6,2.714,2.714,0,0,1,1.764.566,1.833,1.833,0,0,1,.7,1.5v1.065q0,8.319-10.515,8.319h-17.37q-6.523,0-9.217-2.9t-2.762-9.217V12.554q.065-6.322,2.762-9.251T364.657.375h17.37q10.515,0,10.515,8.385v1a1.926,1.926,0,0,1-.7,1.531,2.614,2.614,0,0,1-1.764.6,2.488,2.488,0,0,1-1.73-.6,2.044,2.044,0,0,1-.666-1.6V9.427q-.266-2.728-1.863-3.56a11.932,11.932,0,0,0-5.192-.832h-15.24Q357.4,5.033,357.4,12.288Z" transform="translate(-59.025 -0.063)" fill="#d09a59"/>
    <path id="Path_939" data-name="Path 939" d="M422.013,17.944l19.366,13.576a1.625,1.625,0,0,1,.465,1.2,2.743,2.743,0,0,1-.932,1.93,2.226,2.226,0,0,1-1.6.732,2.655,2.655,0,0,1-1.6-.6L416.956,20.274H412.7V33.252a2.068,2.068,0,0,1-.666,1.564,2.407,2.407,0,0,1-1.73.632,2.573,2.573,0,0,1-1.73-.566,1.866,1.866,0,0,1-.666-1.5V2.5a1.868,1.868,0,0,1,.666-1.5A2.581,2.581,0,0,1,410.3.442a2.411,2.411,0,0,1,1.73.632,2.073,2.073,0,0,1,.666,1.564V15.548h4.326L437.254,1.107a3.363,3.363,0,0,1,2-.732,2.533,2.533,0,0,1,1.929.865,2.758,2.758,0,0,1,.732,1.8,1.806,1.806,0,0,1-.532,1.331Z" transform="translate(-68.268 -0.063)" fill="#d09a59"/>
    <path id="Path_940" data-name="Path 940" d="M544.33.375a2.1,2.1,0,0,1,1.63.7,2.53,2.53,0,0,1,.632,1.764,2.71,2.71,0,0,1-.6,1.83,1.929,1.929,0,0,1-1.531.7H528.689V33.318a1.925,1.925,0,0,1-.7,1.53,2.612,2.612,0,0,1-1.764.6,2.5,2.5,0,0,1-1.8-.632,2.148,2.148,0,0,1-.666-1.63V5.367H507.925a1.889,1.889,0,0,1-1.531-.732,2.742,2.742,0,0,1-.6-1.8,2.524,2.524,0,0,1,.633-1.764,2.1,2.1,0,0,1,1.63-.7H544.33Z" transform="translate(-84.651 -0.063)" fill="#d09a59"/>
    <path id="Path_941" data-name="Path 941" d="M488.767.623a2.154,2.154,0,0,1,1.678.69,2.608,2.608,0,0,1,.624,1.809,2.689,2.689,0,0,1-.591,1.808,1.974,1.974,0,0,1-1.58.69H457.664a1.974,1.974,0,0,1-1.579-.69,2.671,2.671,0,0,1-.591-1.808,2.593,2.593,0,0,1,.624-1.809A2.149,2.149,0,0,1,457.8.623h30.971Z" transform="translate(-76.233 -0.104)" fill="#d09a59"/>
    <path id="Path_942" data-name="Path 942" d="M488.767,17.842a2.208,2.208,0,0,1,1.678.659,2.48,2.48,0,0,1,.624,1.774,2.811,2.811,0,0,1-.591,1.841,1.926,1.926,0,0,1-1.58.728H457.664a1.923,1.923,0,0,1-1.579-.728,2.792,2.792,0,0,1-.591-1.841,2.467,2.467,0,0,1,.624-1.774,2.2,2.2,0,0,1,1.678-.659Z" transform="translate(-76.233 -2.986)" fill="#d09a59"/>
    <path id="Path_943" data-name="Path 943" d="M488.767,36.247a2.207,2.207,0,0,1,1.678.658,2.478,2.478,0,0,1,.624,1.777,2.822,2.822,0,0,1-.591,1.841,1.936,1.936,0,0,1-1.58.723H457.664a1.933,1.933,0,0,1-1.579-.723,2.8,2.8,0,0,1-.591-1.841,2.462,2.462,0,0,1,.624-1.777,2.2,2.2,0,0,1,1.678-.658Z" transform="translate(-76.233 -6.066)" fill="#d09a59"/>
</svg>
