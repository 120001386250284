import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../../config";
import styles from "./style.module.scss";

// Components
import Layout from "../../../components/Layout";
import Button from "../../../components/Button/Button";
import MapPreview from "../../OfflineRedemption/MapPreview";

// Images
import HowToImg1 from "../../../assets/images/ui/howto4.png";
import HowToImg2 from "../../../assets/images/ui/howto5.png";
import HowToImg3 from "../../../assets/images/ui/howto6.png";
import HowToImg4 from "../../../assets/images/ui/howto7.png";
import HowToImg5 from "../../../assets/images/ui/howto8.png";

import PwaImg4 from "../../../assets/images/ui/how-to/img4.png";

import GooglePlay from "../../../assets/images/ui/google-play.png";
import AppStore from "../../../assets/images/ui/app-store.png";
import QollectionLogo from "../../../assets/images/ui/qollection-logo.png";
import PwaIcon01 from "../IQOSPoints/images/icon01.svg";
import PwaIcon02 from "../IQOSPoints/images/icon02.svg";
import PwaIcon03 from "../IQOSPoints/images/icon03.svg";
import PwaIcon04 from "./images/icon01.svg";
import PwaIcon05 from "./images/icon02.svg";
import PwaIcon06 from "./images/icon03.svg";
import PwaIcon07 from "./images/icon04.svg";
import PwaIcon08 from "./images/icon05.svg";
import PwaIcon09 from "./images/icon06.svg";
import PwaImage01 from "./images/image01.png";
import PwaImage02 from "./images/image02.png";


class HowTo extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	render() {
		const {isPWA} = this.state;

		const StepOne = () => {
			return(
				<div>
					{isPWA ?
						<div className={styles.stepDetails}>
							<img src={PwaIcon07} alt=""/>

							<h3>De acum, îți poți cheltui <br/>punctele și în IQOS.ro</h3>
							<p>Cu punctele acumulate poți achita parțial sau total contravaloarea produselor pe care ți le dorești
								din IQOS Shop-ul de pe iqos.ro.</p>
						</div>
						:
						<>
							<span className={styles.number}>1</span>

							<h3>De acum, îți poți cheltui <br/>punctele și în IQOS.ro</h3>
							<p>Cu punctele acumulate poți achita parțial sau total contravaloarea produselor pe care ți le dorești
								din IQOS Shop-ul de pe iqos.ro.</p>
						</>
					}

					<ul className={styles.threeColumns}>
						<li className={isPWA ? styles.pwaList : ''}>
							<img src={isPWA ? PwaIcon04 : HowToImg1} title="Cartuse HEETS"
								 alt="imagine cartuse HEETS"/>
							<p>Cu bani</p>
						</li>
						<li className={isPWA ? styles.pwaList : ''}>
							<img src={isPWA ? PwaIcon05 : HowToImg2} title="Dispozitive IQOS si accesorii"
								 alt="imagine dispozitive IQOS si accesorii"/>
							<p>Cu puncte</p>
						</li>
						<li className={isPWA ? styles.pwaList : ''}>
							<img src={isPWA ? PwaIcon06 : HowToImg3} title="Servicii si experiente"
								 alt="imagine servicii si experiente"/>
							<p>Și cu bani și cu puncte</p>
						</li>
					</ul>

					<Button noLink onClick={() => window.location = config.path.shopIQOS} type="primary" color={isPWA ? 'gold' : ''}>Intră în shop</Button>
				</div>
			)
		};

		const StepTwo = () => {
			return(
				<div className={styles.section}>
					{isPWA ?
						<>
							<div className={styles.stepDetails}>
								<img src={PwaIcon09} alt=""/>

								<h3>Revendică în <br />centrele de premiere IQOS</h3>
								<p>Fiecare membru IQOS CLUB poate alege să revendice beneficiile constând în pachete HEETS și în
									centrele de premiere din punctele de vânzare dedicate IQOS, benzinăriile MOL și ROMPETROL sau
									magazinele Inmedio, 1Minute, HUB și Tabac Xpress.</p>
							</div>
						</>
						:
						<>
							<span className={styles.number}>3</span>

							<h3>Revendică în <br />centrele de premiere IQOS</h3>
							<p>Fiecare membru IQOS CLUB poate alege să revendice beneficiile constând în pachete HEETS și în
								centrele de premiere din punctele de vânzare dedicate IQOS, benzinăriile MOL și ROMPETROL sau
								magazinele Inmedio, 1Minute, HUB și Tabac Xpress.</p>
						</>
					}

					<h4>Ce ai nevoie pentru revendicările <br />din centrele de premiere IQOS?</h4>

					<ul className={styles.threeColumns}>
						<li>
							<h4>Aplicația <br />pentru smartphone ISPOT</h4>
							<p>Cu ajutorul aplicației vei putea genera un cod necesar revendicării.</p>

							<a href="https://play.google.com/store/apps/details?id=ro.telcor.retaileriqos&hl=en_US" target="_blank">
								<img src={GooglePlay} title="ispot-app-android" alt="ispot-app-android-icon" />
							</a>
							<a href="https://itunes.apple.com/ro/app/ispot-app/id1204827004?mt=8" target="_blank">
								<img src={AppStore} title="ispot-app-ios" alt="ispot-app-ios-icon" />
							</a>
						</li>

						{!isPWA && <li><div className={styles.centerAlign}><span>sau</span></div></li> }

						<li>
							<h4>Codul din <br />IQOS CLUB</h4>
							<p>Cu punctele acumulate, selectezi câte pachete dorești și apoi îți poți genera codul special.</p>

							<Button toPage={config.path.offlineRedemption} type='primary' color={isPWA && 'gold'}>Generează cod de revendicare</Button>
						</li>
					</ul>
				</div>
			)
		};

		const StepThree = () => {
			return(
				<div className={classnames(styles.stepTwo, isPWA ? styles.isPWA : '')}>
					<div className={styles.container}>
						{isPWA ?
							<>
								<div className={styles.stepDetails}>
									<img src={PwaIcon08} alt=""/>

									<h3>Revendică online <br/>din catalogul IQOS Club</h3>
									<p>În catalogul IQOS Club poți revendica produse create de designeri români, din secțiunea Qollection,
										alături de vouchere și experiențe cadou de la partenerii IQOS.</p>
								</div>
							</>
							:
							<>
								<span className={styles.number}>2</span>

								<h3>Revendică online <br/>din catalogul IQOS Club</h3>
								<p>În catalogul IQOS Club poți revendica produse create de designeri români, din secțiunea Qollection,
									alături de vouchere și experiențe cadou de la partenerii IQOS.</p>
							</>
						}

						<ul className={classnames(styles.threeColumns, isPWA ? styles.isPWA : '')}>
							<li>
								<h4>
									<img src={QollectionLogo} title="Qollection by Romanian Designers" alt="qollection-logo" />
									by Romanian Designers
								</h4>
								<img src={isPWA ? PwaImage01 : HowToImg4} alt=""/>
							</li>

							<li>
								<h4>Experiențe și <br/>vouchere cadou</h4>
								<img src={isPWA ? PwaImage02 : HowToImg5} alt=""/>
							</li>
						</ul>

						<Button toPage={config.path.catalog} type='primary' color={isPWA && 'gold'}>Vezi catalogul</Button>
					</div>
				</div>
			)
		};

		return (
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>De acum, îți poți folosi <br/> punctele și în IQOS.ro</h1>
				</div>

				<div className={styles.howToUse}>
					<div className={styles.container}>
						<h2>Află cum poți revendica beneficiile IQOS Club <br />cu punctele din contul tău.</h2>

						<StepOne />
					</div>

					<StepThree />

					<div className={styles.container}>
						<StepTwo />
						<MapPreview />
					</div>
				</div>
			</Layout>
		)
	}
}

export default HowTo;
