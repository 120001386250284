import React, {Component} from 'react'
import {connect} from 'react-redux';
import ReferralAction from "../../../../stores/Referral/Actions";
import Layout from '../../../../components/Layout'
import styles from './ReceiveCard.module.scss'
import store from "store";
import config from '../../../../config'
import Card from '../SendCard/Card'
import Button from '../../../../components/Button'
import classnames from 'classnames';

import {IQOSTitle} from './SvgIQOS';
import FirstImg from './images/russet.png';
import SecondImg from './images/second.png';
import SecondImgPWA from './images/secondPWA.png';
import Loader from '../../../../components/Loader';
import FdaSection from '../fdaSection/fdaSection';

class ReceiveCard extends Component {
    state = {
        isPWA: store.get(config.user.isPWA),
        discover: false,
        isMobile: window.innerWidth <= 570,
    };

    componentDidMount() {
        const token = this.props.match.params.token;
        this.props.getCard(token);
        window.addEventListener('resize', () => this.setState({isMobile: window.innerWidth <= 570}))
    }

    render() {
        const {isMobile, isPWA} = this.state;
        const { loading, cardData } = this.props;

        return (
            <Layout>
                {loading ? <Loader /> :
                    <div className={styles.pageContainer}>
                        <div className={styles.imageContainer} />

                        <div className={styles.contentContainer}>
                            <div className={classnames(styles.title, isPWA ? styles.isPWA : '')}> {cardData.user.first_name} are un mesaj pentru tine
                            </div>

                            <div className={styles.cardContainer}>
                                <Card userName={cardData.user.first_name} friendName={cardData.person_name} message={cardData.image.content} photo={cardData.image.image}/>
                            </div>

                            {this.state.discover !== true &&
                                <Button className={!isPWA ? styles.buttonNOTPWA : ''} style={{margin: '40px auto'}} noLink type="primary" color="gold" onClick={() => this.setState({discover: true})}>
                                    DESCOPERĂ MAI MULTE
                                </Button>
                            }

                            {this.state.discover &&
                                <div className={classnames(styles.discoverContainer, isPWA ? styles.isPWA : '')}>
                                    <div className={classnames(styles.sectionContainerFirst, isPWA ? styles.isPWA : '')}>
                                        <h3 className={isPWA ? styles.sectionTitlePWA : styles.sectionTitle}> Alege să faci o schimbare </h3>

                                        <div className={styles.section}>
                                            <div className={styles.leftSection}>
                                                <p className={isPWA ? styles.isPWA : ''}>Alege o viață fără fum și fără scrum cu dispozitivele IQOS.</p>
                                                <p className={isPWA ? styles.isPWA : ''}>Acum poți achiziționa un <b>Starter Kit IQOS 2.4+</b> la prețul de <b>149 RON</b>, cu
                                                    posibilitatea de a-l returna și de a primi banii înapoi în 30 de zile dacă te răzgândești.
                                                </p>

                                                {!isMobile && <Button className={!isPWA ? styles.buttonNOTPWA : ''} toPage={config.path.aboutIQOS} type="primary" color="gold">Află mai multe</Button>}
                                                {isMobile && <img src={FirstImg}/>}
                                            </div>

                                            {!isMobile && <img src={FirstImg}/>}
                                            {isMobile && <Button className={!isPWA ? styles.buttonNOTPWA : ''} noLink type="primary" color="gold">Află mai multe</Button>}
                                        </div>
                                    </div>
                                    <FdaSection />
                                    <div className={classnames(styles.sectionContainerSecond, isPWA ? styles.isPWA : '')}>
                                        <div className={styles.section}>
                                            <div className={styles.leftSection}>
                                                <IQOSTitle/>
                                                <p className={isPWA ? styles.isPWA : ''}> Află pas cu pas cum funcționează
                                                    dispozitivele IQOS, prin tutoriale video disponibile direct pe telefonul tău
                                                    mobil. </p>
                                                {!isMobile && <Button className={!isPWA ? styles.buttonNOTPWA : ''} toPage={config.path.IQOSPocket} type="primary" color="gold" >Încearcă Pocket</Button>}
                                                {isMobile && <img src={isPWA ? SecondImgPWA : SecondImg}/>}
                                            </div>

                                            {!isMobile && <img src={isPWA ?SecondImgPWA : SecondImg}/>}
                                            {isMobile && <Button className={!isPWA ? styles.buttonNOTPWA : ''} toPage={config.path.IQOSPocket} type="primary" color="gold" >Încearcă Pocket</Button>}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </Layout>
        )
    }
}

const mapStateToProps = state => ({
    cardData: state.referral.cardData,
    loading: state.referral.loading
});

const mapDispatchToProps = {
    getCard: (token) => ReferralAction.referralCard(token)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReceiveCard);
