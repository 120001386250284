import { all } from 'redux-saga/effects';
import loginSaga from './Login/Saga';
import logoutSaga from './Logout/Saga';
import countiesSaga from './Counties/Saga';
import referralSaga from './Referral/Saga';
import offlineRedemptionSaga from './OfflineRedemption/Saga';
import forgotPasswordSaga from './ForgotPassword/Saga';
import insertCodesSaga from './InsertCode/Saga';
import pointsSaga from './Profile/PointsHistory/Saga';
import userSaga from './User/Saga';
import devicesSaga from './Devices/Saga';
import mapSaga from './Map/Saga';

export default function* root() {
    yield all([
        loginSaga(),
        logoutSaga(),
        countiesSaga(),
        referralSaga(),
        insertCodesSaga(),
        offlineRedemptionSaga(),
        forgotPasswordSaga(),
        pointsSaga(),
        userSaga(),
        devicesSaga(),
        mapSaga()
    ]);
}
