import React from 'react';
import styles from './ImageSectionEqual.module.scss';
import classnames from 'classnames';

const ImageSectionEqual = ({ image, background, children, imageRight, classname, onClick, imageContainerClassname }) => {
	return (
		<div style={{ backgroundColor: background }} className={classnames(styles.imageSectionEqual, imageRight && styles.imageRight, classname)}>
			<div className={classnames(styles.imageContainer, imageContainerClassname)}>
				<img src={image} alt="" onClick={onClick} />
			</div>
			{children}
		</div>
	);
};

export default ImageSectionEqual;
