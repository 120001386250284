import React from 'react';
import {Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import LogoutActions from '../../../stores/Logout/Actions';
import config from '../../../config';
import {connect} from 'react-redux';
import store from "store";

class MenuList extends React.Component {
    render() {
        return (
            <>
                    <li className="sub-menu">
                        <LinkContainer to={config.path.benefits}>
                            <Nav.Link eventKey="">BENEFICII IQOS</Nav.Link>
                        </LinkContainer>
                    </li>
                    <li>
                        <LinkContainer to={config.path.referral}>
                            <Nav.Link eventKey="">Trimite invitație</Nav.Link>
                        </LinkContainer>
                    </li>
                    <li>
                        <LinkContainer to={config.path.referralList}>
                            <Nav.Link eventKey="">Invitațiile tale</Nav.Link>
                        </LinkContainer>
                    </li>
                    <li>
                        <LinkContainer to={config.path.offlineRedemption}>
                            <Nav.Link eventKey="">Revendicare HEETS</Nav.Link>
                        </LinkContainer>
                    </li>
            </>
        );
    }
}

const mapDispatchToProps = {
    logout: () => LogoutActions.logout()
};

export default connect(
    null,
    mapDispatchToProps
)(MenuList);

