import React from 'react'
import Button from '../../../../../../components/ButtonV3/Button';
import styles from './IQOSPocket.module.scss'
import config from '../../../../../../config'
import ImageSectionEqual from '../../../../../../components/ImageSectionEqual/ImageSectionEqual';
import logo from './iqos-pocket.svg';
import devicePocket from './devicePocket.jpg';

const IQOSPocket = () =>                                     
    <div className={styles.iqosPocketContainer}>
            <ImageSectionEqual
                image={devicePocket}
                background={'#E3813B'}
                imageRight={true}
                classname={styles.pocket}
                imageContainerClassname = {styles.image}>
                <div className={styles.detailsStarter}>
                    <img src={logo} alt=""/>
                    <p>Află pas cu pas cum funcționează dispozitivele IQOS prin tutoriale video disponibile direct pe telefonul tău mobil.</p>
                    <Button type={'darkWithBlue'} href={config.path.IQOSPocket}>Vezi tutorialele</Button>
                    <p>Informație importantă: IQOS nu este lipsit de riscuri. IQOS este destinat exclusiv fumătorilor adulți.</p>
                </div>
            </ImageSectionEqual>
    </div>



export default IQOSPocket