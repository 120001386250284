import React from 'react'
import styles from './FormDetails.module.scss'
import SixtyPoints from "./images/60-points.png";
import SixtyPoints2 from "./images/60-points-2.png";
import ThirtyDays from "./images/30-days.png";

const benefits = {
    sixtyPoints: {
        icon: SixtyPoints2,
        text: 'Pentru prima recomandare cu succes'
    },
    sixtyPoints2: {
        icon: SixtyPoints,
        text: 'Pentru prima recomandare cu succes'
    },
    thirtyDays: {
        icon: ThirtyDays,
        text: 'Pentru recomandările tale din perioada de Lending'
    },
    thirtyDays2: {
        icon: ThirtyDays,
        text: 'Pentru activitățile de acumulare puncte din IQOS Club dacă achiziționezi dispozitivul IQOS'
    },
};
const {sixtyPoints, sixtyPoints2, thirtyDays, thirtyDays2} = benefits

const Benefit = ({text, icon}) => 
    <div className={styles.reward}>
        <img src={icon} alt={text}/>
        <p dangerouslySetInnerHTML={{__html: text}}/>
    </div>

const Triangle = () => 
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="39" viewBox="0 0 34 39">
        <path id="Polygon_3" data-name="Polygon 3" d="M19.5,0,39,34H0Z" transform="translate(34) rotate(90)" fill="#d6a76f"/>
    </svg>

const Plus = () => 
<svg className="svg-circleplus" height="70" width="70" stroke="#D6A76F" viewBox="0 0 100 100">
  <line x1="32.5" y1="50" x2="67.5" y2="50" strokeWidth="5"></line>
  <line x1="50" y1="32.5" x2="50" y2="67.5" strokeWidth="5"></line>
</svg>


const Benefits = () =>                                 
    <div className={styles.newRewards}>
        <Benefit icon={sixtyPoints.icon} text={sixtyPoints.text}/>
        <div className={styles.triangleContainer}>
            <Triangle />
        </div>
        <div className={styles.benefitContainer}>
            <Benefit icon={thirtyDays.icon} text={thirtyDays.text}/>
            <span className={styles.or}>SAU</span>
            <Benefit icon={thirtyDays2.icon} text={thirtyDays2.text}/>
        </div>
    </div>

const Benefits2 = () =>
    <div className={styles.newRewards2}>
        <Benefit icon={sixtyPoints2.icon} text={sixtyPoints2.text}/>
        <div className={styles.triangleContainer}>
            <Triangle />
        </div>
        <Benefit icon={thirtyDays.icon} text={thirtyDays.text}/>
    </div>

export {Benefit, Benefits, Benefits2, Plus}