import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	winners: ['per_page', 'page', 'filters'],
	winnersLoading: null,
	winnersSuccess: ['winners'],
	winnersFail: ['errors'],

	filters: null,
	filtersLoading: null,
	filtersSuccess: ['filters'],
	filtersFail: ['errors']
});

export const WinnersTypes = Types;
export default Creators;
