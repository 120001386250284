import React from 'react';
import store from 'store';
import moment from 'moment';

import {connect} from 'react-redux';
import classnames from "classnames";
import config from "../../../../config";

// Assets
import 'react-dates/lib/css/_datepicker.css';
import styles from './Table.module.scss';
import 'react-dates/initialize';


// Components
import Loader from "react-loader-spinner";
import Button from "../../../../components/Button/Button";
import {DateRangePicker} from "react-dates";

class ReferralTable extends React.Component {
	state = {
		focusedInput: null,
		startDate: new moment(),
		endDate: new moment().add(1, 'days')
	};

	render() {
		const {treeData} = this.props;
		const {startDate, endDate, focusedInput} = this.state;

		if (treeData && treeData.content) {
				// Not PWA
				return (
					<>
						<table className={styles.referralTable}>
							<thead>
							<tr>
								<th scope="col">Nume și prenume</th>
								<th scope="col">Status</th>
							</tr>
							</thead>

							<tbody>
							{
								treeData.content.recommendationsArray.map((item, id) => (
									<tr key={id}>
										<td data-label="Nume">{item.name}</td>
										<td data-label="Status">
											<div
												className={classnames(item.success === 1 ? styles.isSuccess : styles.isProgress)}>{item.status}</div>
										</td>
									</tr>
								))
							}
							</tbody>
						</table>
					</>
				)
		} else {
			// Loading state
			return (
				<div className={styles.referralPage}>
					<div className={styles.container}>
						<div style={{
							width: '100%',
							marginTop: 50,
							marginBottom: 50,
							textAlign: 'center'
						}}>
							<Loader
								type="MutatingDots"
								color="#d6a76f"
								height={100}
								width={100}
							/>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default connect(
)(ReferralTable)
