import React from 'react';
import { connect } from 'react-redux';
import store from 'store';

// Components
import IntroSection from './components/Intro';
import Layout from "../../../components/Layout";
import Cards from './components/Cards';
import Tiers from './components/Tiers';
import config from "../../../config";

class HomeNotLoggedScreen extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA)
    };

    componentDidMount() {
        store.set(config.user.exploreMode, false);
    }

    render() {
        const {isPWA} = this.state;

        return (
            <Layout>
                <IntroSection />
                <Cards />
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.login.loading
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeNotLoggedScreen);
