import React from "react";
import store from "store"
import classnames from "classnames";
import config from "../../../config";
import {connect} from "react-redux";

import Reaptcha from "reaptcha";
import ReeValidate from "ree-validate";

// Assets
import styles from "./Form.module.scss";
import {animateScroll as scroll, Link, scroller} from "react-scroll/modules";

// Components
import Button from "../../../components/Button/Button";
import OfflineRedemptionActions from "../../../stores/OfflineRedemption/Actions";
import Reveal from "react-reveal/Reveal";

class Form extends React.Component {
	constructor(props) {
		super(props);

		let dictionary = {
			en: {
				messages: {
					required: () => 'Acest câmp este obligatoriu.',
				}
			}
		};

		this.validator = new ReeValidate({
			packs: 'required',
			gRecaptchaResponse: 'required'
		});

		this.validator.localize(dictionary);
		this.captcha = null;

		this.state = {
			isPWA: store.get(config.user.isPWA),
			errors: this.validator.errors,
			captchaReady: false,
			formData: {
				packs: "",
				gRecaptchaResponse: ""
			},
			savedResponse: null
		};

		this.handleChange = this.handleChange.bind(this);
		this.validateAndSubmit = this.validateAndSubmit.bind(this);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.submitResponse && this.props.submitResponse.content.code && this.state.savedResponse !== this.props.submitResponse.content.code) {
			this.setState((state) => ({
				...state,
				savedResponse: this.props.submitResponse.content.code
			}));
			scroller.scrollTo('successCode', {
				duration: 800,
				offset: -200,
				delay: 0,
				smooth: 'easeInOutQuart'
			});
		}
	}
	onLoad = () => {
		this.setState({
			captchaReady: true
		});
	};

	onChange = (value) => {
		this.setState((state) => ({
			...state,
			formData: {
				...state.formData,
				gRecaptchaResponse: value
			}
		}));
	};

	handleChange = (field, value) => {
		const {errors} = this.validator;
		errors.remove(field);

		this.setState((state) => ({
			...state,
			formData: {
				...state.formData,
				[field]: value
			}
		}));

		this.validator.validate(field, value)
			.then(() => {
				this.setState({errors})
			});
	};

	validateAndSubmit(e) {
		e.preventDefault();

		// this.captcha.execute();

		const {formData} = this.state;
		const {errors} = this.validator;

		this.validator.validateAll(formData)
			.then(success => {
				if (success) {
					this.props.submitRedeem(formData);
				} else {
					this.setState({errors})
				}
			});
	}

	render() {
		const {isPWA, errors} = this.state;
		const {availablePoints, availablePacks, submitResponse} = this.props;
		const ErrorIcon = () => (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.883 30.883">
				<path
					d="M26.366,4.518a15.448,15.448,0,1,0,0,21.846A15.466,15.466,0,0,0,26.366,4.518ZM24.68,24.68a13.066,13.066,0,1,1,0-18.477A13.081,13.081,0,0,1,24.68,24.68Zm-2.254-3.132a.873.873,0,0,1-1.6.693,5.464,5.464,0,0,0-5.073-3.207,5.418,5.418,0,0,0-5.1,3.2.873.873,0,1,1-1.613-.67,7.159,7.159,0,0,1,6.716-4.281A7.209,7.209,0,0,1,22.426,21.548ZM9.679,11.26a1.805,1.805,0,1,1,1.806,1.806A1.806,1.806,0,0,1,9.679,11.26Zm8.187,0a1.806,1.806,0,1,1,1.806,1.806A1.806,1.806,0,0,1,17.866,11.26Z"
					transform="translate(0 0)" fill="#d6a76f"/>
			</svg>
		);

		return (
			<div className={classnames(isPWA ? styles.generateCodePWA : styles.generateCode)} id="form">
				{
					availablePoints > 20 ? (
						<>
							{!isPWA &&
							<Link to="form" smooth={true} offset={-50} duration={500}>
								<span className={styles.scrollDown}></span>
							</Link>
							}

							<form onSubmit={this.validateAndSubmit}>
								<Reveal effect="fadeInUp" cascade>
									<p>
										Cu punctele disponibile în contul tău poți revendica un maxim
										de <b> {availablePacks} pachete Heets</b>. 1 pachet de HEETS valorează 20 de puncte.
									</p>
									<h3>Selectează numărul de pachete Heets dorit</h3>

									<label
										className={classnames('select', styles.simpleSelect, {'has-error': errors.has('packs')})}>
										<select name="packs" value={this.state.formData.packs}
												onChange={(e) => this.handleChange('packs', e.target ? e.target.value : e)}>
											<option value="" disabled>Selectează</option>
											{
												[...new Array(availablePacks).keys()].map(i => {
													const index = i + 1;
													if (index <= 10) {
														return (
															<option key={index}
																	value={index}>{index} {index > 1 ? 'pachete' : 'pachet'} HEETS</option>
														)
													}
												})
											}
										</select>
										{errors.has('packs') &&
										<span className="error">{errors.first('packs')}</span>
										}
									</label>
								</Reveal>

								<Reaptcha
									ref={e => (this.captcha = e)}
									sitekey="6LfRALgUAAAAAH4Dvwupku82fyCNItFCPYP9qLnY"
									onLoad={this.onLoad}
									onVerify={this.onChange}
									// size="invisible"
								/>

								{errors.has('gRecaptchaResponse') &&
								<span className={styles.error}>{errors.first('gRecaptchaResponse')}</span>
								}

								<p>Vrei să folosești din puncte pentru un cod de revendicare?</p>
								<button type="submit" className={styles.submit} disabled={!this.state.captchaReady}>Da,
									generează cod de revendicare
								</button>

								{submitResponse && submitResponse.responseStatus === "failure" &&
									<span className={styles.error}>{submitResponse.errorMessage}</span>
								}

								<p>*Poți revendica pachete HEETS în valoare de cel mult <b>200 de puncte</b>.</p>
							</form>
						</>
					) : (
						<div className={styles.noPoints}>
							<ErrorIcon/> <br/>

							<p>Nu ai suficiente puncte pentru această activitate. Mergi în zona de activități și câștigă
								punctele necesare.</p>
							<br/>
							<Button type="primary" toPage={config.path.referral}>Vezi activitățile</Button>
						</div>
					)
				}

				{
					submitResponse && submitResponse.responseStatus === "success" &&
					<div className={styles.outputCode} id="successCode">
						<p>
							<small>Prezintă codul următor în centrele de premiere IQOS</small>
						</p>
						<h3>{submitResponse.content.code}</h3>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	submitResponse: state.offlineRedemption.submitResponse
});

const mapDispatchToProps = {
	submitRedeem: (formData) => OfflineRedemptionActions.submitRedeem(formData)
};


export default connect(
	mapStateToProps, mapDispatchToProps,
)(Form);
