import React from 'react';
import { Fade } from 'react-reveal';

import styles from './Benefits.module.scss';
import Button from '../../../components/ButtonV3/Button';
import ImageSection from "../../../components/ImageSection/ImageSection";

import Icon from './images/icon1.svg';
import Icon1 from './images/icon2.svg';
import Icon2 from './images/icon3.svg';
import Icon3 from './images/icons-pictogram-like.svg';
import s1image from './images/couple.jpg';

import Layout from "../../../components/Layout";
import classnames from "classnames";
import config from '../../../config';
import EducatedAwarenessComponent from "../Components/EducatedAwarenessComponent/EducatedAwareness";

class Benefits extends React.Component {

    render() {
        const reasons = [
            {
                icon: Icon,
                title: 'Fără ardere, fără gudron',
                description: 'Gudronul este reziduul din fumul produs în urma arderii țigării. IQOS nu produce gudron, pentru că încălzește tutunul și nu îl arde.',
                disclaimer: 'Informații importante: Deși IQOS nu produce gudron, IQOS nu este lipsit de riscuri și eliberează nicotină, care provoacă dependență.'
            }
            ,
            {
                icon: Icon1,
                title: 'Fără fum, fără cantități mari de monoxid de carbon',
                description: 'IQOS eliberează în medie cu 98% mai puțin monoxid de carbon în comparație cu țigările tradiționale.',
                disclaimer: 'Informații importante:  Aceasta nu înseamnă că există o reducere cu 98% a riscului. IQOS nu este lipsit de riscuri și eliberează nicotină, care provoacă dependență.'
            },
            {
                icon: Icon2,
                title: 'Fără să miroși a fum',
                description: 'Hainele, părul, mâinile nu mai miros a fum de țigară și e mai puțin probabil să îi deranjezi pe cei din jur.',
                // disclaimer: 'Informații importante:  Aceasta nu echivalează cu o reducere cu 98% a riscului. IQOS nu este lipsit de riscuri. Eliberează nicotină, care provoacă dependență.'
            }, {
                icon: Icon3,
                title: 'O alegere  mai bună',
                description: `IQOS este o alegere mai bună față de continuarea fumatului.`,
                disclaimer: 'Informații importante: IQOS nu este lipsit de riscuri, eliberează nicotină, care provoacă dependență.'
            }];

        return (
            <Layout>
                <div className={classnames('page_heading', 'isReferral', 'hasSubheading')}>
                    <div className='rowHeading'>
                        <div className='textContainer'>
                            <div className='detailsHeader'>
                                <h2>Același ritual.</h2>
                                <h2>Aceeași comunitate.</h2>

                                <p>Recomandă IQOS unui prieten adult fumător și bucurați-vă împreună de beneficii.</p>
                            </div>
                            {/*{index === 1 && <Button noLink type='primary' onClick={() => this.handleTabChange(0)}> Descoperă avantajele IQOS </Button>}*/}
                        </div>
                        <div className= 'imageHeader' />
                    </div>
                </div>

                <div className={styles.container}>
                    <Fade bottom cascade>
                        <ImageSection background={'#DB3624'} image={s1image} classname={styles.customImgSection}>
                            <div className={styles.details}>
                                <h3>Experiențele din IQOS Club ne apropie și mai mult.</h3>
                                <p>Împărtășește cu cei dragi experiența ta cu IQOS și veți fi parte a unei comunități vibrante.<br/> <br/> Invită-ți prietenii adulți fumători să încerce și ei IQOS. Dacă aceștia decid să achiziționeze kit-ul IQOS, toți veți avea parte de avantaje în IQOS Club.</p>
                                <Button type="borderWhite" href={config.path.referral}>
                                    Invită-ți prietenii
                                </Button>
                            </div>
                        </ImageSection>

                        <div className={styles.reasonsContainer}>
                            <h3>Experiențele comune ne apropie și mai mult.</h3>
                            <div className={styles.reasons}>
                                {reasons.map(({icon, title, description, disclaimer}, id) =>
                                    <div className={styles.reason} key={id}>
                                        <div className={styles.iconContainer}>
                                            <img src={icon} alt="icon-benefits" />
                                        </div>
                                        <h3> {title}</h3>
                                        <p>{description}</p>
                                        <small>{disclaimer}</small>
                                    </div>)
                                }
                            </div>
                        </div>

                        <Button type="darkWithBlue" href={config.path.referral}>
                            Invită-ți prietenii
                        </Button>
                    </Fade>
                    {/*<FdaSection />*/}
                    <EducatedAwarenessComponent/>
                </div>
            </Layout>
        );
    }
}

export default Benefits;
