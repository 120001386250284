import React from 'react';
import store from 'store';
import Slider from 'react-slick';
import classnames from 'classnames';
import ReeValidate from 'ree-validate';

import config from '../../../../config';
import styles from './SendCard.module.scss';

// Saga
import { connect } from 'react-redux';
import ReferralAction from '../../../../stores/Referral/Actions';

import { FacebookMessengerShareButton, WhatsappShareButton, WhatsappIcon, FacebookMessengerIcon } from 'react-share';

// Components
import Card from './Card';
import Layout from '../../../../components/Layout';
import Loader from '../../../../components/Loader';
import Button from "../../../../components/ButtonV3/Button";

class SendCard extends React.Component {
    constructor(props) {
        super(props);
        let dictionary = {
            en: {
                messages: {
                    required: () => 'Acest câmp este obligatoriu.',
                    max: (field, value) => `Te rugăm să introduci maxim ${value} de caractere.`
                }
            }
        };

        this.validator = new ReeValidate({
            name: 'required|max:32'
        });

        this.validator.localize(dictionary);
        this.state = {
            isPWA: store.get(config.user.isPWA),
            isLoggedIn: store.get(config.user.token),
            name: '',
            slide: 0,
            userName: store.get(config.user.data),
            errors: this.validator.errors,
            isMobile: window.innerWidth < 920
        };

        this.handleCurrentSlide = this.handleCurrentSlide.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (store.get(config.user.token)) {
            this.props.getCards();
        }
        window.addEventListener('resize', this.isMobileWidth);
    }

    isMobileWidth = () => {
        this.setState({ isMobile: window.innerWidth < 920 });
    };

    handleCurrentSlide = (currentSlide) => {
        const { cardsData } = this.props;
        this.setState({ slide: currentSlide });
    };

    handleInputChange = (field, value) => {
        const { errors } = this.validator;
        errors.remove(field);

        this.setState({ name: value });
        this.validator.validate(field, value).then(() => this.setState({ errors }));
    };

    handleSubmit = async (e) => {
        // e.preventDefault();

        const { name, slide } = this.state;
        const { errors } = this.validator;
        const { submit, cardsData } = this.props;

        errors.remove('name');

        const isValid = await this.validator.validate('name', name);

        if (isValid) {
            const activeSlide = cardsData[slide].id;
            submit({image_id: activeSlide, person_name: name});
        } else {
            this.setState({ errors });
        }
    };

    handleCopyText = (e) => {
        // e.preventDefault();
        let copyText = document.getElementById('link-text');
        copyText.select();
        copyText.setSelectionRange(0, 1000);

        document.execCommand('copy');
    };

    render() {
        const { cardsData, success, cardData, loading } = this.props;
        const { isPWA, userName, isMobile } = this.state;

        const settings = {
            variableWidth: true,
            focusOnSelect: true,
            infinite: true,
            dots: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1040,
                    settings: {
                        centerMode: true,
                        variableWidth: false
                    }
                },
                {
                    breakpoint: 920,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        variableWidth: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        infinite: true,
                        variableWidth: false,
                        dots: false
                    }
                }
            ]
        };

        return (
            <Layout>
                {success && (
                    <div className={styles.pageContainer}>
                        <div className={styles.heading}>
                            <h3>
                                Mesajul tău a fost generat
                            </h3>
                        </div>
                        <div className={styles.successContainer}>
                            <h3>Trimite mesajul!</h3>
                            <p>Așa arată mesajul ales de tine. E timpul să-l trimiți.</p>
                            <div className={classnames(styles.sendCardLink)}>
                                <span>Link-ul tău personalizat</span>
                                <input
                                    // className={isPWA ? styles.isPWA : ''}
                                    type="text"
                                    value={cardData && cardData.link}
                                    id="link-text"
                                />
                                <Button type='darkWithBlue' onClick={this.handleCopyText}>
                                    Copiază link-ul
                                </Button>
                            </div>

                            <div className={styles.socialMedia}>
                                <div className={styles.buttons}>
                                    <FacebookMessengerShareButton
                                        openShareDialogOnClick={true}
                                        url={cardData.link}
                                    >
                                        <Button type='transparent'>
                                            {/*<img src={messenger} alt=""/>*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                <g id="Group_13655" data-name="Group 13655" transform="translate(-40 -589)">
                                                    <g id="Ellipse_148" data-name="Ellipse 148" transform="translate(40 589)" fill="none" stroke="#34303d" stroke-width="1.5">
                                                        <circle cx="9" cy="9" r="9" stroke="none"/>
                                                        <circle cx="9" cy="9" r="8.25" fill="none"/>
                                                    </g>
                                                    <path id="Path_19571" data-name="Path 19571" d="M183.8,196.773c.348,0,.665.006.982,0,.176,0,.256.057.235.24-.047.423-.079.848-.141,1.268a.294.294,0,0,1-.219.168c-.273.021-.548.008-.857.008v.236q0,1.668,0,3.337c0,.278-.017.294-.3.294h-1.322c-.291,0-.309-.016-.309-.3q0-1.636,0-3.272v-.291c-.177,0-.341,0-.5,0-.178.007-.267-.057-.263-.25.008-.4.007-.794,0-1.192,0-.177.068-.251.244-.242s.328,0,.5,0c.016-.354.014-.685.048-1.013a1.6,1.6,0,0,1,1.616-1.5c.419-.011.838,0,1.257,0,.178,0,.245.081.243.251q-.006.574,0,1.148c0,.172-.065.252-.244.246-.238-.008-.477,0-.715,0-.17,0-.258.061-.253.24C183.805,196.343,183.8,196.544,183.8,196.773Z" transform="translate(-134.084 399.724)" fill="#34303d"/>
                                                </g>
                                            </svg>
                                            <span>Share pe Facebook</span>
                                        </Button>
                                    </FacebookMessengerShareButton>

                                    <WhatsappShareButton
                                        openShareDialogOnClick={true}
                                        url={cardData.link}>
                                        <Button type='transparent'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.998" viewBox="0 0 18 17.998">
                                                <g id="wWPM4W" transform="translate(-589.293 -93.4)">
                                                    <g id="Group_13638" data-name="Group 13638" transform="translate(589.293 93.4)">
                                                        <path id="Path_19569" data-name="Path 19569" d="M589.293,111.4c.275-.81.535-1.575.793-2.34.244-.72.492-1.439.723-2.163a.517.517,0,0,0-.033-.368,8.845,8.845,0,1,1,9.253,4.268,8.943,8.943,0,0,1-5.677-.852.545.545,0,0,0-.371-.029C592.435,110.4,590.892,110.89,589.293,111.4Zm2.225-2.181c.126-.033.213-.053.3-.079.788-.25,1.574-.506,2.365-.745a.472.472,0,0,1,.34.042,7.358,7.358,0,1,0-3.5-6.4,7.157,7.157,0,0,0,1.263,4.232.5.5,0,0,1,.067.5c-.221.613-.426,1.231-.635,1.848C591.648,108.8,591.59,108.992,591.518,109.217Z" transform="translate(-589.293 -93.4)"/>
                                                        <path id="Path_19570" data-name="Path 19570" d="M685.575,190.1a2.811,2.811,0,0,1,.766-1.788,1.564,1.564,0,0,1,1.348-.429.5.5,0,0,1,.326.272c.308.677.616,1.357.862,2.057a.973.973,0,0,1-.177.644,2.791,2.791,0,0,1-.453.54.377.377,0,0,0-.065.509,6.658,6.658,0,0,0,3.4,2.892.361.361,0,0,0,.451-.124c.24-.291.493-.572.714-.877a.424.424,0,0,1,.584-.17c.666.311,1.334.617,1.986.955a.511.511,0,0,1,.185.4,2.1,2.1,0,0,1-1.737,2.01,3.407,3.407,0,0,1-1.611-.1,9.4,9.4,0,0,1-5.452-3.977,5.7,5.7,0,0,1-1.046-2.11C685.616,190.567,685.6,190.331,685.575,190.1Z" transform="translate(-681.33 -183.627)"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span>Share pe WhatsApp</span>
                                        </Button>
                                    </WhatsappShareButton>
                                </div>
                            </div>
                            {cardData && (
                                <Card
                                    friendName={cardData.person_name}
                                    userName={userName.first_name}
                                    message={cardData.image.content}
                                    greetings={cardData.greetings}
                                    photo={cardData.image.image}
                                    slogan={ cardData.image.slogan}
                                />
                            )}
                        </div>
                    </div>
                )}

                {!success && (
                    <div className={styles.pageContainer}>
                        <div className={classnames(styles.contentContainer, isPWA ? styles.isPWA : '')}>
                            <div className={styles.heading}>
                                <h3>
                                    Ne bucurăm că vrei să împărtășești <br/> experiențele IQOS cu cineva drag.
                                </h3>
                            </div>
                            <div className={classnames(styles.textContainer)}>
                                <h3>
                                    Alege mesajul
                                </h3>
                                <p>
                                    Alege unul dintre mesajele de încurajare, completează numele prietenului adult fumător și invită-l în lumea IQOS.
                                </p>

                                <form className={styles.form} onSubmit={this.handleSubmit} id="my-form">
                                    <label className={classnames('referralFriendName',{ 'has-error': this.state.errors.has('name') })}>
                                        <p>Numele prietenului</p>
                                        <input
                                            type="text"
                                            placeholder="ex: Alexandru"
                                            value={this.state.name}
                                            id="name"
                                            maxLength={32}
                                            onChange={(e) =>
                                                this.handleInputChange('name', e.target ? e.target.value : e)
                                            }
                                        />
                                        {this.state.errors.has('name') && (
                                            <span className="error" style={{marginLeft: '60px'}}>{this.state.errors.first('name')}</span>
                                        )}

                                    </label>

                                    {this.state.errors.has('general') && (
                                        <span className={styles.error}>{this.state.errors.first('general')}</span>
                                    )}
                                </form>
                            </div>
                            {loading ? (
                                <Loader
                                    color="#ffffff"
                                />
                            ) : (
                                <Slider
                                    className={classnames(styles.sliderContainer, 'mgmSlider', isPWA ? 'isPWA' : '')}
                                    {...settings}
                                    afterChange={this.handleCurrentSlide}
                                >
                                    {cardsData &&
                                    cardsData.map((card) => (
                                        <Card
                                            friendName={this.state.name}
                                            key={card.id}
                                            photo={card.image}
                                            message={card.content}
                                            greetings={card.greetings}
                                            slogan = {card.slogan}
                                            userName={userName.first_name}
                                        />
                                    ))}
                                </Slider>
                            )}
                            <div className={styles.submit}>
                                <Button type="darkWithBlue" onClick = {() => this.handleSubmit()} className = {styles.mobileSubmitButton}>Alege</Button>

                            </div>

                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.referral.loading,
    cardsData: state.referral.cardsData,
    cardData: state.referral.cardData,
    success: state.referral.success
});

const mapDispatchToProps = {
    getCards: () => ReferralAction.referralCards(),
    submit: (params) => ReferralAction.referralSubmitCards(params)
};

export default connect(mapStateToProps, mapDispatchToProps)(SendCard);
