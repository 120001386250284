import React from 'react';
import store from 'store';
import config from '../../config';
import styles from './Button.module.scss';

import classnames from 'classnames';
import { withRouter } from 'react-router';
import validURL from '../../utils/validURL';

const Button = ({ children, className, disabled, history, href, onClick, type, hover, id, style }) => {
	const isPWA = store.get(config.user.isPWA);
	const _onClick = (e) => {
		e.preventDefault();

		if (onClick) onClick();
		if (href) validURL(href) ? (window.location = href) : history.push(href);
	};

	return (
		<a href={href}
		   onClick={(e) => _onClick(e)}
		>
			<button
				id={id}
				type="button"
				style={style}
				className={
					classnames(
						styles.button,
						type && styles[type],
						hover && styles[hover],
						isPWA && styles.pwa,
						className
					)
				}
				disabled={disabled}
			>
				{children}
			</button>
		</a>
	);
};

export default withRouter(Button);
