import React from 'react';
import store from 'store';
import classnames from 'classnames';
import config from '../../../config';

// Assets
import styles from './style.module.scss';

// Components
import Layout from '../../../components/Layout';

class Regulation extends React.Component {
	state = {
		isPWA: store.get(config.user.isPWA)
	};

	componentDidMount() {
		window.jQuery('#acc_regulament').bwlAccordion({
			placeholder: 'Caută după cuvinte cheie',
			highlight_bg: '#d6a76f',
			highlight_color: '#fff',
			msg_item_found: ' Rezultat(e)',
			msg_no_result: '0 Rezultate'
		});
	}

	render() {
		const { isPWA } = this.state;

		return (
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>
						Condiții generale ale platformei <br />
						promoționale online "IQOS CLUB"
					</h1>
				</div>

				<div className={styles.container}>
					<div className="bwl_acc_container" id="acc_regulament">
						<div className="accordion_search_container">
							<input
								type="text"
								className="accordion_search_input_box search_icon"
								defaultValue=""
								placeholder="Caută după cuvinte cheie"
							/>
						</div>

						<div className="search_result_container" />

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 1. ORGANIZATORUL</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Platforma online <b>„IQOS Club”</b> (numită în cele ce urmează „Campania”), o
										inițiativă cu caracter promoțional, este deţinută şi se află sub gestiunea
										Philip Morris Trading S.R.L (numită în prezentul document “Organizatorul”), cu
										sediul în Otopeni, Str. Horia, Cloşca şi Crişan nr. 83-105, clădirea A, Judeţul
										Ilfov, România, înmatriculată la Registrul Comerţului sub numărul J23/511/2004,
										având CIF RO 4888165. Campania este accesibilă publicului larg la adresa web{' '}
										<a href="https://iqos.ro/club">iqos.ro/club</a>. Participanţii la această
										Campanie sunt obligaţi să respecte termenii şi condiţiile de participare,
										potrivit celor menţionate mai jos (denumit în continuare “Regulament Oficial”).
										În cadrul Campaniei se desfășoară o serie de activități promotionale diferite,
										dar conexe, denumite „Promoții Curente” ale căror detalii de implementare sunt
										comunicate în cadrul platformei online, atât prin comunicări publicitare
										specifice (de tip materiale vizuale online, e-mailuri etc) cât și prin secțiunea
										„Promoții Curente” a acestor Condiții Generale. Organizatorul își rezervă
										dreptul să adauge și/sau să retragă diverse promoții în cadrul Campaniei, urmând
										a aduce la zi secțiunea Promoții Curente a prezentului regulament Oficial, și
										informând participanții la Campanie cu privire la debutul/încetarea
										valabilității anumitor promoții prin e-mail și/sau direct prin intermediul
										platformei online, la adresa web <a href="https://iqos.ro/club">iqos.ro/club</a>
										.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 2. CONDIŢII DE PARTICIPARE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										La această Campanie pot participa cetăţenii români, cu vârsta peste 18 ani
										(împliniţi până la data înscrierii lor în cadrul prezentei Campanii),
										utilizatori de IQOS, cu cont activ pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a>
										şi care au înregistrat în contul lor minim un dispozitiv IQOS compus din Holder
										(„Stilou”) şi încărcător portabil şi care au domiciliul sau reşedinţa sau sunt
										rezidenţi în România.
									</p>

									<p>
										Din dorinţa de a nu prejudicia niciun participant care doreşte să participe la
										prezenta Campanie, Organizatorul îşi rezervă dreptul să poată efectua verificări
										în ceea ce priveşte corecta participare.
									</p>

									<p>
										O persoană poate deține un singur cont individual pe platforma online{' '}
										<a href="https://iqos.ro/club">iqos.ro/club</a>. Orice încercare de duplicare a
										unui cont sau de înregistrare a unei persoane cu date modificate/fictive/cu alte
										conturi (același nume, prenume, adresă de livrare, număr de telefon), va atrage
										după sine ștergerea conturilor deschise ulterior contului inițial, iar în caz de
										repetare a acțiunii, se va putea trece inclusiv la dezactivarea contului inițial
										de utilizator.
									</p>

									<p>
										În cazul în care, în urma verificărilor desfăşurate de către Organizator,
										rezultă că procesul de participare a fost fraudat, Organizatorul îşi rezervă
										dreptul de a retrage participanţilor premiile obţinute ca rezultat al
										activităţii lor şi / sau să restricţioneze participarea la Campanie.{' '}
									</p>

									<p>
										În cazul în care sunt identificate persoane care au influenţat sau au facilitat
										câştigarea de premii necuvenite, Organizatorul are dreptul de a cere urmărirea
										în instanţă a respectivelor persoane, pe baza dovezilor existente.
									</p>

									<p>
										Organizatorul îşi rezervă dreptul de a exclude participanţi din Campanie cu sau
										fără indicarea motivului. Acest lucru se aplică mai ales participanţilor care
										îşi manipulează sau încearcă să îşi manipuleze participarea (în principal prin
										crearea de conturi multiple sau încercând să obţină sau să creeze şanse
										suplimentare de a câştiga în mod incorect) sau care încalcă condiţiile specifice
										promoţiei sau care, într-un mod sau altul, utilizează abuziv promoţiile propuse.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 3. ÎNSCRIEREA ÎN CAMPANIE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Participarea la prezenta Campanie se face prin intermediul website-ului dedicat.
										Pentru a participa la prezenta Campanie, Participanţii trebuie să îndeplinească
										cumulativ următoarele condiţii: să deţină cont activ pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a> şi să aibă înregistrat în contul lui
										pe website-ul www.iqos.ro minim un dispozitiv IQOS compus din Holder („Stilou”)
										şi încărcător portabil.
										<br />
										Utilizatorul va putea accesa şi se va putea autentifica în cadrul website-ului
										dedicat prezentei Campanii promoţionale utilizând userul şi parola stabilite
										anterior la crearea şi activarea contului său pe website-ul
										<a href="https://iqos.ro">www.iqos.ro</a>.
										<br />
										Un utilizator care nu deţine un cont activ pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a>
										nu se va putea autentifica în cadrul website-ului destinat prezentei campanii şi
										nici nu îşi va putea crea cont în cadrul platformei online dedicate acestei
										Campanii.
										<br />
										Un utilizator care deţine un cont activ pe website-ul www.iqos.ro dar nu are
										înregistrat în contul lui pe website-ul{' '}
										<a href="https://iqos.ro">www.iqos.ro</a> minim un dispozitiv IQOS compus din
										Holder („Stilou”) şi încărcător portabil se va putea autentifica în cadrul
										website-ului destinat prezentei campanii promoţionale dar va avea acces limitat
										la conţinut şi nu va putea participa activ la prezenta Campanie.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">SECŢIUNEA 4. PROMOȚII CURENTE</h2>
							<div className="acc_container">
								<div className="block">
									<h3 className="acc_title_bar">
										<a href="#">Categoria promoții: Acumulare de puncte</a>
									</h3>
									<div className="acc_container">
										<div className="block">
											<h4 className="acc_title_bar">
												Promoţia: Utilizatori în perioada de testare IQOS
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>La această promoţie participă cetăţenii români, cu vârsta peste 18 ani (împliniţi până la data înscrierii lor în cadrul prezentei Campanii), care au domiciliul sau reşedinţa sau sunt rezidenţi în România, care au primit cu titlu de „împrumut” dispozitivul IQOS şi care se află în perioada de testare, conform termenilor şi condiţiilor promoţiei „Încearcă IQOS” derulată prin intermediul reprezentanţilor Organizatorului.</p>
													<p>În cadrul interacţiunii cu reprezentantul Organizatorului, Participantul va primi link-ul de acces în cadrul unei secţiuni special dedicate utilizatorului aparţinând platformei online IQOS Club.</p>
													<p>În calitate de utilizator aflat în perioada de testare, Participantul va putea accesa doar secţiunile din cadrul platformei online IQOS Club la care va avea drept de vizualizare, restul secţiunilor fiind restricţionate.</p>
													<p>Un Participant aflat în perioada de testare poate accesa promoţiile disponibile în cadrul platformei online IQOS Club astfel:</p>
													<p>A. Categoria promoţii: Acumulare puncte, Promoţia: Înscrierea de recomandări</p>
													<p>B. Categoria promoţii: Valorificare puncte</p>
													<ul>
														<li>Promoţia: Revendicare pachete HEETS din magazine </li>
														<li>Promoţia: Anulare coduri revendicare HEETS</li>
													</ul>
													<p>
														Un Participant aflat în perioada de testare IQOS poate transforma punctele dintre cele acumulate în contul său în beneficii de tip pachete HEETS™ în limita <b>a maxim 10 (zece) pachete de HEETS</b>, indiferent de numărul de puncte acumulate de acesta. În situaţia în care Participantul acumulează în contul său un număr mai mare de puncte, atunci diferenţa de puncte acumulate şi neutilizate încă va rămâne în contul asociat participantului până la expirarea acestora, conform promoţiei „Promoţia: Valabilitate puncte”.
													</p>
													<p>În situaţia în care Participantul restituie dispozitivul primit împrumut la finalul perioadei de Împrumut sau anterior acesteia şi nu achiziţionează acelaşi sau alt dispozitiv IQOS, atunci accestul în cadrul platformei online IQOS Club va fi înrerupt. Punctele acumulate de Participant rămân în contul asociat participantului până la expirarea acestora, conform promoţiei „Promoţia: Valabilitate puncte”.</p>
													<p>În situaţia în care Participantul achiziţionează un dispozitiv IQOS, în timpul sau la finalul perioadei de testare sau ulterior finalizării perioadei de testare, atunci acesta va putea accesa din nou platforma online IQOS Club, cu toate drepturile Participanţilor conform condiţiilor de participare şi a regulilor de înscriere. Punctele acumulate de acesta în perioada de testare IQOS, neutilizate şi aflate încă în perioada de valabilitate vor fi disponibile în contul Participantului la momentul accesării.</p>
												</div>
											</div>

											<h4 className="acc_title_bar">Promoţia: Înscrierea de recomandări</h4>
											<div className="acc_container">
												<div className="block">
													<p>Participanţii pot recomanda persoane fumătoare adulte care şi-au exprimat interesul legat de sistemul IQOS prin următoarele metode:</p>
													<ul className="dashLine">
														<li>Prin intermediul website-ului prezentei Campanii Promoţionale, &icirc;n cadrul secţiunii speciale dedicate &icirc;nscrierii de recomandări;</li>
														<li>Prin intermediul aplicaţiei digitale iSPOT.</li>
													</ul>
													<p>Persoanele fumătoare adulte pe care urmează să le recomande trebuie recrutate de Participanţi doar prin interacţiune directă, faţă &icirc;n faţă cu aceasta. Se interzice Participanţilor utilizarea brandului IQOS &icirc;n campanii de publicitate, recrutări prin mijloace electronice, cum ar fi, dar fără a se limita la, comunicări pe e-mail, comunicări SMS utiliz&acirc;nd baze de date din orice surse, realizarea de website-uri &icirc;n scop de promovare şi recrutare potenţiali cumpărători, promovarea pe paginile de socializare şi orice altă acţiune care poate fi considerată acţiune de publicitate prin mijloace electronice. &Icirc;n cazul &icirc;n care, &icirc;n urma verificărilor desfăşurate de către Organizator, rezultă că procesul de participare a fost fraudat, Organizatorul &icirc;şi rezervă dreptul de a retrage participanţilor punctele acumulate / premiile obţinute ca rezultat al activităţii lor şi / sau să restricţioneze participarea la Campania promoţională p&acirc;nă la &icirc;ncheierea acesteia.</p>
													<p>Pentru a &icirc;nscrie o recomandare prin oricare din modalităţile descrise anterior &icirc;n cadrul prezentei secţiuni, Participantul va completa un formular cu următoarele date personale ale persoanei fumătoare adulte recomandate: nume, prenume, judeţ, localitate, număr telefon mobil. Totodată va confirma &icirc;n cadrul formularului de recomandare faptul că persoana recomandată este adultă şi fumătoare şi este de acord cu termenii şi condiţiile recomandării.</p>
													<p>Pentru ca o recomandare să fie &icirc;nscrisă cu succes &icirc;n cadrul platformei online trebuie să &icirc;ntrunească cumulativ următoarele criterii:</p>
													<ul className="dashLine">
														<li>Datele introduse să fie corecte şi complete;</li>
														<li>Numărul de telefon mobil să fie valid;</li>
														<li>Persoana recomandată, identificată după numărul de telefon, nu a fost &icirc;nscrisă / recomandată anterior, prin acelaşi sau orice alt canal de adaugare recomandări, cum ar fi dar fără a se limita la iSPOT, iQOACH;</li>
														<li>Persoana recomandată nu trebuie să deţină cont activ &icirc;n cadrul platformei <a href="http://www.iqos.ro">iqos.ro</a> cu dispozitiv &icirc;nscris / asociat contului;</li>
														<li>Persoana recomandată să nu fi achiziţionat anterior, prin intermediul reprezentanţilor de v&acirc;nzare ai Organizatorului sau prin altă modalitate, un alt sistem IQOS, conform ofertelor comerciale active sau anterioare.</li>
													</ul>
													<p>După completarea cu succes a formularului de recomandare, persoana recomandată va primi, prin comunicare SMS la numărul de telefon mobil completat &icirc;n formularul de recomandare, informaţii referitoare la persoana care l-a recomandat, precum şi faptul că va fi contactat de un reprezentant al Organizatorului &icirc;n interval de maxim 48 de ore. Organizatorul nu &icirc;şi asumă răspunderea pentru neprimirea sau primirea &icirc;nt&acirc;rziată a SMS-urilor datorită unor cauze care nu depind direct de el, cum ar fi, dar nelimit&acirc;ndu-se la, defecţiuni tehnice ale operatorilor de telefonie mobilă.</p>
													<p>Toate persoanele recomandate &icirc;nscrise de Participant prin oricare din modalităţile descrise anterior &icirc;n cadrul prezentei secţiuni vor fi contactate de un reprezentant al Organizatorului &icirc;n vederea prezentării detaliilor despre sistemul IQOS.</p>
													<p>Recomandările care se finalizează cu achiziţionarea de către persoana recomandată a unui sistem IQOS (dispozitiv IQOS şi un cartuş HEETS&trade; sau dispozitiv IQOS şi 3 pachete HEETS&trade; sau orice altă combinaţie de dispozitiv IQOS şi pachete HEETS&trade; disponibilă conform ofertei comerciale active la momentul achiziţionării sistemului iQOS) sunt numite &icirc;n continuare &bdquo;recomandări finalizate cu v&acirc;nzare" şi doar acestea vor fi contorizate &icirc;n vederea acumulării de puncte.</p>
													<p>Prin "Ofertă" se &icirc;nţelege oferta de achiziţie cu sau fără testare prealabilă de produs, prezentată de agentul de v&acirc;nzări al Fieldstar S.R.L. (&bdquo;V&acirc;nzătorul") Cumpărătorului, care se poate referi la produsul IQOS, rezervele de tutun HEETS&trade; sau la orice combinaţie &icirc;ntre ele, inclusiv orice pachet promoţional (IQOS si HEETS&trade;). Ofertele pot fi construite &icirc;n considerarea unicităţii celui ce le primeşte, prin prisma faptului că dau dreptul achizitorului la facilitaăţi economice sau de altă natură. Orice &icirc;ncercare de accesare a Ofertei de către aceeaşi persoana &icirc;n moduri care ar urmări fraudarea condiţiilor ofertei sau inducerea &icirc;n eroare a V&acirc;nzătorului cu privire la identitatea achizitorului Ofertei (cum ar fi, dar nelimit&acirc;ndu-se la creearea de identităţi diferite pentru una şi aceeaşi persoană fizică, duplicarea unui cont sau de &icirc;nregistrare a unei persoane cu date modificate/fictive/cu alte conturi &ndash; de exemplu același nume, prenume, adresă de livrare/facturare, număr de telefon), va atrage după sine anularea Ofertei, iar &icirc;n caz de repetare a acțiunii, se va putea trece inclusiv la informarea organelor de urmărire penala competente. Suplimentar, orice tentativă de fraudă detectată &icirc;n cadrul și &icirc;n legătură cu promoții active organizate de Organizator &icirc;mpreună cu Fieldstar S.R.L. va duce la posibilitatea ca utilizatorului care a comis respectiva fraudă sau &icirc;ncercare de fraudă să i se poată refuza reducerea/premiul/gratuitatea.</p>
													<p>Pentru ca o recomandare finalizată cu v&acirc;nzare să fie considerată validă, achiziţionarea de către persoana recomandată a unui sistem IQOS trebuie să fie realizată doar de la reprezentantul IQOS de care a fost contactat &icirc;n termenul descris mai sus din momentul primirii SMS-ului şi doar ca urmare a respectării paşilor descrişi prin SMS. Nu vor fi considerate recomandări finalizate cu v&acirc;nzare valide şi nu vor fi contorizate &icirc;n contul participantului care a &icirc;nregistrat recomandarea acele achiziţionări de sisteme IQOS din alte surse dec&acirc;t cea menţionată anterior &icirc;n cadrul prezentului paragraf, cum ar fi, dar fără a se limita la, direct de pe website-ul <a href="http://www.iqos.ro">www.iqos.ro</a> sau din magazinele partenere sau ca urmare a interacţiunii cumpărătorilor cu un reprezentant IQOS, alta dec&acirc;t prin &icirc;ntalnirea cu reprezentantul IQOS de care a fost contactat &icirc;n termenul descris mai sus din momentul primirii SMS-ului şi alta ca urmare a recomandării făcute de Participantul &icirc;nscris &icirc;n prezenta Campanie Promoţională.</p>
													<p>Pentru ca o recomandare finalizată cu v&acirc;nzare să fie considerată validă, persoana recomandată trebuie să acceseze, cu respectarea paşilor descrişi anteior, dintre ofertele disponibile de tipul &bdquo;Starter Kit&rdquo;. Nu vor fi considerate recomandări finalizate cu v&acirc;nzare valide şi nu vor fi contorizate &icirc;n contul participantului care a &icirc;nregistrat recomandarea acele achiziţii de sisteme IQOS din alte oferte, cum ar fi dar fără a se limita la, oferte de tip &bdquo;Loyalty Kit&rdquo;, conform ofertelor comerciale active ale V&acirc;nzătorului.</p>
													<p>Participantul va acumula &icirc;n contul său 60 puncte pentru orice recomandare finalizată cu v&acirc;nzare.</p>
													<p>&Icirc;n plus, pentru recomandările &icirc;nregistrate &icirc;ncep&acirc;nd cu data de 15 aprilie 2021, Participantul va acumula &icirc;n contul său <strong>1 Moment</strong> dacă sunt &icirc;ntrunite cumulativ următoarele condiţii, astfel:</p>
													<ul className="dashLine">
														<li>Persoana recomandată, care a finalizat cu v&acirc;nzare recomandarea &icirc;nregistrată de participant, s-a &icirc;nregistrat cu succes şi şi-a activat contul &icirc;n cadrul platformei IQOS Club;</li>
														<li>Ulterior lunii calendaristice &icirc;n care s-a &icirc;nregistrat recomandarea se defineşte ca fiind lună &bdquo;buffer&rdquo; luna calendaristică imediat următoare celei &icirc;n care a fost &icirc;nregistrată recomandarea de către participant;</li>
														<li>Activarea contului &icirc;n cadrul platformei IQOS Club de către persoana recomandată s-a realizat p&acirc;nă cel t&acirc;rziu &icirc;n data de 15 a lunii următoare celei de buffer, aşa cum aceasta a fost definită anterior.</li>
													</ul>
													<p>Momentul se adaugă &icirc;n contul Participantului &icirc;n mod automat, ulterior identificării şi validării criteriilor de eligibilitate menţionate anterior.</p>
													<p>Exemple:</p>
													<p>Pentru recomandările &icirc;nregistrate &icirc;n perioada 15 aprilie 2021 &ndash; 15 mai 2021, persoana recomandată a finalizat cu v&acirc;nzare recomandarea &icirc;nregistrată de participant, s-a &icirc;nregistrat cu succes şi şi-a activat contul &icirc;n cadrul platformei IQOS Club p&acirc;nă cel t&acirc;rziu la data de 15 iunie 2021;</p>
													<p>Pentru recomandările &icirc;nregistrate &icirc;n perioada 16 mai 2021 &ndash; 31 mai 2021, persoana recomandată a finalizat cu v&acirc;nzare recomandarea &icirc;nregistrată de participant, s-a &icirc;nregistrat cu succes şi şi-a activat contul &icirc;n cadrul platformei IQOS Club p&acirc;nă cel t&acirc;rziu la data de 15 iulie 2021;</p>
													<p>Pentru recomandările &icirc;nregistrate &icirc;n perioada 15 iulie 2021 &ndash; 31 iulie 2021, persoana recomandată a finalizat cu v&acirc;nzare recomandarea &icirc;nregistrată de participant, s-a &icirc;nregistrat cu succes şi şi-a activat contul &icirc;n cadrul platformei IQOS Club p&acirc;nă cel t&acirc;rziu la data de 15 septembrie 2021;</p>
													<p>Pentru recomandările &icirc;nregistrate &icirc;n perioada 01 august 2021 &ndash; 31 august 2021, persoana recomandată a finalizat cu v&acirc;nzare recomandarea &icirc;nregistrată de participant, s-a &icirc;nregistrat cu succes şi şi-a activat contul &icirc;n cadrul platformei IQOS Club p&acirc;nă cel t&acirc;rziu la data de 15 octombrie 2021.</p>
													<p>Organizatorul &icirc;și rezervă dreptul de a modifica prezenții termeni pe parcursul desfășurării Campaniei Promoționale, precum și dreptul de a suspenda și/sau &icirc;nceta și/sau &icirc;ntrerupe și/sau prelungi desfășurarea Campaniei, urmand ca astfel de modificări să intre &icirc;n vigoare &icirc;n momentul comunicării lor către participanți prin afișare pe website-ul <a href="http://www.iqos.ro/club">www.iqos.ro/club</a>, &icirc;n cadrul secțiunii dedicate acestei Campanii.</p>
													<p>Termenii prezentei Campanii Promoționale se completează cu Regulamentul IQOS Club, disponibil <a href="https://www.iqos.ro/club/regulament">aici</a>.</p>
												</div>
											</div>

										</div>
									</div>


									<h3 className="acc_title_bar">
										<a href="#">Categoria promoții: Valorificare puncte</a>
									</h3>
									<div className="acc_container">
										<div className="block">

											<h4 className="acc_title_bar">
												Promoţia: Revendicare pachete HEETS din magazine
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Un Participant, identificat după numărul de telefon cu care s-a
														înscris în Campanie, participant la prezenta Campanie, ca urmare
														a acumulării de puncte poate transforma total sau parţial
														punctele acumulate în contul său în beneficii de tip pachete
														HEETS™, la alegerea participantului, în limita numărului de
														puncte acumulate în contul său, dar nu mai mult de 749 puncte
														folosite pe un singur cod de validare.
													</p>
													<p>Punctele acumulate de către utilizatori în cadrul platformei online IQOS Club au rolul de a debloca beneficiile aferente nivelurilor superioare prin acumularea unui număr minim de puncte, așa cum este specificat în prezentul regulament la Secțiunea 4. Promoții Curente -> Categoria Promoții: Beneficii -> Promoția: Beneficii garantate.</p>
													<p>Punctele acumulate de către utilizatori în cadrul platformei IQOS Club pot fi folosite, de asemenea, pentru obținerea de gratuități sau reduceri la produsele din portofoliul Philip Morris Trading sau ale partenerilor acestuia, valoarea reducerii fiind stabilită de către utilizator prin folosirea unui număr de puncte, mai mică sau egală cu numărul de puncte disponibile în contul Participantului. Echivalentul in RON a reducerii survenite ca urmare a folosirii unui punct în oricare din canalele disponibile și prezentate în cadrul platformei online IQOS Club este cuprins în intervalul: 1 punct = între <b>0.7 RON si 1 RON</b>, în funcție de canalul folosit pentru utilizarea punctelor și, respectiv, a categoriei de produse pentru care utilizatorul dorește a le folosi.</p>
													<p>
														În momentul în care un Participant va transforma puncte
														acumulate în contul său în recompense de tip pachete HEETS™,
														Participanţii vor fi informaţi cu privire la beneficiul
														revendicat printr-un mesaj SMS, în care îi vor fi comunicate:{' '}
													</p>
													<ul className="dashLine">
														<li>
															numărul de pachete HEETS pentru care a transformat punctele,
														</li>
														<li>
															un cod de validare unic alfanumeric format din 7 caractere -
															necesar pentru revendicarea beneficiului
														</li>
														<li>modalitatea de intrare în posesia beneficiului.</li>
													</ul>
													<p>
														Organizatorul nu îşi asumă răspunderea pentru neprimirea sau
														primirea întârziată a SMS-urilor datorită unor cauze care nu
														depind direct de el, cum ar fi, dar nelimitându-se la,
														defecţiuni tehnice ale operatorilor de telefonie mobilă. De
														asemenea, codul de validare premiu va fi afişat şi în contul
														Participantului din cadrul aplicaţiei digitale iSPOT, precum şi
														în cadrul website-ului destinat prezentei Campanii Promoţionale.{' '}
													</p>
													<p>
														Un cod de revendicare generat în contul Participantului şi
														neutilizat de acesta are termen de valabilitate după cum
														urmează:
													</p>

													<ul className="dashLine">
														<li>
															Codurile de revendicare generate în perioada 01 ianuarie ora
															00:00:00 – 30 iunie ora 23:59:59 a unui an calendaristic şi
															neutilizate pâna la 31 decembrie ora 23:59:59, acelaşi an
															calendaristic, îşi vor pierde valabilitatea la această dată
															şi vor fi eliminate automat din contul participantului;
														</li>
														<li>
															Codurile de revendicare generate în perioada 01 iulie ora
															00:00:00 – 31 decembrie ora 23:59:59 a unui an calendaristic
															şi neutilizate până la data de 30 iunie ora 23:59:59 a
															anului calendaristic următor, îşi vor pierde valabilitatea
															la această dată şi vor fi eliminate automat din contul
															participantului.
														</li>
													</ul>

													<p>
														În cazul în care codul de validare a expirat, deşi Participantul
														nu a revendicat beneficiul, atunci beneficiul nu se mai acordă,
														iar punctele nu vor fi restituite în contul Participantului.
													</p>

													<p>
														Participantul poate revendica beneficiiul de tip pachet HEETS™
														din centrele de premiere desemnate de Organizator din reţelele
														de benzinării MOL şi Rompetrol, magazine din reţeaua Lagardere
														(Inmedio, 1 Minute, Relay), Tabac Xpress, precum şi din magazine
														IQOS, numite în continuare centre de premiere, pe baza codului
														de validare premiu format din 7 caractere primit prin comunicare
														SMS din partea Organizatorului, disponibil şi în contul
														participantului din cadrul aplicaţiei iSPOT şi/sau în contul
														participanţilor din cadrul website-ului destinat prezentei
														Campanii.{' '}
													</p>
													<p>
														Lista cu centrele de premiere va fi disponibilă participanţilor
														pe website-ul dedicat platformei IQOS Club.
													</p>
													<p>
														În faza recepţionării beneficiului, Participantul trebuie să se
														prezinte personal în faţa reprezentantului centrului de premiere
														şi să prezinte codul de validare unic alfanumeric format din 7
														(şapte) caractere din cadrul SMS-ul primit din partea
														Organizatorului sau disponibil în contul său din cadrul
														aplicaţiei iSPOT / website-ului destinat prezentei Campanii.
													</p>
													<p>
														Aceste informaţii sunt necesare validării premiului câştigat şi
														intrării în posesia lui.{' '}
													</p>
													<p>
														La revendicarea beneficiului dintr-unul din centrele de
														premiere, un Participant poate opta pentru una sau oricare
														conbinaţie din următoarele variante de Pachet HEETS™:{' '}
														<b>
															Amber Flavor, Yellow Flavor, Turquoise Flavor, Blue Flavor,
															Bronze Flavor, Sienna Flavor, Sienna Caps sau Russet
														</b>
														, în limita stocului disponibil existent în locaţia aleasă de
														câştigător pentru revendicarea premiului.
													</p>
													<p>
														Reprezentantul centrului de premiere nu are obligaţia de a oferi
														informaţii în legătură cu prezenta Campanie. Pentru detalii în
														acest sens, participanţii trebuie să apeleze Centrul de
														asistenţă clienţi.
													</p>
													<p>
														Participanţii care au transformat punctele în beneficii de tip
														Pachet HEETS™ nu pot cere modificarea naturii, cantităţii,
														condiţiilor şi modului de acordare al acestora. În situaţia în
														care Participantul refuză să accepte beneficiul câştigat sau nu
														poate beneficia de acesta din motive independente de
														Organizator, respectivul beneficiu nu va fi înlocuit cu un alt
														premiu sau beneficiu.
													</p>
												</div>
											</div>

											<h4 className="acc_title_bar">
												Promoţia: Anulare coduri revendicare HEETS
											</h4>
											<div className="acc_container">
												<div className="block">
													<p>
														Promoţia se desfăşoară în cadrul platformei online IQOS Club
														începând cu data de <b>2 iunie 2020 ora 00:00:00</b>; Promoţia
														poate înceta ca urmare a unei decizii a Organizatorului, cu
														respectarea condiţiilor generale ale platformei online „IQOS
														Club”.
													</p>
													<p>
														La prezenta promoţie pot participa toţi utilizatorii cu cont
														activ în cadrul platformei online IQOS Club, care întunesc
														cumulativ condiţiile de mai jos:
													</p>
													<ul>
														<li>
															deţin în contul lor cel puţin 1 (un) cod de revendicare
															obţinut de Participant ca urmarea a participării acestuia la
															Promoţia „<u>Revendicare pachete HEETS din magazine</u>”,
															conform termenilor şi condiţiilor respectivei Promoţii;
														</li>
														<li>
															codul de revendicare deţinut de Participant este activ,
															neutilizat încă şi aflat în termen de valabilitate.
														</li>
													</ul>
													<p>
														Participanţii care accesează platforma online IQOS Club pot
														accesa secţiunea „Foloseşte puncte”, subsecţiunea „Revendică
														HEETS din magazine” unde pot vizualiza dacă deţin în contul lor
														coduri de revendicare HEETS generate, neutilizate încă şi aflate
														în termenul de valabilitate, conform regulilor promoţiei „
														<u>Revendicare pachete HEETS din magazine</u>”.{' '}
													</p>
													<p>
														Pe perioada de desfăşurare a prezentei Promoţii, Participantul
														poate opta pentru anularea codului de revendicare generat
														anterior, neutilizat încă şi aflat în termen de valabilitate.
														Pentru aceasta, participantul va putea iniţia solicitarea prin
														accesarea opțiunii „Anulează cod” din dreptul fiecărui cod
														generat. Această opțiune va fi afișată doar în dreptul codurilor
														care întunesc cumulativ condiţiile de mai sus.
													</p>
													<p>
														În urma transmiterii cu succes a solicitării Participantului,
														codul eligibil asociat va fi anulat, iar punctele utilizate
														pentru generarea codului respectiv vor fi returnate în contul
														Participantului din cadrul platformei online IQOS Club.
													</p>
													<p>
														Punctele returnate în contul Participantului nu sunt contorizate
														de Organizator în vederea avansării utilizatorului de la un
														nivel inferior către un nivel superior în cadrul platformei
														online IQOS Club, aşa cum sunt acestea descrise în cadrul
														regulamentului promoţiei „<u>Beneficii garantate</u>”.
													</p>
													<p>
														În situaţia în care utilizatorul solicită anularea unui cod de
														revendicare generat anterior intrării în vigoare a
														regulamentului prezentei Promoţii, atunci punctele returnate în
														contul lui se vor considera, din punct de vedere valabilitate,
														ca fiind acumulate la momentul anulării codului de revendicare
														şi returnării lor în contul Participantului, iar termenul lor de
														valabilitate al acestora se supune regulilor promoţiei
														„Valabilitate puncte”.
													</p>
													<p>
														În situaţia în care utilizatorul solicită anularea unui cod de
														revendicare generat ulterior intrării în vigoare a
														regulamentului prezentei Promoţii, atunci punctele care şi-ar fi
														pierdut valabilitatea în perioada de timp decursă între momentul
														generării codului de revendicare şi solicitarea de anulare a
														acestuia nu vor mai fi returnate în contul utilizatorului. Se
														vor returna în contul utilizatorului doar punctele aflate încă
														în termenul de valabilitate, iar termenul lor de valabilitate al
														acestora se supune regulilor promoţiei „Valabilitate puncte”,
														fiind luat în considerare momentul acumulării lor de către
														utilizator, anterior transformării lor în cod de revendicare.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 5. PROMOȚII ÎNCETATE</a>
							</h2>
							<div className="acc_container">
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 6. RĂSPUNDERE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Organizatorul şi societăţile implicate în organizarea prezentei Campanii nu îşi
										asumă răspunderea pentru imposibilitatea participării la Campanie datorită unor
										cauze care nu depind direct de ei.
									</p>
									<p>
										Prin înscrierea la această Campanie, Participanţii sunt de acord să respecte
										condiţiile de participare precum şi toate deciziile luate de Organizator şi
										colaboratorii săi în toate aspectele legate de implementarea prezentei campanii.
									</p>
									<p>
										Organizatorul nu-şi asumă răspunderea şi nu este responsabil pentru situaţiile
										în care un participant nu poate accesa platforma online dedicată Campaniei din
										motive care nu ţin de funcţionalitatea website-ului, de genul: conexiune la
										internet, programe instalate, versiuni de browser vechi, probleme cu reţeaua
										electrică, erori cauzate de întreruperea neaşteptată a serviciului de hosting al
										website-ului etc sau alte motive independente de Organizator.
									</p>
									<p>
										Organizatorul nu are nicio obligaţie de a întreţine corespondenţa cu
										solicitanţii unor revendicări ce apar ulterior termenelor stabilite în prezentul
										document sau al documentelor conexe.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 7. PROTECŢIA DATELOR PERSONALE</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Tuturor participanţilor la prezenta Campanie le sunt garantate drepturile cu
										privire la protecţia datelor cu caracter personal în conformitate cu
										Regulamentul (UE) 679/2016 privind protecţia persoanelor fizice în ceea ce
										priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a
										acestor date, în special drepturile persoanei vizate cu privire la informare şi
										acces la date, dreptul la rectificare, dreptul la ştergerea datelor, dreptul la
										restricţionarea prelucrării, dreptul la portabilitatea datelor, dreptul la
										opoziţie, de a se adresa instanţei competente şi de plângere către autoritatea
										de supraveghere.{' '}
									</p>
									<p>
										În înţelesul Regulamentului (UE) nr. 679/2016, principalele drepturi mai sus
										menţionate aparţinând persoanelor vizate au următorul conţinut:{' '}
									</p>

									<ol>
										<li>
											Dreptul de informare şi acces la date: Orice persoană vizată are dreptul de
											a obţine de la Operator, la cerere şi în mod gratuit pentru o solicitare pe
											an, confirmarea faptului că datele care o privesc sunt sau nu sunt
											prelucrate de către acesta. Astfel, la cererea scrisă, semnată şi datată a
											persoanei respective, adresată la CP84 OP83, Bucureşti sau prin email la
											contact@iqos.ro, o dată pe an, în mod gratuit, compania Philip Morris
											Trading S.R.L. va confirma dacă utilizează date care o privesc sau va înceta
											orice prelucrare a acestora. În caz afirmativ, va furniza persoanei vizate
											urmatoarele informaţii: a) scopurile prelucrării; b) categoriile de date cu
											caracter personal vizate; c) destinatarii sau categoriile de destinatari
											cărora datele cu caracter personal le-au fost sau urmează să le fie
											divulgate, în special destinatari din ţări terţe sau organizaţii
											internaţionale; d) acolo unde este posibil, perioada pentru care se
											preconizează că vor fi stocate datele cu caracter personal sau, dacă acest
											lucru nu este posibil, criteriile utilizate pentru a stabili această
											perioadă; e) existenţa dreptului de a solicita operatorului rectificarea sau
											ştergerea datelor cu caracter personal ori restricţionarea prelucrării
											datelor cu caracter personal referitoare la persoana vizată sau a dreptului
											de a se opune prelucrării; f) dreptul de a depune o plângere în faţa unei
											autorităţi de supraveghere; g) în cazul în care datele cu caracter personal
											nu sunt colectate de la persoana vizată, orice informaţii disponibile
											privind sursa acestora; h) existenţa unui proces decizional automatizat
											incluzând crearea de profiluri, menţionat la articolul 22 alineatele (1) şi
											(4), precum şi, cel puţin în cazurile respective, informaţii pertinente
											privind logica utilizată şi privind importanţa şi consecinţele preconizate
											ale unei astfel de prelucrări pentru persoana vizată.
										</li>
										<li>
											Dreptul la rectificare: Orice persoană vizată are dreptul de a obţine de la
											Operator, la cerere şi în mod gratuit, fără întârzieri nejustificate,
											rectificarea datelor cu caracter personal inexacte care o privesc.
											Ţinându-se seama de scopurile în care au fost prelucrate datele, persoana
											vizată are dreptul de a obţine completarea datelor cu caracter personal care
											sunt incomplete, inclusiv prin furnizarea unei declaraţii suplimentare.
										</li>

										<li>
											Dreptul la ştergerea datelor ("dreptul de a fi uitat"): Persoana vizată are
											dreptul de a obţine din partea operatorului ştergerea datelor cu caracter
											personal care o privesc, fără întârzieri nejustificate, iar operatorul are
											obligaţia de a şterge datele cu caracter personal fără întârzieri
											nejustificate în cazul în care se aplică unul dintre următoarele motive: a)
											datele cu caracter personal nu mai sunt necesare pentru îndeplinirea
											scopurilor pentru care au fost colectate sau prelucrate; b) persoana vizată
											îşi retrage consimţământul pe baza căruia are loc prelucrarea; c) persoana
											vizată se opune prelucrării şi nu există motive legitime care să prevaleze
											în ceea ce priveşte prelucrarea; d) datele cu caracter personal au fost
											prelucrate ilegal; e) datele cu caracter personal trebuie şterse pentru
											respectarea unei obligaţii legale care revine operatorului;
										</li>

										<li>
											Dreptul la restricţionarea prelucrării: Persoana vizată are dreptul de a
											obţine din partea operatorului restricţionarea prelucrării în cazul în care
											se aplică unul din următoarele cazuri: a) persoana vizată contestă
											exactitatea datelor, pentru o perioadă care îi permite operatorului să
											verifice exactitatea datelor; b) prelucrarea este ilegală, iar persoana
											vizată se opune ştergerii datelor cu caracter personal, solicitând în schimb
											restricţionarea utilizării lor; c) operatorul nu mai are nevoie de datele cu
											caracter personal în scopul prelucrării, dar persoana vizată i le solicită
											pentru constatarea, exercitarea sau apărarea unui drept în instanţă; sau d)
											persoana vizată s-a opus prelucrării, pentru intervalul de timp în care se
											verifică dacă drepturile legitime ale operatorului prevalează asupra celor
											ale persoanei vizate;
										</li>
										<li>
											Dreptul la portabilitatea datelor: Persoana vizată are dreptul de a primi
											datele cu caracter personal care o privesc şi pe care le-a furnizat
											operatorului într-un format structurat, utilizat în mod curent şi care poate
											fi citit automat şi are dreptul de a transmite aceste date altui operator,
											fără obstacole din partea operatorului căruia i-au fost furnizate datele cu
											caracter personal, în cazul în care: a) prelucrarea se bazează pe
											consimţământ în temeiul articolului 6 alineatul (1) litera (a) sau al
											articolului 9 alineatul (2) litera (a) sau pe un contract în temeiul
											articolului 6 alineatul (1) litera (b) din Regulamentul (UE) nr. 679/2006;
											şi b) prelucrarea este efectuată prin mijloace automate.
										</li>
										<li>
											Dreptul la opoziţie: Persoana vizată are dreptul de a se opune în orice
											moment, în mod gratuit şi fără nicio justificare, ca datele care o vizează
											să fie prelucrate în scop de marketing direct, în numele operatorului sau al
											unui terţ sau să fie dezvăluite unor terţi într-un asemenea scop
											(reprezentând alin.2 al art.15 din legea 677/2001).
										</li>
									</ol>

									<p>
										Următoarele date personale vor fi colectate pe durata Campaniei participanţilor
										care se înregistrează în Baza de date PM şi câştigătorilor de premii: nume,
										prenume, data de naştere, număr telefon mobil, e-mail, localitate, judet, sex.
										Scopurile constituirii bazei de date sunt: înmânarea premiilor câştigătorilor,
										înregistrarea câştigătorilor Campaniei, realizarea de rapoarte statistice cu
										privire la utilizatorii IQOS, informarea acestora prin diverse mijloace de
										comunicare (poştă, e-mail, SMS sau alte mijloace de comunicare permise prin
										lege), cu privire la alte acţiuni desfăşurate în viitor de către Organizator.
										Organizatorul se obligă că datele personale să nu fie difuzate către terţi cu
										excepţia împuterniciţilor săi. Prin înscrierea în Baza de date PM prin
										intermediul secţiunilor dedicate din cadrul website-urilor deţinute de Philip
										Morris Trading S.R.L, participanţii care au optat în acest sens îşi dau în mod
										expres consimţământul ca datele lor (respectiv, nume şi prenume, data naşterii,
										sex, telefon mobil, adresă de e-mail, localitate, judeţ) să fie incluse în baza
										de date de utilizatori IQOS / fumători adulţi a companiei Philip Morris Trading
										S.R.L. şi să primească ocazional obiecte promoţionale, precum şi informaţii prin
										poştă, telefon, e-mail, SMS sau alte mijloace de comunicare permise prin lege.
									</p>
									<p>
										Organizatorul va respecta dispoziţiile art.12 din Legea nr 506/2004 privind
										prelucrarea datelor cu caracter personal şi protecţia vieţii private în sectorul
										comunicaţiilor electronice, referitor la transmiterea de informaţii prin e-mail
										şi alte mijloace electronice.
									</p>
									<p>
										Formularele conţinând date cu caracter personal completate on-line, precum şi
										rezultatele oricăror interacţiuni finalizate de utilizatori, cum ar fi dar fără
										a se limita la răspunsurile date de aceştia la întrebările / chestionare
										disponibile în cadrul website-urilor deţinute de Philip Morris Trading S.R.L,
										devin proprietatea Philip Morris Trading S.R.L., cu toate drepturile aferente,
										inclusiv dreptul de a prelucra sau utiliza în scopuri de marketing direct
										informaţiile conţinute, fără alte obligaţii şi plăţi ulterioare faţă de persoana
										respectivă. Prin înscrierea la această Campanie, participanţii sunt de acord cu
										prevederile prezentului document şi al documentelor conexe şi cu prelucrarea
										datelor cu caracter personal şi sunt de acord ca, în cazul în care vor câştiga,
										numele, prenumele şi premiul câştigat să fie folosite în scopuri publicitare,
										fără alte obligaţii sau plăţi. La cererea scrisă, semnată şi datată a persoanei
										respective, adresată la CP84 OP83, Bucureşti sau prin email la contact@iqos.ro,
										o dată pe an, în mod gratuit, compania Philip Morris Trading S.R.L. va confirma
										utilizarea datelor care o privesc sau va înceta orice prelucrare a acestora. De
										asemenea, Philip Morris Trading S.R.L. va rectifica, actualiza, bloca, şterge
										sau transforma în date anonime pe toate acelea a căror folosire nu este conformă
										dispoziţiilor Regulamentului (UE) nr. 679/2016.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 8. CENTRUL DE ASISTENŢĂ CLIENŢI</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Pe perioada derulării prezentei Campanii, de luni până dumincă, orele
										08.00-22.00, persoanele interesate de detalii pot contacta centrul de asistenţă
										clienţi destinat IQOS la numărul 0800.030.333 (apel gratuit) pentru a afla mai
										multe informaţii în legătură cu prezenta Campanie. În afara programului
										menţionat mai sus, va exista un mesaj preînregistrat care va comunica
										apelanţilor programul de funcţionare a liniei telefonice.{' '}
									</p>
									<p>
										Organizatorul îşi rezervă dreptul de a nu lua în considerare solicitările
										primite la centrul de asistenţă clienţi de la apelanţi cu atitudine
										necorespunzătoare (cum ar fi, dar fără a se limita la aceste situaţii: limbaj
										agresiv, inadecvat, ton ridicat al vocii, ameninţări). În aceste cazuri vor fi
										luate în considerare spre analiză, doar situaţiile semnalate şi primite de către
										Organizator de la respectivii reclamanţi în scris, la CP84 OP83, Bucureşti până
										la data de 15 septembrie 2018 (inclusiv), data poştei.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 9. LITIGII</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										În cazul unor potenţiale litigii apărute între Organizator şi participanţii la
										prezenta Campanie, acestea vor fi soluţionate pe cale amiabilă. Dacă nu este
										posibilă rezolvarea litigiilor pe cale amiabilă, părţile implicate în litigiu se
										vor adresa pentru soluţionare instanţelor competente române în jurisdicţia
										cărora se află sediul Organizatorului. Orice reclamaţii sau sesizări referitoare
										la prezenta Campanie promoţională se primesc în scris de către Organizator prin
										email la adresa contact@iqos.ro sau prin Poştă la adresa CP84, OP83, Bucureşti,
										România.
									</p>
								</div>
							</div>
						</section>

						<section>
							<h2 className="acc_title_bar">
								<a href="#">SECŢIUNEA 10. ÎNCETAREA CAMPANIEI</a>
							</h2>
							<div className="acc_container">
								<div className="block">
									<p>
										Prezenta Campanie poate înceta ca urmare a unei decizii a Organizatorului sau în
										cazul apariţiei unui eveniment ce constituie forţă majoră, inclusiv în cazul
										imposibilităţii Organizatorului, din motive independente de voinţa sa, de a
										continua prezenta Campanie promoţională. Organizatorul își rezervă dreptul să
										adauge și/sau să retragă diverse promoții în cadrul Campaniei, urmând a aduce la
										zi secțiunea Promoții Curente a prezentului regulament Oficial, și informând
										participanții la Campanie cu privire la debutul/încetarea valabilității anumitor
										promoții prin e-mail și/sau direct prin intermediul platformei online, la adresa
										web „iqos.ro/club”. Încetarea uneia sau mai multor promoții din cadrul Campaniei
										nu atrage după sine încetarea propriu-zisă a Campaniei.
									</p>
								</div>
							</div>
						</section>
					</div>
				</div>
			</Layout>
		);
	}
}

export default Regulation;
