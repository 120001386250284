import React from 'react';
import store from "store";
import config from '../../../config';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';


class Logo extends React.Component {
    state = {
        isLoggedIn: store.get(config.user.token),
        exploreMode: store.get(config.user.exploreMode)
    };

    render() {
        var logoClass = classNames({
            logo: true,
            summerTime: true,
            noAuth: !this.props.isLoggedIn
        });

        return (
            <LinkContainer to={config.path.referral} className={logoClass}>
                <Nav.Link eventKey="">IQOS Club</Nav.Link>
            </LinkContainer>
        );
    }
}

export default Logo;
