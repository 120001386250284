import React from 'react';
import { Image } from 'react-bootstrap';
import styles from './LoginInput.module.scss';

class LoginInput extends React.Component {
    state = {
        focused: false,
        visiblePassword: false
    };

    getFormStyle = () => {
        const position = this.props.first
            ? styles.first
            : this.props.middle
            ? styles.middle
            : this.props.last
            ? styles.last
            : '';
        const isFocused = this.state.focused ? styles.activeFormContainer : '';
        return [styles.formContainer, position, isFocused].join(' ');
    };

    getSecondaryIconStyle = (isPassword) => {
        const visible = !this.state.visiblePassword && isPassword ? styles.secondaryIconInactive : '';
        return [styles.secondaryIcon, visible].join(' ');
    };

    toggleVisiblePassword = () => {
        const visible = this.state.visiblePassword;
        this.setState({
            visiblePassword: !visible
        });
    };

    render() {
        const { name, type, data, icon, secondaryIcon, label } = this.props;
        const isPassword = type === 'password';
        return (
            <div className={this.getFormStyle()}>
                <Image className={styles.icon} src={icon} />

                <div className={styles.field}>
                    <input
                        onChange={this.props.onChange}
                        className={styles.input}
                        type={isPassword && !this.state.visiblePassword ? 'password' : 'text'}
                        name={name}
                        data={data}
                        onFocus={() => this.setState({ focused: true })}
                        onBlur={() => this.setState({ focused: false })}
                        required
                    />
                    <label className={styles.label} htmlFor={name}>
                        {label}
                    </label>
                </div>

                <Image
                    className={this.getSecondaryIconStyle(isPassword)}
                    src={secondaryIcon}
                    onClick={isPassword ? this.toggleVisiblePassword : undefined}
                />
            </div>
        );
    }
}

export default LoginInput;
