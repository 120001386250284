import React from "react";
import store from "store";
import config from "../../../config";

// Assets
import styles from "./ListCodes.module.scss";
import Collapsible from "react-collapsible";
import Reveal from "react-reveal/Reveal";

class TableList extends React.Component{
	state = {};

	render() {
		const {codes, active} = this.props;

		return(
			<table className="autofit">
				<thead>
					<tr>
						<th scope="col">Coduri generate</th>
						<th scope="col">Cantitate</th>
						<th scope="col">Status</th>
					</tr>
				</thead>

				<tbody>
				{
					codes.map((item, key) => (
						<tr key={key}>
							<td data-label="Coduri generate">{item.code}</td>
							<td data-label="Cantitate">{item.quantity} x {item.quantity > 1 ? 'Pachete HEETS' : 'Pachet HEETS'}</td>
							<td data-label="Status">
								<div className={active ? 'is-success' : 'is-danger'}>{item.status}</div>
							</td>
						</tr>
					))
				}
				</tbody>
			</table>
		)
	}
}
class PWAList extends React.Component{
	state = {};

	render() {
		const {codes, active} = this.props;

		return(
			<ul className={styles.listPWA}>
				<Reveal effect="fadeInUp" cascade>
					<li>
						<div>Cod și cantitate</div>
						<div>Status</div>
					</li>

					{
						codes.map((item, key) => (
							<li key={key}>
								<div>
									<b>{item.code}</b>
									<span>{item.quantity} x {item.quantity > 1 ? 'Pachete HEETS' : 'Pachet HEETS'}</span>
								</div>
								<div>
									<span className={active ? styles.isSuccess : styles.isDanger}>{item.status}</span>
								</div>
							</li>
						))
					}
				</Reveal>
			</ul>
		)
	}
}

class ListCodes extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA),
		showUnclaimedCodes: true,
		showClaimedCodes: false
	};

	showUnclaimedCodes = () => { this.setState({showUnclaimedCodes: true}); };
	hideUnclaimedCodes = () => { this.setState({showUnclaimedCodes: false}); };

	showClaimedCodes = () => { this.setState({showClaimedCodes: true}); };
	hideClaimedCodes = () => { this.setState({showClaimedCodes: false}); };

	render() {
		const {isPWA, showClaimedCodes, showUnclaimedCodes} = this.state;
		const {userCodes} = this.props;

		if(isPWA){
			return(
				<Reveal effect="fadeInUp" cascade>
					<Collapsible trigger={showClaimedCodes ? 'Ascunde codurile nerevendicate' : 'Vezi codurile nerevendicate'}
								 open={showUnclaimedCodes}
								 className={styles.seeClaimedCodes}
								 triggerClassName={styles.collapsibleTrigger}
								 triggerOpenedClassName={styles.collapsibleTriggerOpen}
								 contentInnerClassName={styles.collapsibleInnerPWA}
								 contentOuterClassName={styles.collapsibleOuterPWA}
								 onOpening={this.showUnclaimedCodes}
								 onClosing={this.hideUnclaimedCodes}
					>
						<PWAList codes={userCodes.unclaimedCodes} valid={true} />
					</Collapsible>

					<Collapsible trigger={showClaimedCodes ? 'Ascunde codurile revendicate' : 'Vezi codurile revendicate'}
								 className={styles.seeClaimedCodes}
								 triggerClassName={styles.collapsibleTrigger}
								 triggerOpenedClassName={styles.collapsibleTriggerOpen}
								 contentInnerClassName={styles.collapsibleInnerPWA}
								 contentOuterClassName={styles.collapsibleOuterPWA}
								 onOpening={this.showClaimedCodes}
								 onClosing={this.hideClaimedCodes}
					>
						<PWAList codes={userCodes.claimedCodes} valid={false} />
					</Collapsible>
				</Reveal>
			)
		} else {
			return(
				<>
					<Collapsible trigger={showUnclaimedCodes ? 'Ascunde codurile nerevendicate' : 'Vezi codurile nerevendicate'}
								 open={showUnclaimedCodes}
								 className={styles.seeClaimedCodes}
								 triggerClassName={styles.collapsibleTrigger}
								 triggerOpenedClassName={styles.collapsibleTriggerOpen}
								 contentInnerClassName={styles.collapsibleInnerPWA}
								 contentOuterClassName={styles.collapsibleOuterPWA}
								 onOpening={this.showUnclaimedCodes}
								 onClosing={this.hideUnclaimedCodes}
					>
						<TableList codes={userCodes.unclaimedCodes} active={true} />
					</Collapsible>

					<Collapsible trigger={showClaimedCodes ? 'Ascunde codurile revendicate' : 'Vezi codurile revendicate'}
								 className={styles.seeClaimedCodes}
								 triggerClassName={styles.collapsibleTrigger}
								 triggerOpenedClassName={styles.collapsibleTriggerOpen}
								 contentInnerClassName={styles.collapsibleInner}
								 contentOuterClassName={styles.collapsibleOuter}
								 onOpening={this.showClaimedCodes}
								 onClosing={this.hideClaimedCodes}
					>
						<TableList codes={userCodes.claimedCodes} active={false} />
					</Collapsible>
				</>
			)
		}
	}
}

export default ListCodes;
