import React from 'react';
import { connect } from 'react-redux';
import LoginActions from '../../../stores/Login/Actions';

// Assets
import Header from '../../../components/Header';
import styles from './Register.module.scss';

// Components
import LoginInput from '../../../components/LoginForm';
import AuthButton from '../../../components/AuthButton';

import { LinkContainer } from 'react-router-bootstrap';
import config from '../../../config';
import { Image, Nav } from 'react-bootstrap';
import Logo from '../../../assets/images/ui/logo/logo_big.png';

class Register extends React.Component {
    state = {
        email: undefined,
        password: undefined
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value ? e.target.value : e.target.checked
        });
    };

    handleSubmit = () => {
        const { email, password } = this.state;

        this.props.submit(email, password);
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleError = (error) => {
        if (error) {
            const emailError = error.email ? error.email : undefined;
            const passwordError = error.password ? error.password : undefined;
            const generalError = error ? error : undefined;

            return emailError ? emailError : passwordError ? passwordError : generalError ? generalError : undefined;
        }
    };

    render() {
        return (
            <div className={styles.login}>
                <Header />

                <div className={styles.loginContainer}>
                    <div className={styles.closePp}></div>

                    <div className="center-align">
                        <div className="loginWrapper">
                            <Image src={Logo} className={styles.logo} /> <br />
                            <p>Completează formularul de mai jos și intră în comunitatea noastră.</p>
                            <br />
                            <form>
                                <LoginInput onChange={this.handleChange} name="Adresa de e-mail" type="email" first />

                                <AuthButton onClick={this.handleSubmit} loading={this.props.loading}>
                                    Creare cont
                                </AuthButton>

                                <br />

                                <LinkContainer to={config.path.login} className={styles.register}>
                                    <Nav.Link eventKey="">
                                        Ai deja cont? <br />
                                        <b>Click aici</b>
                                    </Nav.Link>
                                </LinkContainer>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.login.loading,
    error: state.login.errors
});

const mapDispatchToProps = {
    submit: (email, password) => LoginActions.login(email, password)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Register);
