import React from 'react';
import styles from './ImageSection.module.scss';
import { connect } from 'react-redux';
import classnames from 'classnames';

const ImageSection = ({ image, background, children, isRight, classname, onClick }) => {
	return (
		<div style={{ backgroundColor: background}} className={classnames(styles.imageSection, isRight && styles.isRight, classname)}>
			<div className={classnames(styles.imageContainer,isRight && styles.isRightImage)}>
				<img src={`${image}`} alt="" onClick={onClick} />
			</div>
			{children}
		</div>
	);
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(ImageSection);