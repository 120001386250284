import React from 'react';
import { connect } from 'react-redux';

// Assets
import Ranks from '../../../../../assets/images/ui/homepage/ranks.png';
import styles from './Tiers.module.scss';

// Imports
import { Image } from 'react-bootstrap';
import Reveal from 'react-reveal/Reveal';

class TiersSection extends React.Component {
    state = {};

    render() {
        return (
            <div className={styles.rewarded_area}>
                <div className="center-align">
                    <Reveal bottom cascade>
                        <Image src={Ranks} alt="" />

                        <div className={styles.details}>
                            <Reveal bottom cascade>
                                <h2>
                                    Cu cât ești mai activ, <br /> cu atât primești <br /> mai multe beneficii.
                                </h2>
                                <p>
                                    Strânge puncte și poți accesa un nivel superior în care vei descoperi beneficii din
                                    ce în ce mai mari.
                                </p>
                            </Reveal>
                        </div>
                    </Reveal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.login.loading
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TiersSection);
