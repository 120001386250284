import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../config";

// Components
import Header from "../Header";
import Footer from "../Footer";

class Layout extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA),
		isLoggedIn: store.get(config.user.token)
	};

	render() {
		const {isPWA, isLoggedIn} = this.state;

		return (
			<>
				{
					isLoggedIn || (!isLoggedIn && !isPWA) ? (
						<Header/>
					) : ('')
				}

				<div className={classnames(
					'content',
					this.props.class ? this.props.class : '',
					isPWA ? 'isPWA' : ''
				)}>
					{this.props.children}
				</div>


				{!isPWA || (!isLoggedIn && isPWA) ? ( <Footer /> ) : ('')}
			</>
		)
	}
}

export default Layout;
