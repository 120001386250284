import React from 'react';
import classnames from 'classnames';

import store from 'store';
import styles from "./FormDetails.module.scss";

// Assets
import dimensions from './images/i3.svg';
import Badge from './images/i2.svg';
import Points from "./images/i1.svg";
import Reveal from 'react-reveal/Reveal';
import config from "../../../../config";

class FormDetails extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA),
        isMobile: window.innerWidth < 768,
        endBoostSeptember: false
    };

    componentDidMount() {
        window.addEventListener('resize', () => this.setState({isMobile: window.innerWidth <= 570}))
        if(new Date() > new Date('09/17/2021 23:59:59')) {
            this.setState({endBoostSeptember: true})
        }
    }

    render() {

        const {isPWA, isMobile, endBoostSeptember} = this.state;

        return (
            <div className={classnames(styles.formDetails, isPWA ? styles.isPWA : '')}>
                <Reveal effect="fadeInUp" cascade>
                    <div className={styles.benefitsContainer}>
                        <>
                            <h3>Împărtășește experiența IQOS și bucură-te de beneficii:</h3>
                            <p>Invită-ți prietenii adulți fumători să treacă la IQOS și, dacă aceștia decid să achiziționeze kit-ul IQOS, te vei bucura de beneficii în IQOS Club:</p>
                            <div className={styles.pointsBenefits}>
                                <div className={styles.smallBenefitSection}>
                                    <img src={Points} alt='' />
                                    <div className={styles.text}>
                                        <span>60 de puncte</span>
                                        <p>în contul tău IQOS Club</p>
                                    </div>
                                </div>

                                <span className={styles.plus}>
                                    +
                                </span>

                                <div className={styles.smallBenefitSection}>
                                    <img src={Badge} alt='' />
                                    <div className={styles.text}>
                                        <span>Un moment</span>
                                        <p>în contul tău IQOS Club dacă, în urma achiziției, prietenul devine membru IQOS Club</p>
                                    </div>
                                </div>

                                {
                                    !endBoostSeptember &&
                                        <span className={styles.plus}>
                                         +
                                        </span>
                                }
                                {
                                    !endBoostSeptember &&
                                        <div className={styles.smallBenefitSection}>
                                            <img src={dimensions} alt='' />
                                            <div className={classnames(styles.text,styles.cartige)}>
                                                <span>Un cartuș*</span>
                                                <p>HEETS Dimensions</p>
                                            </div>
                                        </div>
                                }

                            </div>
                            {!endBoostSeptember && <span className={styles.duoDisclaimer}>*Pentru fiecare recomandare făcută în perioada 15 iulie - 17 septembrie și finalizată cu achiziție.</span>}
                            {!endBoostSeptember && <span className={styles.duoDisclaimer}>**Poți să revendici cartușul de HEETS Dimensions doar după ce devii membru IQOS Club și completezi formularul de revendicare în platformă.</span>}
                            <p>Punctele acumulate în contul tău te vor ajuta să avansezi în IQOS Club și să ai acces la noi experiențe și beneficii.</p>
                            <p>De asemenea, cu punctele acumulate poți achiziționa produse din <a href={`https://www.iqos.ro/club/catalog`}>Catalogul IQOS Club</a> sau <a href={config.path.shopIQOS}>Shop-ul IQOS.ro.</a> </p>
                            <p>Dacă devii membru în IQOS Club îți vei putea folosi punctele și pentru alte achiziții: accesorii și dispozitive IQOS, piese semnate de designer români, experiențe, cărți, vouchere și multe altele.</p>
                        </>
                    </div>
                </Reveal>
            </div>
        )
    }
}

export default FormDetails
