import React from 'react';
import classnames from 'classnames';

import store from 'store';
import styles from "./FormDetails.module.scss";

// Assets
import FiftyLei from "./images/50-lei.png";
import ImgBenefits from './images/benefits-mobile.png'

import Reveal from 'react-reveal/Reveal';
import config from "../../../../config";

import {Plus, Benefits, Benefits2, Benefit} from './FormDetailsComponents'
class FormDetails extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA),
        isMobile: window.innerWidth < 768
    };

    componentDidMount() {
        window.addEventListener('resize', () => this.setState({isMobile: window.innerWidth <= 570}))
    }

    render() {
        const voucher = {
            icon: FiftyLei,
            text: 'Pentru prima sa comandă din IQOS.RO sau din magazinele și insulele noastre*'
        };

        const {isPWA, isMobile} = this.state;

        return (
            <div className={classnames(styles.formDetails, isPWA ? styles.isPWA : '')}>
                <Reveal effect="fadeInUp" cascade>
                    <div className={styles.benefitsContainer}>
                        {!isMobile &&
                        <>
                            <h3>Beneficiile tale</h3>
                            <p><b>În perioada 1 septembrie – 31 decembrie ai parte de:</b> </p>
                            <Benefits />

                            <p>
                                <b>În plus</b>, odată cu fiecare recomandare încheiată cu achiziție în perioada campaniei, <b>perioada de 30 de zile</b> în care ai parte de puncte duble se resetează și reîncepe din acel moment.**
                            </p>


                            <p>*Voucherul are o valabilitate  de 30 de zile din momentul finalizării cu succes a recomandării.</p>
                            <p>**Perioadele de 30 de zile de dublare de puncte nu pot fi cumulate. Fiecare recomandare încheiată cu achiziție în perioada campaniei înseamnă startul unei noi perioade de 30 de zile de puncte duble și nu se cumulează cu perioadele de 30 de zile de dublare de puncte dobândite din recomandările anterioare.</p>
                        </>
                        }

                        {isMobile &&
                        <>
                            <h3>Beneficii pentru recomandări</h3>
                            <p><b>Pentru fiecare prieten adult fumător care acceptă invitația la experiența IQOS și achiziționează un dispozitiv, primești puncte și alte avantaje în IQOS CLUB.</b><br/>
                                Recomandă IQOS în perioada <b>1 septembrie – 31 decembrie</b> și te vei bucura de următoarele beneficii: primești 60 de puncte pentru fiecare prieten care achiziționează IQOS în urma invitației tale și puncte duble în club timp de 30 de zile pentru achiziția făcută de prietenul tău adult fumător.* </p>
                            <p>La rândul lor, prietenii adulți fumători care au experimentat universul IQOS și au achiziționat un dispozitiv primesc, la fel ca tine, <b className={styles.boldGold}>un voucher de 50 lei pentru prima lor comandă din IQOS.RO sau din magazinele și insulele noastre</b> și se vor bucura și ei <b className={styles.boldGold}>de dublarea punctelor pentru activitățile viitoare din IQOS CLUB timp de 30 de zile</b>.</p>
                        </>
                        }
                    </div>


                    <div className={styles.benefitsContainer}>
                        {!isMobile &&
                        <>
                            <h3>Beneficiile persoanei recomandate</h3>
                            <p><b>În perioada 1 septembrie – 31 decembrie persoana recomandată cu succes de tine are parte de:</b></p>

                            <div className={styles.extraRewards}>
                                <Benefit icon={voucher.icon} text={voucher.text}/>
                                <Plus />
                            </div>
                            <Benefits2 />

                            <p><b>În plus</b>, odată cu fiecare recomandare încheiată cu achiziție în perioada campaniei, <b>perioada de 30 de zile</b> în care are parte de puncte duble se resetează și reîncepe din acel moment.**</p>

                            <p>*Voucherul are o valabilitate  de 30 de zile din momentul finalizării cu succes a recomandării.</p>
                            <p>**Perioadele de 30 de zile de dublare de puncte nu pot fi cumulate. Fiecare recomandare încheiată cu achiziție în perioada campaniei înseamnă startul unei noi perioade de 30 de zile de puncte duble și nu se cumulează cu perioadele de 30 de zile de dublare de puncte dobândite din recomandările anterioare.</p>
                        </>
                        }

                    </div>

                    <div className={styles.accumulatedPoints}>
                        <p><b>Îți poți folosi punctele acumulate* pentru a revendica pachetele de HEETS preferate din magazine. </b></p>
                        <p><b>1 pachet = 20 de pct </b></p>
                        <p>* Dacă devii membru IQOS Club vei putea folosi punctele și pentru alte achiziții: accesorii și dispozitive, piese semnate de designeri români, experiențe, vouchere și multe altele.</p>
                        <p>** În cazul în care mai mulți prieteni adulți fumători au acceptat invitația ta la experiența IQOS și au decis să cumpere dispozitivul, cele 30 de zile în care punctele din club ți se dublează nu se cumulează de la un prieten la altul – perioada de 30 de zile începe din nou din momentul achiziției dispozitivului de către fiecare prieten adult fumător.</p>
                    </div>

                    <div className={styles.disclaimer}>
                        <p><b>Îți poți folosi punctele acumulate* pentru a revendica pachetele de HEETS preferate din magazine.</b></p>
                        <p><b>1 pachet = 20 de pct</b></p>
                        <p>*Dacă devii membru IQOS Club vei putea folosi punctele și pentru alte achiziții: accesorii și dispozitive, piese semnate de designeri români, experiențe, vouchere și multe altele.</p>
                        <p>**În cazul în care mai mulți prieteni adulți fumători au acceptat invitația ta la experiența IQOS și au decis să cumpere dispozitivul, cele 30 de zile în care punctele din club ți se dublează nu se cumulează de la un prieten la altul - perioada de 30 de zile începe din nou din momentul achiziției dispozitivului de către fiecare prieten adult fumător.</p>
                    </div>

                </Reveal>
            </div>
        )
    }
}

export default FormDetails
