import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../../config";
import styles from "./style.module.scss";

// Components
import { animateScroll as scroll } from 'react-scroll';
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import ReactPaginate from "react-paginate";

// Saga
import {connect} from "react-redux";
import WinnersActions from "../../../stores/Winners/Actions";

class ListWinners extends React.Component{
	state = {
		isPWA: store.get(config.user.isPWA),
		pageNumber: 0,
		offset: 0,
		filters: {
			location: [],
			campaign: [],
			prize: []
		}
	};

	componentDidMount() {
		this.props.getWinners(30, 1);
		this.props.getFilters();
	}

	handlePageClick = data => {
		let selected = data.selected;
		let offset = Math.ceil(selected * 10);
		scroll.scrollToTop();

		this.setState((state) => ({
			...state,
			offset: offset,
			pageCount: this.props.pager.last_page,
			pageNumber: selected
		}), () => this.props.getWinners(30, selected + 1, this.state.filters));
	};

	filterWinners = (field, value) => {
		this.setState((state) => ({
			...state,
			filters: {
				...state.filters,
				[field]: value === 'all' ? '' : value
			}
		}), () => this.handlePageClick({selected: 0}));
	};

	render() {
		const {isPWA, pageNumber} = this.state;
		const {winners, pager, loading, filters, filtersLoading} = this.props;

		return (
			<Layout>
				<div className={classnames('page_heading', isPWA ? 'isPWA' : '')}>
					<h1>Câștigători activități <br/>IQOS Club</h1>
					<p>* Câștigătorii vor fi afișați pe măsura validării acestora.</p>
				</div>

				<div className={styles.container}>
					<h3>În IQOS Club te așteaptă tot felul de surprize. <br/>Trebuie doar să fii cu ochii pe cardurile de activități.</h3>
					<p className={styles.subTitle}>Mai jos vor fi listați toți câștigătorii pe măsura validării acestora la încheierea campaniilor.</p>

					{filtersLoading && !loading ? <Loader /> :
						(filters &&
							<div className={styles.filters}>
								{filters.locations &&
								<div className={classnames(styles.select, isPWA && styles.selectPWA)}>
									<p>Oraș</p>
									<select name="location" onChange={(e) => this.filterWinners('location', e.target ? e.target.value : e)}>
										<option value="all">Toate</option>
										{filters.locations.map((location, key) => (
											<option key={key} value={location.location}>{location.location}</option>
										))}
									</select>
								</div>}

								{filters.campaign &&
								<div className={classnames(styles.select, isPWA && styles.selectPWA)}>
									<p>Campanie</p>
									<select name="campaign" onChange={(e) => this.filterWinners('campaign', e.target ? e.target.value : e)}>
										<option value="all">Toate</option>
										{filters.campaign.map((campaign, key) => (
											<option key={key} value={campaign.campaign}>{campaign.campaign}</option>
										))}
									</select>
								</div>}

								{filters.prizes &&
								<div className={classnames(styles.select, isPWA && styles.selectPWA)}>
									<p>Premiu</p>
									<select name="prize" onChange={(e) => this.filterWinners('prize', e.target ? e.target.value : e)}>
										<option value="all">Toate</option>
										{filters.prizes.map((prize, key) => (
											<option key={key} value={prize.prize}>{prize.prize}</option>
										))}
									</select>
								</div>}
							</div>
						)
					}

					{loading ? <Loader /> :
						<>
							{filtersLoading ? <Loader/> :
							<table className="autofit">
								<tbody>
									{winners && winners.map((winner, key) => (
										<tr key={key}>
											<td data-label="Nume și prenume">{winner.first_name} {winner.last_name}</td>
											<td data-label="Oraș">{winner.location}</td>
											<td data-label="Campanie">{winner.campaign}</td>
											<td data-label="Premiu">{winner.prize}</td>
										</tr>
									))}
								</tbody>
							</table>}

							{winners.length < 1 && <h2>Nu există informații conform criteriilor selectate.</h2>}
						</>
					}

					{!filtersLoading && pager.last_page > 1 &&
						<div className="pagination">
							<ReactPaginate
								previousLabel={''}
								nextLabel={''}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={pager.last_page}
								marginPagesDisplayed={1}
								pageRangeDisplayed={2}
								onPageChange={this.handlePageClick}
								containerClassName={'pagination'}
								subContainerClassName={'pages pagination'}
								activeClassName={'active'}
								forcePage={pageNumber}
							/>
						</div>
					}
				</div>
			</Layout>
		)
	}
}

const mapStateToProps = (state) => ({
	filtersLoading: state.winners.filtersLoading,
	loading: state.winners.loading,
	winners: state.winners.winners,
	filters: state.winners.filters,
	pager: state.winners.pager
});

const mapDispatchToProps = {
	getWinners: (per_page, page, filters) => WinnersActions.winners(per_page, page, filters),
	getFilters: () => WinnersActions.filters()
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListWinners);
