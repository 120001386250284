import React, {Component} from 'react'
import styles from './StarterKit.module.scss'
import startKit from './startKit.jpg';

import config from '../../../../../../config'
import Button from '../../../../../../components/ButtonV3/Button'
import ImageSectionEqual from '../../../../../../components/ImageSectionEqual/ImageSectionEqual';

class StarterKit extends Component {
    state = {
        isMobile: window.innerWidth <= 700,
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.setState({isMobile: window.innerWidth <= 700}))
    }

    render() {
        const {isMobile} = this.state;
        return (

            <div className={styles.starterKitContainer}>
                <ImageSectionEqual
                    image={startKit}
                    background={'#34303D'}
                    imageRight={true}
                    imageContainerClassname = {styles.image}>
                    <div className={styles.detailsStarter}>
                        <h2>Împarte aceeași emoție în aceeași comunitate.</h2>
                        <p>Bucură-te de emoția de a fi parte din comunitatea IQOS și achiziționează Starter Kit-ul IQOS 3 DUO.</p>
                        <Button type={'light'} onClick={() => (window.location = 'https://www.iqos.ro/produse/ce-este-iqos')}>Află mai multe</Button>
                    </div>
                </ImageSectionEqual>
            </div>

        )
    }
}


export default StarterKit