import React from 'react';
import store from 'store';
import moment from 'moment';
import { connect } from 'react-redux';

// Assets
import styles from './Intro.module.scss';
import styled, { keyframes } from 'styled-components';

// Imports
import config from '../../../../../config';
import Logo from '../../../../../assets/images/ui/logo/iqos_huge.png';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

// Components
import { Link } from 'react-scroll';
import Reveal from 'react-reveal/Reveal';
import { bounce } from 'react-animations';
import { Image, Nav } from 'react-bootstrap';
import Button from '../../../../../components/Button/Button';

// Images
import background1 from '../../../../../assets/images/backgrounds/1.jpg';
import background2 from '../../../../../assets/images/backgrounds/2.jpg';
import background3 from '../../../../../assets/images/backgrounds/3.jpg';
import background4 from '../../../../../assets/images/backgrounds/4.jpg';
import background5 from '../../../../../assets/images/backgrounds/5.jpg';
import background6 from '../../../../../assets/images/backgrounds/6.jpg';
import background7 from '../../../../../assets/images/backgrounds/7.jpg';
import background8 from '../../../../../assets/images/backgrounds/8.jpg';
import background9 from '../../../../../assets/images/backgrounds/9.jpg';
import background10 from '../../../../../assets/images/backgrounds/10.jpg';
import background11 from '../../../../../assets/images/backgrounds/11.jpg';
import background12 from '../../../../../assets/images/backgrounds/12.jpg';
import background13 from '../../../../../assets/images/backgrounds/13.jpg';
import background14 from '../../../../../assets/images/backgrounds/14.jpg';
import background15 from '../../../../../assets/images/backgrounds/15.jpg';
import background16 from '../../../../../assets/images/backgrounds/16.jpg';
import background17 from '../../../../../assets/images/backgrounds/17.jpg';
import background18 from '../../../../../assets/images/backgrounds/18.jpg';
import background19 from '../../../../../assets/images/backgrounds/19.jpg';
import background20 from '../../../../../assets/images/backgrounds/20.jpg';
import background21 from '../../../../../assets/images/backgrounds/21.jpg';
import background22 from '../../../../../assets/images/backgrounds/22.jpg';
import background23 from '../../../../../assets/images/backgrounds/23.jpg';
import background24 from '../../../../../assets/images/backgrounds/24.jpg';
import background25 from '../../../../../assets/images/backgrounds/25.jpg';
import background26 from '../../../../../assets/images/backgrounds/26.jpg';
import background27 from '../../../../../assets/images/backgrounds/27.jpg';
import background28 from '../../../../../assets/images/backgrounds/28.jpg';
import background29 from '../../../../../assets/images/backgrounds/29.jpg';
import background30 from '../../../../../assets/images/backgrounds/30.jpg';
import background31 from '../../../../../assets/images/backgrounds/31.jpg';
import { LinkContainer } from 'react-router-bootstrap';

class IntroSection extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA),
        backgrounds: {
            1: background1,
            2: background2,
            3: background3,
            4: background4,
            5: background5,
            6: background6,
            7: background7,
            8: background8,
            9: background9,
            10: background10,
            11: background11,
            12: background12,
            13: background13,
            14: background14,
            15: background15,
            16: background16,
            17: background17,
            18: background18,
            19: background19,
            20: background20,
            21: background21,
            22: background22,
            23: background23,
            24: background24,
            25: background25,
            26: background26,
            27: background27,
            28: background28,
            29: background29,
            30: background30,
            31: background31
        },
        getDay: 1
    };

    componentDidMount() {
        const date = new moment();
        this.setState({ getDay: date.day() });
    }

    render() {
        const Bounce = styled.div`
            animation: 2s ${keyframes`${bounce}`} infinite;
        `;
        const { backgrounds, getDay } = this.state;

        return (
            <div
                className={this.state.isPWA ? styles.introBannerPWA : styles.introBanner}
                style={{ backgroundImage: 'url(' + backgrounds[getDay] + ')' }}
            >
                <div className={styles.centerAlign}>
                    <Reveal effect="fadeInUp">
                        <h1 className={styles.title}>
                            Un univers al beneficiilor <br /> pentru comunitatea
                        </h1>

                        <Image className={styles.logo} src={Logo} />
                        <br />

                        <Button type="primary" toPage={config.path.login}>
                            Conectează-te
                        </Button>

                        <div className={styles.scrollDown}>
                                <Link to="beneficii" smooth={true} offset={-50} duration={500}>
                                    <Bounce>
                                        <ArrowDownward className={styles.pwaScroll} />
                                    </Bounce>
                                </Link>
                        </div>
                    </Reveal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.login.loading
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IntroSection);
