import React from 'react';
import moment from "moment";
import {connect} from 'react-redux';

// Assets
import styles from './List.module.scss';

// Components
import Table from "../Components/Table";
import Reveal from "react-reveal/Reveal";
import ReferralStats from '../Components/Stats';
import ErrorScenario from "../../../components/ErrorScenario";

// Datepicker
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/ro_RO';

// Saga
import ReferralAction from "../../../stores/Referral/Actions";
import Loader from "../../../components/Loader";
import Layout from "../../../components/Layout";
import classnames from "classnames";

class List extends React.Component {
    state = {
        startValue: new moment(),
        endValue: new moment(),
        endOpen: false
    };

    disabledStartDate = startValue => {
        const {endValue} = this.state;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };

    disabledEndDate = endValue => {
        const {startValue} = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    onChange = (field, value) => {
        this.setState({
            [field]: value,
        }, () => {
            const {startValue, endValue} = this.state;
            this.props.getTree({
                startDate: startValue ? startValue.format('YYYY-MM-DD') : '',
                endDate: endValue ? endValue.format('YYYY-MM-DD') : ''
            });
        });
    };

    componentDidMount() {
        this.props.getTree();
    }

    onStartChange = value => {
        this.onChange('startValue', value);
    };

    onEndChange = value => {
        this.onChange('endValue', value);
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    handleEndOpenChange = open => {
        this.setState({endOpen: open});
    };

    render() {
        const {treeData, loadingTree} = this.props;
        const {startValue, endValue, endOpen} = this.state;
        return (
            <Layout>
                <div className={classnames('page_heading', 'isReferral', 'hasSubheading')}>
                    <div className='rowHeading'>
                        <div className='textContainer'>
                            <div className='detailsHeader'>
                                <h2>Același ritual.</h2>
                                <h2>Aceeași comunitate.</h2>

                                <p>Recomandă IQOS unui prieten adult fumător și bucurați-vă împreună de beneficii.</p>
                            </div>
                            {/*{index === 1 && <Button noLink type='primary' onClick={() => this.handleTabChange(0)}> Descoperă avantajele IQOS </Button>}*/}
                        </div>
                        <div className= 'imageHeader' />
                    </div>
                </div>

                <div className={styles.container}>
                    {treeData && treeData.responseStatus === "success" ?
                        <div className={styles.referralPage}>
                            <div className={styles.container}>
                                <Reveal effect="fadeInUp" cascade>
                                    <ReferralStats treeData={this.props.treeData}/>

                                    <form action="" id="refInterval" className={styles.selectInterval}>
                                        <p>Selectează intervalul</p>
                                        <label>
                                            <p>De la:</p>
                                            <DatePicker
                                                disabledDate={this.disabledStartDate}
                                                format="YYYY-MM-DD"
                                                value={startValue}
                                                locale={locale}
                                                placeholder="De la:"
                                                onChange={this.onStartChange}
                                                onOpenChange={this.handleStartOpenChange}
                                            />
                                        </label>

                                        <label>
                                            <p>Până la:</p>
                                            <DatePicker
                                                disabledDate={this.disabledEndDate}
                                                format="YYYY-MM-DD"
                                                value={endValue}
                                                locale={locale}
                                                placeholder="Până la:"
                                                onChange={this.onEndChange}
                                                open={endOpen}
                                                onOpenChange={this.handleEndOpenChange}
                                            />
                                        </label>
                                    </form>

                                    {loadingTree ? <Loader/> : <Table treeData={treeData}/>}
                                </Reveal>
                            </div>
                        </div>
                        : treeData && treeData.responseStatus === "failure" ?
                            <div className={styles.referralPage}>
                                    {loadingTree ?
                                        <Loader/> :
                                        <ErrorScenario>
                                            <h1>Ups.</h1>
                                            <p>Ne pare rău, dar s-a produs o eroare. Dacă acest mesaj persistă, te
                                                rugăm să ne
                                                contactezi.</p>
                                        </ErrorScenario>
                                    }
                            </div>
                            :
                            <Loader/>
                    }
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    treeData: state.referral.data,
    loadingTree: state.referral.loadingTree
});

const mapDispatchToProps = {
    getTree: (params) => ReferralAction.referralTree(params)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(List);
