import React from 'react';
import {connect} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {Nav, Image} from 'react-bootstrap';
import config from '../../../config';

// Assets
import styles from './Create.module.scss';
import FormDetails from "../Components/FormDetails";
import Form from "../Components/Form";

class Create extends React.Component {
	state = {};

	componentDidMount() {
	}

	render() {
		return (
			<div className={styles.referralPage}>
				<div className={styles.container}>
					<Form />
					<FormDetails />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.login.loading,
	error: state.login.errors
});

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Create);
