import React from 'react'
import styles from './fdaSectioin.module.scss'
import Button from '../../../../components/Button/Button';

//Assets
import config from '../../../../config';
import deviceFDA from '../../images/device-fda.png';

const fdaSection = () =>
    <div className={styles.fdaSection}>
        <p>În plus, ai un nou motiv de a-ți invita prietenii adulți fumători să încerce IQOS:
            acesta este primul și singurul sistem de încălzire a tutunului autorizat ca produs din tutun cu risc modificat de către Administrația Americană pentru Alimente și Medicamente (FDA) din SUA, care a concluzionat
            că trecerea completă de la țigări la IQOS <b>reduce expunerea organismului la substanțe nocive sau potențial nocive.*</b></p>
        <img src={deviceFDA} alt="device-blue"/>
        <Button
            type={'primary'}
            toPage={config.path.fdaArticle}
        >Citește mai multe
        </Button>
        <div className={styles.importantInfo}>
            <p>Informații importante: IQOS nu este lipsit de riscuri. Acesta eliberează nicotină, care creează dependență.</p>
            <span>*Sursa: Studiile PMI referitoare la reducerea expunerii desfășurate pe durata a trei luni în Japonia și SUA în condiții de utilizare apropiate de cele obisnuite.
                                Aceste studii au măsurat expunerea la 15 substanțe nocive și au comparat nivelurile observate în rândul fumătorilor care au trecut la IQOS cu nivelurile măsurate în rândul fumătorilor care au continuat să fumeze țigări.
            </span>
        </div>
    </div>

export default fdaSection