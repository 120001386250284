import React, {Component} from 'react'
import styles from './ReceiveCard.module.scss'

//redux
import {connect} from 'react-redux';
import ReferralAction from "../../../../stores/Referral/Actions";

//components
import Card from '../SendCard/Card'
import Button from '../../../../components/ButtonV3/Button'
import Layout from '../../../../components/Layout'
import Loader from '../../../../components/Loader';
import {StarterKit, Community, IQOSPocket} from './sections'
import classnames from "classnames";
import ReasonSection from './sections/ReasonsContainer/ReasonSection';
import EducatedAwarenessComponent from "../EducatedAwarenessComponent/EducatedAwareness";


class ReceiveCard extends Component {
    state = {
        discover: false,
        isMobile: window.innerWidth <= 570,
        changeMargin: true
    };

    componentDidMount() {
        const token = this.props.match.params.token;
        this.props.getCard(token);
        window.addEventListener('resize', () => this.setState({isMobile: window.innerWidth <= 570}));
    }

    handleClick = () => this.setState({discover: true})

    render() {
        const {isMobile, discover, changeMargin } = this.state;
        const { loading, cardData } = this.props;

        if(loading) return <Loader />
        return (
            <Layout>
                    <div className={styles.pageContainer}>
                        <div className={styles.imageContainer} />

                        <div className={styles.contentContainer}>
                            <div className={styles.title}>
                                <b>{cardData.user.first_name}</b> vrea să îți spună ceva.
                            </div>

                            <div className={styles.cardContainer}>
                                <Card
                                    userName={cardData.user.first_name}
                                    friendName={cardData.person_name}
                                    message={cardData.image.content}
                                    greetings={cardData.image.greetings}
                                    photo={cardData.image.image}
                                    slogan={cardData.image.slogan}
                                />
                            </div>

                            {!discover &&
                                <Button type="darkWithBlue" onClick={this.handleClick}>
                                    Descoperă mai multe
                                </Button>
                            }

                            {discover &&
                                <div className={classnames(styles.discoverContainer)}>

                                    <StarterKit />
                                    <ReasonSection/>
                                    <EducatedAwarenessComponent/>
                                    <IQOSPocket />
                                </div>
                            }
                        </div>
                    </div>
            </Layout>
        )
    }
}

const mapStateToProps = state => ({
    cardData: state.referral.cardData,
    loading: state.referral.loading
});

const mapDispatchToProps = {
    getCard: (token) => ReferralAction.referralCard(token)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReceiveCard);
