import React from 'react'
import {connect} from 'react-redux';
import classnames from 'classnames';
import store from 'store';
import Button from "../../components/Button/Button";
// Assets
import styles from "./tabStyles.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import CreateReferral from "./Create";
import Popup from "./Components/Popup";
import Layout from "../../components/Layout";

// Saga
import ReferralAction from "../../stores/Referral/Actions";
import config from "../../config";

class Referral extends React.Component {
    state = {};

    submitForm = () => {
        this.props.submitForm(this.props.formData);
    };

    render() {

        return (
            <Layout>
                <div className={classnames('page_heading', 'isReferral', 'hasSubheading')}>
                    <div className='textContainer'>
                        <h1>  Tu ai făcut primul pas. </h1>
                        <h2> Ajută-ți prietenii* să facă la fel.</h2>
                        <Button toPage={config.path.benefits} type='primary'>Descoperă avantajele IQOS</Button>
                    </div>
                    <div className='light-brown'/>
                    <div className='darker-brown'/>
                </div>

                <div className={styles.container}>
                    <CreateReferral/>
                </div>
                <Popup submitForm={this.submitForm}/>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    formData: state.referral.formData
});

const mapDispatchToProps = {
    submitForm: (formData) => ReferralAction.referral(formData),
    toggleModal: () => ReferralAction.toggleModal()
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Referral);
