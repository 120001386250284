import styles from './ReasonSection.module.scss';
import React, { Component } from 'react';
import Icon from '../../../../Benefits/images/icon1.svg';
import Icon1 from '../../../../Benefits/images/icon2.svg';
import Icon2 from '../../../../Benefits/images/icon3.svg';
import Icon3 from '../../../../Benefits/images/icons-pictogram-like.svg';

class ReasonSection extends Component {
    state = {
        isMobile: window.innerWidth <= 700,
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.setState({isMobile: window.innerWidth <= 700}))
    }

    render() {
        const {isMobile} = this.state;
        const reasons = [
            {
                icon: Icon,
                title: 'Fără ardere, fără gudron',
                description: 'Gudronul este reziduul din fumul produs în urma arderii țigării. IQOS nu produce gudron, pentru că încălzește tutunul și nu îl arde.',
                disclaimer: 'Informații importante: Deși IQOS nu produce gudron, IQOS nu este lipsit de riscuri și eliberează nicotină, care provoacă dependență.'
            }
            ,
            {
                icon: Icon1,
                title: 'Fără fum, fără cantități mari de monoxid de carbon',
                description: 'IQOS eliberează în medie cu 98% mai puțin monoxid de carbon în comparație cu țigările tradiționale.',
                disclaimer: 'Informații importante:  Aceasta nu înseamnă că există o reducere cu 98% a riscului. IQOS nu este lipsit de riscuri și eliberează nicotină, care provoacă dependență.'
            },
            {
                icon: Icon2,
                title: 'Fără să miroși a fum',
                description: 'Hainele, părul, mâinile nu mai miros a fum de țigară și e mai puțin probabil să îi deranjezi pe cei din jur.',
                // disclaimer: 'Informații importante:  Aceasta nu echivalează cu o reducere cu 98% a riscului. IQOS nu este lipsit de riscuri. Eliberează nicotină, care provoacă dependență.'
            }, {
                icon: Icon3,
                title: 'O alegere  mai bună',
                description: `IQOS este o alegere mai bună față de continuarea fumatului.`,
                disclaimer: 'Informații importante: IQOS nu este lipsit de riscuri, eliberează nicotină, care provoacă dependență.'
            }];
        return (
            <div className={styles.reasonsContainer}>
                <h3>Experiența IQOS are următoarele beneficii:</h3>
                <div className={styles.reasons}>
                    {reasons.map(({icon, title, description, disclaimer}, id) =>
                        <div className={styles.reason} key={id}>
                            <div className={styles.iconContainer}>
                                <img src={icon} alt="icon-benefits" />
                            </div>
                            <h3> {title}</h3>
                            <p>{description}</p>
                            <small>{disclaimer}</small>
                        </div>
                    )
                    }
                </div>
            </div>
        )
    }
}


export default ReasonSection
