export default {
    apiUrl: (window.location.hostname === 'localhost') || (window.location.hostname === '127.0.0.1' ) || (window.location.hostname.includes('pmi.gd')) ? 'https://leads-from-landing-api.gd.ro/api' : 'https://api-leads-from-landing-prod.gd.ro/api/',

    // 'http://localhost:8000/api/',
    cookieConfig:
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
            ? ";domain=localhost;path=/"
            : `;secure;domain=iqos.ro;path=/`,
    user: {
        token: 'token',
        tokenExpireDate: 'tokenExpireDate',
        refreshToken: 'refreshToken',
        exploreMode: 'exploreMode',
        disableExploreModePopup: 'disableExploreModePopup',
        expiresIn: 'expiresIn',
        isAdult: 'isAdult',
        isPWA: 'isPWA',
        data: 'data',
        bottomShortcuts: 'bottomShortcuts',
        menuOpenings: 'menuOpenings',
        orders: 'orders',
        points: 'points',
        pushNotificationsToken: 'pushNotificationsToken'
    },
    path: {
        index: '/club/invita-un-prieten/',
        login: '/club/invita-un-prieten/login',
        home: '/club/invita-un-prieten/home',
        forgotPassword: '/club/invita-un-prieten/recuperare-parola',
        forgotPasswordIQOS: 'https://www.iqos.ro/catalog?login_overlay=1',
        register: '/club/invita-un-prieten/inregistrare-cont',
        benefits: '/club/invita-un-prieten/beneficii',
        referral: '/club/invita-un-prieten/trimite-invitatie',
        referralList: '/club/invita-un-prieten/invitatiile-tale',
        offlineRedemption: '/club/invita-un-prieten/revendica-heets-offline',
        referralSuccess: '/club/invita-un-prieten/recomanda/succes',
        sendCard: '/club/invita-un-prieten/recomanda/trimite-card',
        sendCardSuccess: '/club/invita-un-prieten/recomanda/trimite-card/success',
        receivedCard: '/club/invita-un-prieten/recomandari',
        contact: 'https://www.iqos.ro/asistenta',
        userDataNotification: 'https://www.iqos.ro/privacy',
        cookiesPolicy: 'https://www.iqos.ro/cookies',
        termsConditions: 'https://www.iqos.ro/conditii-de-utilizare',
        termsConditionsUGC: 'https://www.iqos.ro/continut-generat-de-utilizatori',
        fdaArticle: 'https://iqos.ro/club/comunitate/descopera-iqos/fda-a-autorizat-comercializarea-in-statele-unite-a-iqos-si-a-trei-variante-de-heets',
        FAQs: 'https://www.iqos.ro/iqos-intrebari-frecvente',
        listWinners: '/club/invita-un-prieten/lista-castigatori',
        regulation: '/club/invita-un-prieten/regulament',
        map: 'https://www.iqos.ro/store/ro/search',
        shopIQOS: 'https://www.iqos.ro/iqos3-duo',
        IQOSpoints: '/club/invita-un-prieten/puncte-iqos-club',
        IQOSPocket: 'https://iqos.ro/club/iqos-pocket',
        aboutIQOS: 'https://www.iqos.ro/produse/ce-este-iqos',
        notFound: '/club/invita-un-prieten/page-not-found'
    },
    activities: {

    },
    dateFormat: {
        projects: 'MMM D, YYYY'
    },
    auth: {
        refreshInProgress: 'refreshInProgress'
    },
    googleMapsKey: 'AIzaSyDLcdVvp_yV0i0V3-WWHZ2AY2BhTHESMYk'
};
