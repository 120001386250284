import React from 'react';
import { connect } from 'react-redux';
import store from 'store';

// Assets
import CardImage1 from '../../../../../assets/images/ui/homepage/card_01.png';
import CardImage2 from '../../../../../assets/images/ui/homepage/card_02.png';

import PWACardImage1 from '../../../../../assets/images/ui/homepage/cards/01/pwa-card.png';
import PWACardImage2 from '../../../../../assets/images/ui/homepage/cards/02/pwa-card.png';
import PWACardImage3 from '../../../../../assets/images/ui/homepage/cards/03/pwa-card.png';

import PWACardIcon1 from '../../../../../assets/images/ui/homepage/cards/01/ico1.svg';
import PWACardIcon2 from '../../../../../assets/images/ui/homepage/cards/01/ico2.svg';
import PWACardIcon3 from '../../../../../assets/images/ui/homepage/cards/02/ico1.svg';
import PWACardIcon4 from '../../../../../assets/images/ui/homepage/cards/02/ico2.svg';
import PWACardIcon5 from '../../../../../assets/images/ui/homepage/cards/02/ico3.svg';

import PWARanks from '../../../../../assets/images/ui/homepage/cards/03/ranks.png';

import styles from './Cards.module.scss';

// Imports
import { Image } from 'react-bootstrap';
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';
import config from "../../../../../config";

class CardsSection extends React.Component {
    state = {
        isPWA: store.get(config.user.isPWA)
    };

    render() {
        return (
            <ul className={styles.cards} id="beneficii">
                <Reveal effect="fadeInUp" cascade>
                    <li>
                        <div className={this.state.isPWA ? styles.detailsPWA : styles.details}>
                            <div className="center-align">
                                <Reveal effect="fadeInUp" cascade>
                                    <h2>Acțiunile îți aduc puncte.</h2>
                                    <p>
                                        Îți completezi profilul, testezi tot ce știi despre IQOS, le recomanzi și
                                        prietenilor să se alăture sau introduci coduri HEETS - toate îți aduc puncte în
                                        IQOS Club.
                                    </p>
                                </Reveal>
                            </div>
                        </div>

                        {this.state.isPWA ? (
                            <Reveal effect="fadeInUp" cascade>
                                <Image src={PWACardImage1} className={styles.cardImagePWA} />

                                <div className={[styles.cardDetailsPWA, styles.cardDetailsBg1].join(' ')}>
                                    <Fade bottom cascade>
                                        <div>
                                            <Image src={PWACardIcon1} />
                                            <p>Ești activ</p>
                                        </div>

                                        <div>
                                            <Image src={PWACardIcon2} />
                                            <p>Beneficii exclusive</p>
                                        </div>
                                    </Fade>
                                </div>
                            </Reveal>
                        ) : (
                            <div className={[styles.art, styles.card_01].join(' ')}>
                                <div className="center-align">
                                    <Reveal effect="fadeInUp" cascade>
                                        <Image src={CardImage1} />
                                    </Reveal>
                                </div>
                            </div>
                        )}
                    </li>

                    <li className={styles.reverse}>
                        <div className={this.state.isPWA ? styles.detailsPWA : styles.details}>
                            <div className="center-align">
                                <Reveal effect="fadeInUp" cascade>
                                    <h2>Punctele le schimbi în beneficii.</h2>
                                    <p>
                                        Folosești punctele așa cum îți dorești - de la HEETS și alte produse IQOS sau
                                        discounturi în rețeaua partenerilor până la experiențe exclusive pentru tine și
                                        prietenii tăi.
                                    </p>
                                </Reveal>
                            </div>
                        </div>

                        {this.state.isPWA ? (
                            <Reveal effect="fadeInUp" cascade>
                                <Image src={PWACardImage2} className={styles.cardImagePWA} />

                                <div className={[styles.cardDetailsPWA, styles.cardDetailsBg2].join(' ')}>
                                    <Fade bottom cascade>
                                        <div>
                                            <Image src={PWACardIcon3} />
                                            <p>Reduceri</p>
                                        </div>

                                        <div>
                                            <Image src={PWACardIcon4} />
                                            <p>Experiențe</p>
                                        </div>

                                        <div>
                                            <Image src={PWACardIcon5} />
                                            <p>Cadouri</p>
                                        </div>
                                    </Fade>
                                </div>
                            </Reveal>
                        ) : (
                            <Reveal effect="fadeInUp" cascade>
                                <div className={[styles.art, styles.card_02].join(' ')}>
                                    <div className="center-align">
                                        <Image src={CardImage2} />
                                    </div>
                                </div>
                            </Reveal>
                        )}
                    </li>

                    {this.state.isPWA ? (
                        <li>
                            <Reveal effect="fadeInUp" cascade>
                                <div className={this.state.isPWA ? styles.detailsPWA : styles.details}>
                                    <div className="center-align">
                                        <Reveal effect="fadeInUp" cascade>
                                            <h2>Cu cât ești mai activ, cu atât primești mai multe beneficii</h2>
                                            <p>
                                                Strânge puncte și poți accesa un nivel superior în care vei descoperi
                                                beneficii din ce în ce mai mari.
                                            </p>
                                        </Reveal>
                                    </div>
                                </div>

                                <Image src={PWACardImage3} className={styles.cardImagePWA} />

                                <Fade bottom cascade className={styles.row}>
                                    <div className={styles.cardDetailsPWA}>
                                        <Image src={PWARanks} />
                                    </div>
                                </Fade>
                            </Reveal>
                        </li>
                    ) : (
                        ''
                    )}
                </Reveal>
            </ul>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.login.loading
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardsSection);
